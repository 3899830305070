<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-title p-3 py-2 mb-0">
            <div class="d-flex align-items-center">
              <div class="me-2 d-inline-block">
                <i class="uil uil-file-graph display-6 text-primary"></i>
              </div>
              <div class="mb-2 mb-lg-0 d-inline-block">
                <h4 class="fw-medium font-size-20 text-dark mb-0">
                  
                  <span v-if="isEditPage">{{ forms.applicant.company_name }} - Factoring Application</span>
                  <span v-else>{{ title }}</span>
                </h4>
              </div>

              <div class="ms-auto ms-1 text-nowrap">
                <router-link
                  :to="{
                    name: prevRoute.name || 'Factoring Application',
                    query: {
                      status: prevRoute.query.status || $route.query.status,
                    },
                  }"
                  class="btn btn-outline-info fw-medium me-2"
                >
                  <i class="uil uil-arrow-left me-1"></i> Back
                </router-link>
              </div>
            </div>
          </div>

          <div class="card-body border-top">
            <div class="row align-items-top">
              <div class="col-12 col-lg-4 mb-2 mb-lg-0">
                <div class="d-lg-flex align-items-top">
                  <label class="me-2 mb-0 py-2">Applicant</label>
                  <div class="w-100">
                    <multiselect
                      autocomplete="off"
                      v-model="forms.applicant"
                      :options="applicantList"
                      label="company_name"
                      :disabled="isEditPage"
                      placeholder="Select Applicant"
                      :close-on-select="true"
                      :allow-empty="false"
                      deselect-label=""
                      class="font-size-15"
                      :class="{
                        'is-invalid': submitted && $v.forms.applicant.$error,
                      }"
                      :show-on-select="true"
                      @open="isOpen = true"
                      @close="isOpen = false"
                      @input="updateApplicantInfo"
                    >
                      <template slot="singleLabel" slot-scope="props">
                        <span v-if="props.option.id !== '-1'">
                          <span class="option__title">
                            {{ props.option.company_name }}
                          </span>
                        </span>
                        <span v-else>{{ props.option.company_name }} </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <span v-if="props.option.id !== '-1'">
                          <span class="option__title">
                            {{ props.option.company_name }}
                          </span>
                        </span>
                        <span v-else>{{ props.option.company_name }}</span>
                      </template>
                      <span slot="noResult">Oops! No Applicant found. </span>
                    </multiselect>
                    <div
                      v-if="submitted && $v.forms.applicant.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.forms.applicant.required"
                        >Applicant is required.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-4 mb-2 mb-lg-0">
                <div class="d-lg-flex align-items-top">
                  <label class="me-2 mb-0 py-2">Originator</label>
                  <div class="w-100">
                    <!-- <span v-if="forms.originator">{{ forms.originator.name || '' }}</span> -->
                    <multiselect
                      :disabled="isEditPage"
                      autocomplete="off"
                      v-model="forms.originator"
                      :options="
                        userList.filter((item) => {
                          return (
                            item.username !== 'steve' &&
                            item.username !== 'kiatbin'
                          );
                        })
                      "
                      label="name"
                      placeholder="Select Originator"
                      :close-on-select="true"
                      :allow-empty="false"
                      deselect-label=""
                      class="font-size-15"
                      :class="{
                        'is-invalid': submitted && $v.forms.originator.$error,
                      }"
                      :show-on-select="true"
                      @open="isOpen = true"
                      @close="isOpen = false"
                      @input="
                        changeAmountPaymentModeContractType(),
                          ifChangeOrginator()
                      "
                    >
                      <template slot="singleLabel" slot-scope="props">
                        <span v-if="props.option.username !== ''">
                          <span class="option__title">
                            {{ props.option.name }}
                          </span>
                        </span>
                        <span v-else class="text-muted">Select Originator </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <span v-if="props.option.username !== ''">
                          <span class="option__title">
                            {{ props.option.name }}
                          </span>
                        </span>
                        <span v-else class="text-muted">Select Originator </span>
                      </template>
                      <span slot="noResult">Oops! No User found. </span>
                    </multiselect>
                    <div
                      v-if="submitted && $v.forms.originator.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.forms.originator.required"
                        >Originator is required.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="d-lg-flex align-items-top">
                  <label class="me-2 mb-0 py-2">Lead</label>
                  
                  <div class="w-100 position-relative">
                    <div class="position-absolute opacity-50 bg-white top-0 start-0 w-100 h-100 disabled-area" v-if="isEditPage && accessUsername !== forms.originator.username"></div>
                    <input
                      v-model.trim="forms.lead"
                      type="text"
                      class="form-control w-100"
                      placeholder="Lead"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="custom-accordion" v-if="account_type">
          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark"
              data-toggle="collapse"
              aria-expanded="true"
              aria-controls=""
              v-b-toggle.accordion-3
            >
              <div class="p-3">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-20"
                      >
                        <i class="uil uil-calculator-alt"></i>
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-0">
                      Facility Term Sheet Setting
                    </h5>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse id="accordion-3" visible class="position-relative">
              <div class="position-absolute opacity-50 bg-white top-0 start-0 w-100 h-100 disabled-area" v-if="isEditPage && accessUsername !== forms.originator.username"></div>
              <div class="p-3 border-top">
                <div class="row">
                  <div class="col-12 d-none">
                    <div
                      class="p-2 bg-light text-center fw-medium text-dark rounded mb-3"
                    >
                      General
                    </div>
                  </div>
                  <div
                    class="mb-3"
                    :class="{
                      'col-lg-4': forms.nature_of_contract.value !== 'one_off',
                      'col-lg-6': forms.nature_of_contract.value == 'one_off',
                    }"
                  >
                    <div>
                      <label class="mb-1"
                        >Nature Of Contract
                        <span class="text-muted fw-normal font-size-12 d-block"
                          >(Part B: Contract Details)</span
                        ></label
                      >
                      <select
                        class="form-select pe-5"
                        v-model="forms.nature_of_contract"
                        :disabled="loading"
                        @change="natureContractChange(), runFormula()"
                      >
                        <option
                          :value="value"
                          v-for="(value, index) in nature_of_contract"
                          :key="index"
                        >
                          {{ value.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div
                    class="mb-3"
                    :class="{
                      'col-lg-4': forms.nature_of_contract.value !== 'one_off',
                      'col-lg-6': forms.nature_of_contract.value == 'one_off',
                    }"
                  >
                    <div>
                      <label class="mb-1"
                        >Contract Amount
                        <span class="text-success" v-if="forms.contract_amount">
                          ({{
                            convertCurrencyFormat(
                              forms.contract_amount || 0,
                              false
                            )
                          }})</span
                        ><span class="text-muted fw-normal font-size-12 d-block"
                          >(Part B: Contract Details)</span
                        ></label
                      >
                      <input
                        v-model.trim="forms.contract_amount"
                        type="tel"
                        class="form-control text-start"
                        placeholder="Contract Amount"
                        @input="
                          inputNumberOnly(
                            $event,
                            'forms',
                            'contract_amount',
                            'normal'
                          ),
                            runFormula()
                        "
                      />
                    </div>
                  </div>
                  <div
                    class="mb-3"
                    :class="{
                      'col-lg-4': forms.nature_of_contract.value !== 'one_off',
                    }"
                    v-if="forms.nature_of_contract.value !== 'one_off'"
                  >
                    <div>
                      <label class="mb-1"
                        >Average Collection Days
                        <span class="text-muted fw-normal font-size-12 d-block"
                          >(Part C: Forecast Advance and Collection
                          Timeline)</span
                        ></label
                      >
                      <select
                        class="form-select pe-5"
                        v-model="forms.average_collection_forecast"
                        :disabled="loading"
                        @change="runFormula()"
                      >
                        <option
                          :value="value"
                          v-for="(value, index) in collection_forecast"
                          :key="index"
                        >
                          {{ value.label }}
                          {{ value.value !== 0 ? "(" + value.value + ")" : "" }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!-- Part A -->
                  <div class="col-lg-6 mb-3 mb-lg-0">
                    <div class="p-2 border border-light2 bg-light rounded">
                      <div
                        class="p-2 bg-soft-primary text-center fw-medium text-primary rounded mb-3"
                      >
                        Pre-Factoring
                      </div>
                      <div class="mb-3">
                        <label class="mb-1">Purpose</label>
                        <select
                          class="form-select pe-5 mb-3"
                          v-model="forms.pre_factoring_purpose_type"
                          :disabled="loading"
                          @change="purposeChange()"
                        >
                          <option
                            :value="value"
                            v-for="(value, index) in pre_factoring_purpose_type"
                            :key="index"
                          >
                            {{ value }}
                          </option>
                        </select>
                        <label class="mb-1"
                          >Purpose
                          <span class="text-muted font-size-11"
                            >(This purpose will appear in Document C: Facility
                            Term Sheet - Pre Factoring)</span
                          ></label
                        >

                        <input
                          v-model.trim="
                            forms.facility_term_sheet.pre_factoring.purpose
                          "
                          type="text"
                          class="form-control text-start"
                          placeholder="Purpose"
                        />
                      </div>
                      <div class="mb-3">
                        <label class="mb-1"
                          >Proposed Limit
                          <span
                            class="text-success"
                            v-if="forms.facility_term_sheet.pre_factoring.limit"
                          >
                            ({{
                              convertCurrencyFormat(
                                forms.facility_term_sheet.pre_factoring.limit ||
                                  0,
                                false
                              )
                            }})</span
                          ></label
                        >
                        <input
                          v-model.trim="
                            forms.facility_term_sheet.pre_factoring.limit
                          "
                          type="tel"
                          class="form-control text-start"
                          placeholder="Proposed Limit"
                          @input="
                            inputNumberOnlyV5(
                              $event,
                              'forms',
                              'facility_term_sheet',
                              'pre_factoring',
                              'limit',
                              'normal'
                            ),
                              runFormula(),
                              purposeLimitFollowPurposeChange()
                          "
                        />
                      </div>
                      <div class="mb-3" v-if="forms.nature_of_contract.value == 'milestone_staggered'">
                        <label class="mb-1">Total Pre Financing Amount
                          <span class="text-success" v-if="forms.facility_term_sheet.pre_factoring.total_pre_financing_amount">
                            ({{ convertCurrencyFormat(forms.facility_term_sheet.pre_factoring.total_pre_financing_amount || 0,false )}})
                          </span>
                        </label>
                        <input
                          v-model.trim="forms.facility_term_sheet.pre_factoring.total_pre_financing_amount"
                          type="tel"
                          class="form-control text-start"
                          placeholder="Total Pre Financing Amount"
                          @input="
                            inputNumberOnlyV5(
                              $event,
                              'forms',
                              'facility_term_sheet',
                              'pre_factoring',
                              'total_pre_financing_amount',
                              'decimal'
                            ),
                              runFormula(),
                              purposeLimitFollowPurposeChange()
                          "
                        />
                      </div>
                      <div
                        class="mb-3"
                        v-if="forms.nature_of_contract.value == 'recurring'"
                      >
                        <label class="mb-1">Profit Rate (%)</label>
                        <input
                          v-model.trim="
                            forms.facility_term_sheet.pre_factoring.profit_rate
                          "
                          type="tel"
                          class="form-control text-start"
                          placeholder="Profit Rate"
                          @input="
                            inputNumberOnlyV5(
                              $event,
                              'forms',
                              'facility_term_sheet',
                              'pre_factoring',
                              'profit_rate',
                              'decimal'
                            ),
                              runFormula()
                          "
                        />
                      </div>
                      <div
                        class="mb-3"
                        v-if="forms.nature_of_contract.value !== 'recurring'"
                      >
                        <label class="mb-1"
                          >Supplier/Bond Advance - Profit Rate (%)</label
                        >
                        <input
                          v-model.trim="
                            forms.facility_term_sheet.pre_factoring
                              .supplier_bond_advance_rate
                          "
                          type="tel"
                          class="form-control text-start"
                          placeholder="Supplier/Bond Advance - Profit Rate (%)"
                          @input="
                            inputNumberOnlyV5(
                              $event,
                              'forms',
                              'facility_term_sheet',
                              'pre_factoring',
                              'supplier_bond_advance_rate',
                              'decimal'
                            ),
                              runFormula()
                          "
                        />
                      </div>
                      <div
                        class="mb-3"
                        v-if="forms.nature_of_contract.value !== 'recurring'"
                      >
                        <label class="mb-1"
                          >Cash Advance - Profit Rate (%)</label
                        >
                        <input
                          v-model.trim="
                            forms.facility_term_sheet.pre_factoring
                              .cash_advance_rate
                          "
                          type="tel"
                          class="form-control text-start"
                          placeholder="Cash Advance - Profit Rate (%)"
                          @input="
                            inputNumberOnlyV5(
                              $event,
                              'forms',
                              'facility_term_sheet',
                              'pre_factoring',
                              'cash_advance_rate',
                              'decimal'
                            ),
                              runFormula()
                          "
                        />
                      </div>

                      <div class="row">
                        <div class="col-6 col-lg-6 mb-3">
                          <label class="mb-1">Rollover Fee (%)</label>
                          <input
                            v-model.trim="
                              forms.facility_term_sheet.pre_factoring
                                .rollover_fee
                            "
                            type="tel"
                            class="form-control text-start"
                            placeholder="Rollover Fee"
                            @input="
                              inputNumberOnlyV5(
                                $event,
                                'forms',
                                'facility_term_sheet',
                                'pre_factoring',
                                'rollover_fee',
                                'decimal'
                              ),
                                runFormula()
                            "
                          />
                        </div>
                        <div class="col-6 col-lg-6 mb-3">
                          <label class="mb-1">Margin of Advance (%)</label>
                          <input
                            v-model.trim="
                              forms.facility_term_sheet.pre_factoring
                                .margin_of_advance
                            "
                            type="tel"
                            class="form-control text-start"
                            placeholder="Margin of Advance"
                            @input="
                              inputNumberOnlyV5(
                                $event,
                                'forms',
                                'facility_term_sheet',
                                'pre_factoring',
                                'margin_of_advance',
                                'normal'
                              ),
                                runFormula()
                            "
                          />
                        </div>

                        <div
                          class="col-6 col-lg-6 mb-3 mb-lg-3"
                          v-if="forms.nature_of_contract.value !== 'recurring'"
                        >
                          <label class="mb-1">Credit Period</label>
                          <div class="input-group">
                            <input
                              v-model.trim="
                                forms.facility_term_sheet.pre_factoring
                                  .credit_period
                              "
                              type="tel"
                              max-length="3"
                              class="form-control"
                              placeholder="Month"
                              @input="
                                inputNumberOnlyV5(
                                  $event,
                                  'forms',
                                  'facility_term_sheet',
                                  'pre_factoring',
                                  'credit_period',
                                  'normal'
                                ),
                                  runFormula()
                              "
                            />
                            <b-dropdown variant="secondary" right>
                              <template v-slot:button-content>
                                Days
                                <i class="mdi mdi-chevron-down"></i>
                              </template>
                              <b-dropdown-item
                                href="javascript:void(0)"
                                @click="
                                  (forms.facility_term_sheet.pre_factoring.credit_period =
                                    value),
                                    runFormula()
                                "
                                :value="value"
                                v-for="(value, index) in period"
                                :key="index"
                                >{{ value }}</b-dropdown-item
                              >
                            </b-dropdown>
                          </div>
                        </div>
                        <div
                          class="col-6 col-lg-6 mb-3 mb-lg-0"
                          v-if="forms.nature_of_contract.value !== 'recurring'"
                        >
                          <label class="mb-1">Recourse Period</label>
                          <div class="input-group">
                            <input
                              v-model.trim="
                                forms.facility_term_sheet.pre_factoring
                                  .recourse_period
                              "
                              type="tel"
                              max-length="3"
                              class="form-control"
                              placeholder="Month"
                              @input="
                                inputNumberOnlyV5(
                                  $event,
                                  'forms',
                                  'facility_term_sheet',
                                  'pre_factoring',
                                  'recourse_period',
                                  'normal'
                                ),
                                  runFormula()
                              "
                            />
                            <b-dropdown variant="secondary" right>
                              <template v-slot:button-content>
                                Days
                                <i class="mdi mdi-chevron-down"></i>
                              </template>
                              <b-dropdown-item
                                href="javascript:void(0)"
                                @click="
                                  (forms.facility_term_sheet.pre_factoring.recourse_period =
                                    value),
                                    runFormula()
                                "
                                :value="value"
                                v-for="(value, index) in period"
                                :key="index"
                                >{{ value }}</b-dropdown-item
                              >
                            </b-dropdown>
                          </div>
                        </div>

                        <div
                          class="col-6 col-lg-6 mb-3 mb-lg-3"
                          v-if="forms.nature_of_contract.value == 'recurring'"
                        >
                          <label class="mb-1">Tenure</label>
                          <div class="input-group">
                            <input
                              v-model.trim="
                                forms.facility_term_sheet.pre_factoring.tenure
                              "
                              type="tel"
                              max-length="3"
                              class="form-control"
                              placeholder="Tenure"
                              @input="
                                inputNumberOnlyV5(
                                  $event,
                                  'forms',
                                  'facility_term_sheet',
                                  'pre_factoring',
                                  'tenure',
                                  'normal'
                                ),
                                  runFormula()
                              "
                            />
                            <b-dropdown variant="secondary" right>
                              <template v-slot:button-content>
                                Months
                                <i class="mdi mdi-chevron-down"></i>
                              </template>
                              <b-dropdown-item
                                href="javascript:void(0)"
                                @click="
                                  (forms.facility_term_sheet.pre_factoring.tenure =
                                    value),
                                    runFormula()
                                "
                                :value="value"
                                v-for="(value, index) in tenure"
                                :key="index"
                                >{{ value }}</b-dropdown-item
                              >
                            </b-dropdown>
                          </div>
                        </div>

                        <div class="col-6 col-lg-6 mb-3 mb-lg-3">
                          <label class="mb-1">Processing Fees</label>
                          <input
                            v-model.trim="
                              forms.facility_term_sheet.pre_factoring
                                .processing_fees
                            "
                            type="tel"
                            class="form-control text-start"
                            placeholder="Processing Fees"
                            @input="
                              inputNumberOnlyV5(
                                $event,
                                'forms',
                                'facility_term_sheet',
                                'pre_factoring',
                                'processing_fees',
                                'normal'
                              ),
                                runFormula()
                            "
                          />
                        </div>
                        <div
                          class="col-12 col-lg-6 mb-3 mb-lg-3"
                          v-if="forms.nature_of_contract.value == 'recurring'"
                        >
                          <label class="mb-1">Installment Due Dates</label>
                          <select
                            class="form-select pe-5"
                            v-model="
                              forms.facility_term_sheet.pre_factoring
                                .installment_due_dates
                            "
                            :disabled="loading"
                            @change="runFormula()"
                          >
                            <option
                              :value="value"
                              v-for="(value, index) in installment_due_dates"
                              :key="index"
                            >
                              {{ value.label }}
                              {{
                                value.value !== 0 ? "(" + value.value + ")" : ""
                              }}
                            </option>
                          </select>
                        </div>
                        <div
                          class="col-12 col-lg-6 mb-3 mb-lg-1"
                          v-if="forms.nature_of_contract.value !== 'recurring'"
                        >
                          <label class="mb-1">Day of Collections</label>
                          <input
                            v-model.trim="forms.facility_term_sheet.pre_factoring.day_of_collections"
                            type="tel"
                            class="form-control text-start"
                            placeholder="Day of Collections"
                            @input="
                              inputNumberOnlyV5(
                                $event,
                                'forms',
                                'facility_term_sheet',
                                'pre_factoring',
                                'day_of_collections',
                                'normal'
                              ),
                              runFormula()
                            "
                          />
                        </div>
                        <div class="col-12 col-lg-6 mb-3 mb-lg-1" >
                          <label class="mb-1">Custom Profit Amount
                            <span class="text-success" v-if="forms.project_income.term_advance_pre_factoring.profit">
                            ({{ convertCurrencyFormat(forms.project_income.term_advance_pre_factoring.profit || 0,false )}})
                            </span>
                          </label>
                          <div class="input-group">
                          <input v-model.trim="forms.project_income.term_advance_pre_factoring.profit"
                            type="tel"
                            class="form-control text-start"
                            placeholder="Profit Amount"
                            @input="
                              inputNumberOnlyV5(
                                $event,
                                'forms',
                                'project_income',
                                'term_advance_pre_factoring',
                                'profit',
                                'normal'
                              )
                            "
                            @blur="updateProjectIncomeTotal()"
                          />
                          <button class="btn btn-primary btn-sm" @click="forms.project_income.term_advance_pre_factoring.profit='',runFormula()" v-b-tooltip.hover.left
                                  :title="'Recalculate by Auto'"><i class="mdi mdi-reload font-size-18"></i></button>
                          </div>
                        </div>
                        <div
                          class="col-12 col-lg-6 mb-0 mb-lg-1"
                        >
                          <label class="mb-1">Enable Pre-Factoring </label>
                          <div class="btn-group d-block" role="group" aria-label="" >
                            <input type="radio" class="btn-check" value="true" name="btnradio" id="yes_pre_factoring" autocomplete="off" v-model="forms.facility_term_sheet.pre_factoring.enabled">
                            <label class="btn btn-outline-primary mb-0" for="yes_pre_factoring">Yes</label>

                            <input type="radio" class="btn-check" name="btnradio" id="no_pre_factoring" autocomplete="off" v-model="forms.facility_term_sheet.pre_factoring.enabled" value="false" >
                            <label class="btn btn-outline-primary mb-0" for="no_pre_factoring">No</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-3 mb-lg-0">
                    <div class="p-2 border border-light2 bg-light rounded">
                      <div
                        class="p-2 bg-soft-primary text-center fw-medium text-primary rounded mb-3"
                      >
                        Factoring
                      </div>
                      <div class="mb-3">
                        <label class="mb-1">Purpose</label>
                        <select
                          class="form-select pe-5 mb-3"
                          v-model="forms.factoring_purpose_type"
                          :disabled="loading"
                          @change="purposeChange()"
                        >
                          <option
                            :value="value"
                            v-for="(value, index) in factoring_purpose_type"
                            :key="index"
                          >
                            {{ value }}
                          </option>
                        </select>
                        <label class="mb-1"
                          >Purpose
                          <span class="text-muted font-size-11"
                            >(This purpose will appear in Document C: Facility
                            Term Sheet - Factoring)</span
                          ></label
                        >
                        <input
                          v-model.trim="
                            forms.facility_term_sheet.factoring.purpose
                          "
                          type="text"
                          class="form-control text-start"
                          placeholder="Purpose"
                        />
                      </div>
                      <div class="mb-3">
                        <label class="mb-1"
                          >Proposed Limit
                          <span
                            class="text-success"
                            v-if="forms.facility_term_sheet.factoring.limit"
                          >
                            ({{
                              convertCurrencyFormat(
                                forms.facility_term_sheet.factoring.limit || 0,
                                false
                              )
                            }})</span
                          ></label
                        >
                        <input
                          v-model.trim="
                            forms.facility_term_sheet.factoring.limit
                          "
                          type="tel"
                          class="form-control text-start"
                          placeholder="Proposed Limit"
                          @input="
                            inputNumberOnlyV5(
                              $event,
                              'forms',
                              'facility_term_sheet',
                              'factoring',
                              'limit',
                              'normal'
                            ),
                              runFormula(),
                              purposeLimitFollowPurposeChange()
                          "
                        />
                      </div>
                      <div class="row">
                        <div class="col-6 col-lg-6 mb-3">
                          <label class="mb-1">Administration Fee (%)</label>
                          <input
                            v-model.trim="
                              forms.facility_term_sheet.factoring
                                .administration_fee
                            "
                            type="tel"
                            class="form-control text-start"
                            placeholder="Administration Fee"
                            @input="
                              inputNumberOnlyV5(
                                $event,
                                'forms',
                                'facility_term_sheet',
                                'factoring',
                                'administration_fee',
                                'decimal'
                              ),
                                runFormula()
                            "
                          />
                        </div>
                        <div class="col-6 col-lg-6 mb-3">
                          <label class="mb-1">Profit Rate (%)</label>
                          <input
                            v-model.trim="
                              forms.facility_term_sheet.factoring.profit_rate
                            "
                            type="tel"
                            class="form-control text-start"
                            placeholder="Profit Rate"
                            @input="
                              inputNumberOnlyV5(
                                $event,
                                'forms',
                                'facility_term_sheet',
                                'factoring',
                                'profit_rate',
                                'decimal'
                              ),
                                runFormula()
                            "
                          />
                        </div>

                        <div class="col-6 col-lg-6 mb-3">
                          <label class="mb-1">Rollover Fee (%)</label>
                          <input
                            v-model.trim="
                              forms.facility_term_sheet.factoring.rollover_fee
                            "
                            type="tel"
                            class="form-control text-start"
                            placeholder="Rollover Fee"
                            @input="
                              inputNumberOnlyV5(
                                $event,
                                'forms',
                                'facility_term_sheet',
                                'factoring',
                                'rollover_fee',
                                'decimal'
                              ),
                                runFormula()
                            "
                          />
                        </div>
                        <div class="col-6 col-lg-6 mb-3">
                          <label class="mb-1">Margin of Advance (%)</label>
                          <input
                            v-model.trim="
                              forms.facility_term_sheet.factoring
                                .margin_of_advance
                            "
                            type="tel"
                            class="form-control text-start"
                            placeholder="Margin of Advance"
                            @input="
                              inputNumberOnlyV5(
                                $event,
                                'forms',
                                'facility_term_sheet',
                                'factoring',
                                'margin_of_advance',
                                'normal'
                              ),
                                runFormula()
                            "
                          />
                        </div>
                        <div class="col-6 col-lg-6 mb-3 mb-lg-3">
                          <label class="mb-1">Credit Period</label>
                          <div class="input-group">
                            <input
                              v-model.trim="
                                forms.facility_term_sheet.factoring
                                  .credit_period
                              "
                              type="tel"
                              max-length="3"
                              class="form-control"
                              placeholder="Month"
                              @input="
                                inputNumberOnlyV5(
                                  $event,
                                  'forms',
                                  'facility_term_sheet',
                                  'factoring',
                                  'credit_period',
                                  'normal'
                                ),
                                  runFormula()
                              "
                            />
                            <b-dropdown variant="secondary" right>
                              <template v-slot:button-content>
                                Days
                                <i class="mdi mdi-chevron-down"></i>
                              </template>
                              <b-dropdown-item
                                href="javascript:void(0)"
                                @click="
                                  (forms.facility_term_sheet.factoring.credit_period =
                                    value),
                                    runFormula()
                                "
                                :value="value"
                                v-for="(value, index) in period"
                                :key="index"
                                >{{ value }}</b-dropdown-item
                              >
                            </b-dropdown>
                          </div>
                        </div>
                        <div class="col-6 col-lg-6 mb-3 mb-lg-0">
                          <label class="mb-1">Recourse Period</label>
                          <div class="input-group">
                            <input
                              v-model.trim="
                                forms.facility_term_sheet.factoring
                                  .recourse_period
                              "
                              type="tel"
                              max-length="3"
                              class="form-control"
                              placeholder="Month"
                              @input="
                                inputNumberOnlyV5(
                                  $event,
                                  'forms',
                                  'facility_term_sheet',
                                  'factoring',
                                  'recourse_period',
                                  'normal'
                                ),
                                  runFormula()
                              "
                            />
                            <b-dropdown variant="secondary" right>
                              <template v-slot:button-content>
                                Days
                                <i class="mdi mdi-chevron-down"></i>
                              </template>
                              <b-dropdown-item
                                href="javascript:void(0)"
                                @click="
                                  (forms.facility_term_sheet.factoring.recourse_period =
                                    value),
                                    runFormula()
                                "
                                :value="value"
                                v-for="(value, index) in period"
                                :key="index"
                                >{{ value }}</b-dropdown-item
                              >
                            </b-dropdown>
                          </div>
                        </div>

                        <div class="col-12 col-lg-6 mb-1" :class="{'mb-2':forms.nature_of_contract.value == 'milestone_staggered' }">
                          <label class="mb-1">Processing Fees</label>
                          <input
                            v-model.trim="forms.facility_term_sheet.factoring.processing_fees"
                            type="tel"
                            class="form-control text-start"
                            placeholder="Processing Fees"
                            @input="
                              inputNumberOnlyV5(
                                $event,
                                'forms',
                                'facility_term_sheet',
                                'factoring',
                                'processing_fees',
                                'normal'
                              ),
                                runFormula()
                            "
                          />
                        </div>
            
                        <div class="col-12" ><hr>
                        </div>
                        <div class="col-12 col-lg-6 mb-3 mb-lg-1">
                          <label class="mb-1">Custom Administration Fee
                            <span class="text-success" v-if="forms.project_income.factoring.administration_fee">
                            ({{ convertCurrencyFormat(forms.project_income.factoring.administration_fee || 0,false )}})
                            </span>
                          </label>
                          <div class="input-group">
                          <input v-model.trim="forms.project_income.factoring.administration_fee"
                            type="tel"
                            class="form-control text-start border-end-0"
                            placeholder="Administration Fee"
                            @input="
                              inputNumberOnlyV5(
                                $event,
                                'forms',
                                'project_income',
                                'factoring',
                                'administration_fee',
                                'normal'
                              )
                            "
                            @blur="runFormula()"
                          />
                          <button class="btn btn-primary btn-sm" @click="forms.project_income.factoring.administration_fee='',runFormula()" v-b-tooltip.hover.left
                                  :title="'Recalculate by Auto'"><i class="mdi mdi-reload font-size-18"></i></button>
                          </div>
                        </div>
                        <div class="col-12 col-lg-6 mb-1" >
                          <label class="mb-1">Custom Profit Amount
                            <span class="text-success" v-if="forms.project_income.factoring.profit">
                            ({{ convertCurrencyFormat(forms.project_income.factoring.profit || 0,false )}})
                            </span>
                          </label>
                          <div class="input-group">
                          <input v-model.trim="forms.project_income.factoring.profit"
                            type="tel"
                            class="form-control text-start"
                            placeholder="Profit Amount"
                            @input="
                              inputNumberOnlyV5(
                                $event,
                                'forms',
                                'project_income',
                                'factoring',
                                'profit',
                                'normal'
                              )
                            "
                            @blur="runFormula()"
                          />
                          <button class="btn btn-primary btn-sm" @click="forms.project_income.factoring.profit='',runFormula()" v-b-tooltip.hover.left
                                  :title="'Recalculate by Auto'"><i class="mdi mdi-reload font-size-18"></i></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark"
              data-toggle="collapse"
              aria-expanded="true"
              aria-controls=""
              v-b-toggle.accordion-1
            >
              <div class="p-3">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-20"
                      >
                        A
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-0">Executive Summary</h5>
                    <p class="text-muted text-truncate mb-0 font-size-13">
                      Current and Proposed Facilities, Contract Details,
                      Forecast Advance and Collection Timeline and Projected
                      Income
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse id="accordion-1" visible class="position-relative">
              <div class="position-absolute opacity-50 bg-white top-0 start-0 w-100 h-100 disabled-area" v-if="isEditPage && accessUsername !== forms.originator.username"></div>
              <div class="p-3 border-top">
                <div class="row">
                  <!-- Part A -->
                  <div class="col-12 mb-3">
                    <div
                      class="p-2 bg-soft-primary text-center fw-medium text-primary rounded"
                    >
                      Part A: Current and Proposed Facilities
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="table-responsive text-nowrap text-center mb-3">
                      <table
                        class="table table-bordered border-light2 mb-1 mb-0 align-middle min-width-760 partA"
                      >
                        <thead
                          class="fw-medium font-size-14 align-middle text-center"
                        >
                          <tr>
                            <th colspan="3">
                              <span
                                v-if="
                                  forms.pre_factoring_purpose_type ==
                                  'Term Advance'
                                "
                                >{{ forms.pre_factoring_purpose_type }}</span
                              >
                              Pre-Factoring
                            </th>
                            <th colspan="3">Factoring</th>
                            <th rowspan="2"><div class="mb-1">Maximum Exposure</div> <b-dropdown variant="secondary"  size="sm" right>
                                <template v-slot:button-content>
                                 <i class="uil uil-setting"></i> Formula ({{selectedMaximumExposureFormula}}%)
                                  <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item
                                  href="javascript:void(0)"
                                  @click="selectedMaximumExposureFormula = value, updateMaximumExposure()"
                                  :value="value"
                                  v-for="(value, index) in maximumExposureFormula"
                                  :key="index"
                                  >{{ value }}%</b-dropdown-item
                                >
                              </b-dropdown></th>
                          </tr>
                          <tr>
                            <th>Current</th>
                            <th>Proposed</th>
                            <th>Total</th>
                            <th>Current</th>
                            <th>Proposed</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span class="d-block">{{ forms.currency }}</span>
                              <!-- <input
                                v-model.trim="forms.term_advance_pre_factoring.current"
                                type="tel"
                                class="form-control text-end"
                                placeholder=""
                                @input="inputNumberOnlyV3($event, 'forms', 'term_advance_pre_factoring','current')"
                              />  -->
                              <div
                                v-if="forms.term_advance_pre_factoring.current"
                              >
                                {{
                                  convertCurrencyFormat(
                                    forms.term_advance_pre_factoring.current,
                                    false
                                  )
                                }}
                              </div>
                              <div v-else>0</div>
                            </td>
                            <td>
                              <span class="d-block">{{ forms.currency }}</span>
                              <!-- <input
                                v-model.trim="forms.term_advance_pre_factoring.proposed"
                                type="tel"
                                class="form-control text-end"
                                placeholder=""
                                @input="inputNumberOnlyV3($event, 'forms', 'term_advance_pre_factoring','proposed'), partA_PreFactoring()"
                               
                              />  -->
                              <div
                                v-if="forms.term_advance_pre_factoring.proposed"
                              >
                                {{
                                  convertCurrencyFormat(
                                    forms.term_advance_pre_factoring.proposed,
                                    false
                                  )
                                }}
                              </div>
                              <div v-else>0</div>
                            </td>
                            <td>
                              <span class="d-block">{{ forms.currency }}</span>
                              <!-- <input
                                v-model.trim="forms.term_advance_pre_factoring.total"
                                type="tel"
                                class="form-control text-end"
                                placeholder=""
                                @input="inputNumberOnlyV3($event, 'forms', 'term_advance_pre_factoring','total')"
                              />  -->
                              <div
                                v-if="forms.term_advance_pre_factoring.total"
                              >
                                {{
                                  convertCurrencyFormat(
                                    forms.term_advance_pre_factoring.total,
                                    false
                                  )
                                }}
                              </div>
                              <div v-else>0</div>
                            </td>
                            <td>
                              <span class="d-block">{{ forms.currency }}</span>
                              <!-- <input
                                v-model.trim="forms.factoring.current"
                                type="tel"
                                class="form-control text-end"
                                placeholder=""
                                @input="inputNumberOnlyV3($event, 'forms', 'factoring','current')"
                              />  -->
                              <div v-if="forms.factoring.total">
                                {{
                                  convertCurrencyFormat(
                                    forms.factoring.current,
                                    false
                                  )
                                }}
                              </div>
                              <div v-else>0</div>
                            </td>
                            <td>
                              <span class="d-block">{{ forms.currency }}</span>
                              <!-- <input
                                v-model.trim="forms.factoring.proposed"
                                type="tel"
                                class="form-control text-end"
                                placeholder=""
                                @input="inputNumberOnlyV3($event, 'forms', 'factoring','proposed'), partA_Factoring()"
                              /> -->
                              <div v-if="forms.factoring.proposed">
                                {{
                                  convertCurrencyFormat(
                                    forms.factoring.proposed,
                                    false
                                  )
                                }}
                              </div>
                              <div v-else>0</div>
                            </td>
                            <td>
                              <span class="d-block">{{ forms.currency }}</span>
                              <!-- <input
                                v-model.trim="forms.factoring.total"
                                type="tel"
                                class="form-control text-end"
                                placeholder=""
                                @input="inputNumberOnlyV3($event, 'forms', 'factoring','total')"
                              /> -->
                              <div v-if="forms.factoring.total">
                                {{
                                  convertCurrencyFormat(
                                    forms.factoring.total,
                                    false
                                  )
                                }}
                              </div>
                              <div v-else>0</div>
                            </td>
                            <td>
                              <div class="input-group mb-3">
                              <input
                                v-model.trim="forms.maximum_exposure"
                                type="tel"
                                class="form-control"
                                placeholder="Maximum Exposure"
                                @input="
                                  inputNumberOnly(
                                    $event,
                                    'forms',
                                    'maximum_exposure',
                                    'normal'
                                  ),
                                  changeAmountPaymentModeContractType()
                                "
                              />
                              
                              <button class="btn btn-primary btn-sm" style="margin-left:-1px" @click="updateMaximumExposure()" v-b-tooltip.hover.left
                                  :title="'Recalculate by Auto'"><i class="mdi mdi-reload font-size-18"></i>
                              </button>
                            </div>
                              <span class="d-block mb-1 text-success fw-medium"
                                >{{ forms.currency }}
                                <span v-if="forms.maximum_exposure">{{
                                  convertCurrencyFormat(
                                    forms.maximum_exposure,
                                    false
                                  )
                                }}</span></span
                              >
                              <!-- <input
                                v-model.trim="forms.maximum_exposure"
                                type="tel"
                                class="form-control text-center"
                                placeholder=""
                                @input="
                                  inputNumberOnly(
                                    $event,
                                    'forms',
                                    'maximum_exposure',
                                    'normal'
                                  ),
                                    changeAmountPaymentModeContractType()
                                "
                              /> -->

                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <!-- Part B -->
                  <div class="col-12 mb-3">
                    <div
                      class="p-2 bg-soft-primary text-center fw-medium text-primary rounded"
                    >
                      Part B: Contract Details
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 mb-3">
                    <div class="d-flex align-items-center">
                      <label class="me-2 mb-0">Contract</label>
                    
                      <div class="w-100">
                        <select
                          class="form-select pe-5"
                          v-model="forms.contract_type"
                          :disabled="loading"
                          @change="changeAmountPaymentModeContractType()"
                        >
                          <option
                            :value="value"
                            v-for="(value, index) in contracts_type"
                            :key="index"
                          >
                            {{ value.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mb-3">
                    <div class="table-responsive text-nowrap text-center mb-3">
                      <table
                        class="table table-bordered border-light2 mb-1 mb-0 align-middle min-width-760 partB"
                      >
                        <thead
                          class="fw-medium font-size-14 align-middle text-center"
                        >
                          <tr>
                            <th>Title</th>
                            <th>Awarder</th>
                            <th>Nature Of Contract</th>
                            <th>Amount</th>
                            <th>Tenure</th>
                            <th>Mode</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-wrap" width="320">
                              <textarea
                                class="form-control"
                                rows="4"
                                v-model.trim="forms.contract_title"
                              ></textarea>
                            </td>
                            <td class="text-wrap" style="min-width: 210px">
                              <select
                                class="form-select pe-5"
                                v-model="awarderSelected"
                                @change="awarderChange"
                              >
                                <option
                                  v-for="(value, index) in awarder_list"
                                  :key="index"
                                  :value="value"
                                >
                                  {{ value }}
                                </option>
                              </select>
                              <textarea
                                v-if="awarderSelected == 'Other'"
                                type="text"
                                placeholder="Awarder"
                                class="mt-2 form-control"
                                v-model.trim="awarderSelfInput"
                                @input="awarderChange"
                                rows="3"
                              ></textarea>
                            </td>
                            <td class="text-center border-end border-light2">
                              {{ forms.nature_of_contract.label }}
                            </td>
                            <td width="160">
                              <span class="d-block">{{ forms.currency }}</span>
                              <!-- <input
                                v-model.trim="forms.contract_amount"
                                type="tel"
                                class="form-control text-end"
                                placeholder=""
                                @input="inputNumberOnly($event, 'forms', 'amount')"
                              />  -->
                              <div v-if="forms.contract_amount">
                                {{
                                  convertCurrencyFormat(
                                    forms.contract_amount,
                                    false
                                  )
                                }}
                              </div>
                              <div v-else>0</div>
                            </td>
                            <td class="text-wrap" width="150">
                              <div>Month</div>
                              <select
                                class="form-select pe-5"
                                v-model="contractTenureSelected"
                                @change="contractTenureChange"
                              >
                                <option
                                  v-for="(value, index) in contract_tenure"
                                  :key="index"
                                  :value="value"
                                >
                                  {{ value }}
                                </option>
                              </select>
                              <input
                                v-if="contractTenureSelected == 'Other'"
                                v-model.trim="contractTenureSelfInput"
                                type="tel"
                                max-length="3"
                                class="form-control text-center mt-2"
                                placeholder="Month"
                                @input="
                                  inputNumberOnly(
                                    $event,
                                    '',
                                    'contractTenureSelfInput',
                                    'normal'
                                  ),
                                    contractTenureChange()
                                "
                              />
                            </td>
                            <td width="200">
                              <div>&nbsp;</div>
                              <select
                                class="form-select pe-5"
                                v-model="forms.payment_mode"
                                :disabled="loading"
                                @change="changeAmountPaymentModeContractType()"
                              >
                                <option
                                  :value="value"
                                  v-for="(value, index) in payment_mode"
                                  :key="index"
                                >
                                  {{ value.name }} ({{ value.value }})
                                </option>
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <!-- Part C -->
                  <div class="col-12 mb-3">
                    <div
                      class="p-2 bg-soft-primary text-center fw-medium text-primary rounded"
                    >
                      Part C: Forecast Advance and Collection Timeline
                    </div>
                  </div>
                  <div
                    class="col-12 mb-3"
                    v-show="forms.nature_of_contract.value !== 'one_off' && forms.nature_of_contract.value !== 'milestone_staggered'"
                  >
                    <a
                      href="javascript:void(0)"
                      class="text-dark fw-medium bg-light p-2 d-block mb-2 rounded"
                      data-toggle="collapse"
                      v-b-toggle.collection-collapse
                    >
                      Click here to view Forecast Advance and Collection
                      Timeline Table
                      <i
                        class="mdi mdi-chevron-up font-size-16 text-danger float-end accor-down-icon"
                      ></i>
                    </a>
                    <b-collapse id="collection-collapse" visible>
                      <div
                        class="table-responsive text-nowrap text-center mb-3"
                      >
                        <table
                          class="table border border-light2 mb-1 mb-0 align-middle min-width-760 partC"
                        >
                          <thead
                            class="fw-medium font-size-14 align-middle text-center"
                          >
                            <tr>
                              <th colspan="20">Month (Preview)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                colspan="4"
                                v-for="(
                                  value, index
                                ) in forms.collection_forecast"
                                :key="index"
                                class="border-end"
                                width="20%"
                              >
                                {{ value.name }}
                              </td>
                            </tr>
                            <tr class="font-size-14">
                              <td
                                v-for="(v, i) in forms.collection_forecast[0]
                                  .weeks"
                                :key="'cf1' + i"
                                class="border-end"
                                width="5%"
                              >
                                {{ v.name }}
                              </td>
                              <td
                                v-for="(v, i) in forms.collection_forecast[1]
                                  .weeks"
                                :key="'cf2' + i"
                                class="border-end"
                                width="5%"
                              >
                                {{ v.name }}
                              </td>
                              <td
                                v-for="(v, i) in forms.collection_forecast[2]
                                  .weeks"
                                :key="'cf3' + i"
                                class="border-end"
                                width="5%"
                              >
                                {{ v.name }}
                              </td>
                              <td
                                v-for="(v, i) in forms.collection_forecast[3]
                                  .weeks"
                                :key="'cf4' + i"
                                class="border-end"
                                width="5%"
                              >
                                {{ v.name }}
                              </td>
                              <td
                                v-for="(v, i) in forms.collection_forecast[4]
                                  .weeks"
                                :key="'cf5' + i"
                                class="border-end"
                                width="5%"
                              >
                                {{ v.name }}
                              </td>
                            </tr>
                            <tr>
                              <td
                                class="position-relative"
                                v-for="(v, i) in forms.collection_forecast[0]
                                  .weeks"
                                :key="'cfw' + i"
                                :class="{ 'border-end': i == 3 }"
                              >
                                <span v-if="v.remark">
                                  <span class="topArrow"></span>
                                  <div
                                    class="position-absolute px-1"
                                    :class="{
                                      'end-0': i == 3 || i == 2,
                                      'start-0': i == 0 || i == 1,
                                    }"
                                  >
                                    <span
                                      v-html="v.remark.replaceAll('\n', '<br>')"
                                    ></span>

                                    <div
                                      v-if="
                                        forms.term_advance_pre_factoring
                                          .proposed
                                      "
                                    >
                                      {{ forms.currency }}
                                      {{
                                        convertCurrencyFormat(
                                          forms.term_advance_pre_factoring
                                            .proposed,
                                          false
                                        )
                                      }}
                                    </div>
                                  </div>
                                </span>
                              </td>
                              <td
                                class="border-none position-relative"
                                v-for="(v, i) in forms.collection_forecast[1]
                                  .weeks"
                                :key="'cfw2' + i"
                                :class="{ 'border-end': i == 3 }"
                              >
                                <span v-if="v.remark">
                                  <span class="topArrow"></span>
                                  <span
                                    v-html="v.remark.replaceAll('\n', '<br>')"
                                    class="position-absolute px-2"
                                    :class="{
                                      'end-0': i == 3 || i == 2,
                                      'start-0': i == 0 || i == 1,
                                    }"
                                  ></span>
                                </span>
                              </td>
                              <td
                                class="border-none position-relative"
                                v-for="(v, i) in forms.collection_forecast[2]
                                  .weeks"
                                :key="'cfw3' + i"
                                :class="{ 'border-end': i == 3 }"
                              >
                                <span v-if="v.remark">
                                  <span class="topArrow"></span>
                                  <span
                                    v-html="v.remark.replaceAll('\n', '<br>')"
                                    class="position-absolute px-2"
                                    :class="{
                                      'end-0': i == 3 || i == 2,
                                      'start-0': i == 0 || i == 1,
                                    }"
                                  ></span>
                                </span>
                              </td>
                              <td
                                class="border-none position-relative"
                                v-for="(v, i) in forms.collection_forecast[3]
                                  .weeks"
                                :key="'cfw4' + i"
                                :class="{ 'border-end': i == 3 }"
                              >
                                <span v-if="v.remark">
                                  <span class="topArrow"></span>
                                  <span
                                    v-html="v.remark.replaceAll('\n', '<br>')"
                                    class="position-absolute px-2"
                                    :class="{
                                      'end-0': i == 3 || i == 2,
                                      'start-0': i == 0 || i == 1,
                                    }"
                                  ></span>
                                </span>
                              </td>
                              <td
                                class="border-none position-relative"
                                v-for="(v, i) in forms.collection_forecast[4]
                                  .weeks"
                                :key="'cfw5' + i"
                                :class="{ 'border-end': i == 3 }"
                              >
                                <span v-if="v.remark">
                                  <span class="topArrow"></span>
                                  <span
                                    v-html="v.remark.replaceAll('\n', '<br>')"
                                    class="position-absolute px-2"
                                    :class="{
                                      'end-0': i == 3 || i == 2,
                                      'start-0': i == 0 || i == 1,
                                    }"
                                  ></span>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td
                                colspan="4"
                                class="border-top-0 border-end pb-3"
                                height="42"
                              >
                                <span
                                  v-html="
                                    forms.collection_forecast[0].general_remark
                                  "
                                ></span>
                              </td>
                              <td
                                colspan="4"
                                class="border-top-0 border-end pb-3"
                              >
                                <span
                                  v-html="
                                    forms.collection_forecast[1].general_remark
                                  "
                                ></span>
                              </td>
                              <td
                                colspan="4"
                                class="border-top-0 border-end pb-3"
                              >
                                <span
                                  v-html="
                                    forms.collection_forecast[2].general_remark
                                  "
                                ></span>
                              </td>
                              <td
                                colspan="4"
                                class="border-top-0 border-end pb-3"
                              >
                                <span
                                  v-html="
                                    forms.collection_forecast[3].general_remark
                                  "
                                ></span>
                              </td>
                              <td
                                colspan="4"
                                class="border-top-0 border-end pb-3"
                              >
                                <span
                                  v-html="
                                    forms.collection_forecast[4].general_remark
                                  "
                                ></span>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="20">
                                Average
                                <b
                                  >{{
                                    forms.average_collection_forecast.label
                                  }}-Days</b
                                >
                                Collection Forecast
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-collapse>
                    <div class="table-responsive text-nowrap text-center mb-3">
                      <table
                        class="table border border-light2 mb-1 mb-0 align-middle min-width-760 partC"
                      >
                        <thead
                          class="fw-medium font-size-14 align-middle text-center"
                        >
                          <tr>
                            <th colspan="20">Month</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              colspan="4" v-for="(value, index) in forms.collection_forecast" :key="index"  class="border-end" width="20%">
                              {{ value.name }}
                            </td>
                          </tr>
                          <tr class="font-size-14">
                            <td
                              v-for="(v, i) in forms.collection_forecast[0].weeks"
                              :key="'cf1' + i"
                              class="border-end"
                            >
                              {{ v.name }}
                            </td>
                            <td
                              v-for="(v, i) in forms.collection_forecast[1].weeks"
                              :key="'cf2' + i"
                              class="border-end"
                            >
                              {{ v.name }}
                            </td>
                            <td
                              v-for="(v, i) in forms.collection_forecast[2].weeks"
                              :key="'cf3' + i"
                              class="border-end"
                            >
                              {{ v.name }}
                            </td>
                            <td
                              v-for="(v, i) in forms.collection_forecast[3].weeks"
                              :key="'cf4' + i"
                              class="border-end"
                            >
                              {{ v.name }}
                            </td>
                            <td
                              v-for="(v, i) in forms.collection_forecast[4].weeks"
                              :key="'cf5' + i"
                              class="border-end"
                            >
                              {{ v.name }}
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4" class="border-end pb-1">
                              <div
                                class="input-group flex-nowrap font-size-14 mb-1"
                                v-for="(v, i) in forms.collection_forecast[0].weeks"
                                :key="'cfw0' + i"
                              >
                                <span class="input-group-text">{{
                                  i + 1
                                }}</span>
                                <textarea
                                  class="form-control"
                                  rows="2"
                                  v-model="v.remark"
                                ></textarea>
                              </div>
                              <div class="form-floating">
                                <textarea
                                  class="form-control font-size-14"
                                  placeholder="General Remark"
                                  v-model="
                                    forms.collection_forecast[0].general_remark
                                  "
                                  id="floatingTextarea"
                                ></textarea>
                                <label for="floatingTextarea" class="fw-normal"
                                  >General Remark</label
                                >
                              </div>
                            </td>
                            <td colspan="4" class="border-end">
                              <div
                                class="input-group flex-nowrap font-size-14 mb-1"
                                v-for="(v, i) in forms.collection_forecast[1].weeks"
                                :key="'cfw1' + i"
                              >
                                <span class="input-group-text">{{
                                  i + 1
                                }}</span>
                                <textarea
                                  class="form-control"
                                  rows="2"
                                  v-model="v.remark"
                                ></textarea>
                              </div>
                              <div class="form-floating">
                                <textarea
                                  class="form-control font-size-14"
                                  placeholder="General Remark"
                                  v-model="forms.collection_forecast[1].general_remark"
                                  id="floatingTextarea"
                                ></textarea>
                                <label for="floatingTextarea" class="fw-normal"
                                  >General Remark</label
                                >
                              </div>
                            </td>
                            <td colspan="4" class="border-end">
                              <div
                                class="input-group flex-nowrap font-size-14 mb-1"
                                v-for="(v, i) in forms.collection_forecast[2].weeks"
                                :key="'cfw2' + i"
                              >
                                <span class="input-group-text">{{
                                  i + 1
                                }}</span>
                                <textarea
                                  class="form-control"
                                  rows="2"
                                  v-model="v.remark"
                                ></textarea>
                              </div>
                              <div class="form-floating">
                                <textarea
                                  class="form-control font-size-14"
                                  placeholder="General Remark"
                                  v-model="forms.collection_forecast[2].general_remark"
                                  id="floatingTextarea"
                                ></textarea>
                                <label for="floatingTextarea" class="fw-normal"
                                  >General Remark</label
                                >
                              </div>
                            </td>
                            <td colspan="4" class="border-end">
                              <div
                                class="input-group flex-nowrap font-size-14 mb-1"
                                v-for="(v, i) in forms.collection_forecast[3].weeks"
                                :key="'cfw3' + i"
                              >
                                <span class="input-group-text">{{
                                  i + 1
                                }}</span>
                                <textarea
                                  class="form-control"
                                  rows="2"
                                  v-model="v.remark"
                                ></textarea>
                              </div>
                              <div class="form-floating">
                                <textarea
                                  class="form-control font-size-14"
                                  placeholder="General Remark"
                                  v-model="
                                    forms.collection_forecast[3].general_remark
                                  "
                                  id="floatingTextarea"
                                ></textarea>
                                <label for="floatingTextarea" class="fw-normal"
                                  >General Remark</label
                                >
                              </div>
                            </td>
                            <td colspan="4" class="border-end">
                              <div
                                class="input-group flex-nowrap font-size-14 mb-1"
                                v-for="(v, i) in forms.collection_forecast[4]
                                  .weeks"
                                :key="'cfw4' + i"
                              >
                                <span class="input-group-text">{{
                                  i + 1
                                }}</span>
                                <textarea
                                  class="form-control"
                                  rows="2"
                                  v-model="v.remark"
                                ></textarea>
                              </div>
                              <div class="form-floating">
                                <textarea
                                  class="form-control font-size-14"
                                  placeholder="General Remark"
                                  v-model="forms.collection_forecast[4].general_remark"
                                  id="floatingTextarea"
                                ></textarea>
                                <label for="floatingTextarea" class="fw-normal"
                                  >General Remark</label
                                >
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="20">
                              Average
                              <b
                                >{{
                                  forms.average_collection_forecast.label
                                }}-Days</b
                              >
                              Collection Forecast
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    class="col-12 mb-3"
                    v-show="forms.nature_of_contract.value == 'milestone_staggered'"
                  >
                    <a
                      href="javascript:void(0)"
                      class="text-dark fw-medium bg-light p-2 d-block mb-2 rounded"
                      data-toggle="collapse"
                      v-b-toggle.collection-collapse
                    >
                      Click here to view Forecast Advance and Collection
                      Timeline Table (Milestone/Staggered)
                      <i
                        class="mdi mdi-chevron-up font-size-16 text-danger float-end accor-down-icon"
                      ></i>
                    </a>
                    <b-collapse id="collection-collapse" visible>
                      <div
                        class="table-responsive text-nowrap text-center mb-3"
                      >
                        <table
                          name="collection_forecast_custom"
                          class="table border border-light2 mb-1 mb-0 align-top min-width-760 partC"
                        >
                          <thead
                            class="fw-medium font-size-14 align-middle text-center"
                          >
                            <tr>
                              <th :colspan="`${parseFloat(2*forms.collection_forecast_milestoneStaggered.length)}`">Tenure ({{forms.collection_forecast_custom_tenure_type}}) (Preview)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="font-size-14">
                              <td
                                v-for="(value, index) in forms.collection_forecast_milestoneStaggered" :key="index"
                                colspan="2"
                                class="border-end"
                                :width="`${parseFloat(100/forms.collection_forecast_milestoneStaggered.length)}%`"
                              >
                              <span v-for="(v, i) in value.months" :key="'cf'+i + i"> {{ v.name }} 
                                  <span v-if="!(forms.collection_forecast_milestoneStaggered[0]
                                  .months.length-1)==i">-</span>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td :colspan="2" v-for="(value, index) in forms.collection_forecast_milestoneStaggered" :key="index" class="border-end">
                                <div
                                class="position-relative pb-1"
                                v-for="(v, i) in value.months"
                                :key="'cfw' + i"
                                width="50%"
                                :class="{ 'text-start': i === 0 , 'text-end pt-3': i === 1  }"
                              >
                                <div class="w-100"> 
                                  <div v-if="v.remark" :class="{'pt-5 d-block': i == 1 }">
                                    <span class="topArrow"></span>
                                    <div
                                      class="position-absolute px-1"
                                      :class="{
                                        'end-0': i == 1,
                                        'start-0': i == 0,
                                      }"
                                    >
                                      <span
                                        v-html="v.remark.replaceAll('\n', '<br>')"
                                      ></span>

                                    </div>
                                  </div>
                                </div>
                               
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td
                                v-for="(value, index) in forms.collection_forecast_milestoneStaggered" :key="index"
                                colspan="2"
                                class="border-top-0 border-end pb-2"
                                height="42"
                              >
                                <span
                                  v-html="
                                    value.general_remark
                                  "
                                ></span>
                              </td>
                            </tr>
                            <tr>
                              <td :colspan="`${parseFloat(2*forms.collection_forecast_milestoneStaggered.length)}`">
                                Average
                                <b
                                  >{{
                                      forms.average_collection_forecast.label
                                  }}-Days</b
                                >
                                Collection Forecast
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-collapse>
                    <div class="table-responsive text-nowrap text-center mb-3">
                      <table
                        name=""
                        class="table border border-light2 mb-1 mb-0 align-middle min-width-760 partC"
                      >
                        <thead
                          class="fw-medium font-size-14 align-middle text-center"
                        >
                          <tr>
                            <th :colspan="`${parseFloat(2*forms.collection_forecast_milestoneStaggered.length)}`">Tenure 
                              <input
                                v-model.trim="forms.collection_forecast_custom_tenure_type"
                                type="text"
                                class="form-control text-start d-inline-block mb-2 ms-2 w-auto"
                                placeholder=""
                              />
                              <div>
                                <button class="btn btn-sm btn-dark me-2" @click="forms.collection_forecast_milestoneStaggered.push({'general_remark':'','months':[{'name':'','remark':''},{'name':'','remark':''}],'name':''})" :disabled="forms.collection_forecast_milestoneStaggered.length>=5"><i class="uil uil-plus-circle"></i> Add Column (Max.5)</button>
                                <button class="btn btn-sm btn-dark" :disabled="forms.collection_forecast_milestoneStaggered.length<=1" @click="forms.collection_forecast_milestoneStaggered.pop()"><i class="uil uil-minus-circle"></i> Remove Column (Min.1)</button>
                              </div>
                           </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="font-size-14">
                            <td
                              v-for="(value, index) in forms.collection_forecast_milestoneStaggered"
                              :key="'cfc' + index"
                              :width="`${parseFloat(100/forms.collection_forecast_milestoneStaggered.length)}%`"
                              class="border-end text-center"
                            >
                              <div class="d-flex gap-2 ">
                                <div
                                  v-for="(v, i) in value.months"
                                  :key="'cfc'+i + i"
                                  class="text-center w-100"
                                >
                                  <input
                                    v-model.trim="v.name"
                                    type="tel"
                                    @input="inputNumberOnlyCustom($event, 'collection_forecast_milestoneStaggered',index, i)"
                                    class="form-control text-start"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td  class="border-end pb-1" v-for="(value, index) in forms.collection_forecast_milestoneStaggered" :key="index">
                              <div
                                class="input-group flex-nowrap font-size-14 mb-1"
                                v-for="(v, i) in value.months"
                                :key="'cfw'+i + i"
                              >
                                <span class="input-group-text">{{
                                  i + 1
                                }}</span>
                                <textarea
                                  class="form-control"
                                  rows="3"
                                  v-model="v.remark"
                                ></textarea>
                              </div>
                              <div class="form-floating">
                                <textarea
                                  class="form-control font-size-14"
                                  placeholder="General Remark"
                                  v-model="value.general_remark"
                                  id="floatingTextarea"
                                ></textarea>
                                <label for="floatingTextarea" class="fw-normal"
                                  >General Remark</label
                                >
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td :colspan="`${parseFloat(2*forms.collection_forecast_milestoneStaggered.length)}`">
                              Average
                              <b
                                >{{
                                  forms.average_collection_forecast.label
                                }}-Days</b
                              >
                              Collection Forecast
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    class="col-12 mb-3"
                    v-show="forms.nature_of_contract.value == 'one_off'"
                  >
                    <a
                      href="javascript:void(0)"
                      class="text-dark fw-medium bg-light p-2 d-block mb-2 rounded"
                      data-toggle="collapse"
                      v-b-toggle.collectionCollapse3
                    >
                      Click here to view Forecast Advance and Collection
                      Timeline Table (One-Off)
                      <i
                        class="mdi mdi-chevron-up font-size-16 text-danger float-end accor-down-icon"
                      ></i>
                    </a>
                    <b-collapse id="collectionCollapse3" visible>
                      <div class="table-responsive text-nowrap text-center mb-3">
                        <table class="table border border-light2 mb-1 mb-0 align-middle min-width-760 partC">
                          <thead
                            class="fw-medium font-size-14 align-middle text-center"
                          >
                            <tr>
                              <th :colspan="`${parseFloat(2*forms.collection_forecast_one_off.length)}`">Month (Preview)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                colspan="2"
                                v-for="(value, index) in forms.collection_forecast_one_off" :key="index"
                                class="border-end"
                                :width="`${parseFloat(100/forms.collection_forecast_one_off.length)}`"
                              >
                                {{ value.name }}
                              </td>
                            </tr>
                            <tr class="font-size-14">
                              <td
                                colspan="2"
                                v-for="(value, index) in forms.collection_forecast_one_off" :key="index"
                                class="border-end"
                                :width="`${parseFloat(100/forms.collection_forecast_one_off.length)}%`"
                              >
                                <div class="d-flex">
                                  <div 
                                    v-for="(v, i) in value.weeks"
                                    :key="'cf'+ index + i"
                                    class="text-start w-100"
                                    :class="{ 'text-end': i == 1 }"
                                  >
                                    {{ v.name }}
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td
                                colspan="2"
                                v-for="(value, index) in forms.collection_forecast_one_off" :key="index"
                                class="border-end position-relative"
                                :width="`${parseFloat(100/forms.collection_forecast_one_off.length)}`"
                              >
                                <div class="d-flex gap-2 w-100">
                                  <div 
                                    v-for="(v, i) in value.weeks"
                                    :key="'cfw'+ index + i"
                                    class="text-start w-100"
                                    :width="`${parseFloat(100/value.weeks.length)}%`"
                                    :class="{ 'text-end': i == 1 }"
                                  >
                                  <span v-if="v.remark">
                                    <span
                                      class="topArrow"
                                      :class="{ 'text-start': i == 0,'text-end': i == 1 }"
                                    ></span>
                                    <div
                                      class="position-absolute px-2"
                                      :class="{
                                        'end-0 text-end': i == 1,
                                        'start-0 text-start': i == 0,
                                      }"
                                    >
                                      <span
                                        v-html="v.remark.replaceAll('\n', '<br>')"
                                      ></span>

                                    </div>
                                  </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td
                                colspan="2"
                                v-for="(value,index) in forms.collection_forecast_one_off" :key="index"
                                height="22"
                                class="border-top-0 border-end"
                              >
                              </td>
                            </tr>
                            <tr>
                              <td
                                colspan="2"
                                v-for="(value, index) in forms.collection_forecast_one_off" :key="index"
                                class="border-top-0 border-end pb-3"
                                height="42"
                              >
                                <span
                                  v-html="value.general_remark"
                                ></span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-collapse>
                    <div class="table-responsive text-nowrap text-center mb-3">
                      <table
                        class="table border border-light2 mb-1 mb-0 align-middle min-width-760 partC"
                      >
                        <thead
                          class="fw-medium font-size-14 align-middle text-center"
                        >
                          <tr>
                            <th :colspan="`${parseFloat(2*forms.collection_forecast_one_off.length)}`">Month
                              <div>
                                <button class="btn btn-sm btn-dark me-2" @click="forms.collection_forecast_one_off.push({ 'name': forms.collection_forecast_one_off.length+1, 'weeks': [ { 'name': '', 'remark': '' }, { 'name': '', 'remark': '' } ], 'general_remark': '' })" :disabled="forms.collection_forecast_one_off.length>=5"><i class="uil uil-plus-circle"></i> Add Column (Max.5)</button>
                                <button class="btn btn-sm btn-dark" :disabled="forms.collection_forecast_one_off.length<=1" @click="forms.collection_forecast_one_off.pop()"><i class="uil uil-minus-circle"></i> Remove Column (Min.1)</button>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              colspan="2"
                              v-for="(value, index) in forms.collection_forecast_one_off" :key="index"
                              class="border-end"
                              :width="`${parseFloat(100/forms.collection_forecast_one_off.length)}`"
                            >
                              <input
                                v-model.trim="value.name"
                                type="text"
                                class="form-control text-start"
                                placeholder=""
                              />
                            </td>
                          </tr>
                          <tr class="font-size-14">
                            <td
                              colspan="2"
                              v-for="(value, index) in forms.collection_forecast_one_off" :key="index"
                              class="border-end"
                              :width="`${parseFloat(100/forms.collection_forecast_one_off.length)}%`"
                            >
                              <div class="d-flex gap-2">
                                <div 
                                  v-for="(v, i) in value.weeks"
                                  :key="'cf'+ index + 'g' + i"
                                  class="text-start w-100"
                                  :class="{ 'text-end': i == 1 }"
                                >
                                <input
                                  v-model.trim="v.name"
                                  type="text"
                                  class="form-control text-start"
                                  placeholder="Day"
                                />
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td class="border-end pb-1" colspan="2" v-for="(value, index) in forms.collection_forecast_one_off" :key="index">
                              <div class="d-block w-100">
                                  <div 
                                    v-for="(v, i) in value.weeks"
                                    :key="'cfw'+ index+ 'w'+ + i"
                                    class="text-start w-100"
                                    :width="`${parseFloat(100/value.weeks.length)}%`"
                                  >
                                  <div class="form-floating mb-2">
                                    <textarea class="form-control" v-model="v.remark" placeholder="" :id="'cfw'+ index + i" style="height: 100px"></textarea>
                                    <label :for="'cfw'+ index + i"><span class="pt-2 d-block bg-white fw-normal text-dark" style="margin-top:-6px;">{{ v.name }}</span></label>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2" class="border-end" v-for="(value, index) in forms.collection_forecast_one_off" :key="index">
                              <div class="form-floating">
                                <textarea
                                  class="form-control font-size-14"
                                  placeholder="General Remark"
                                  v-model="value.general_remark
                                  "
                                  id="floatingTextarea"
                                ></textarea>
                                <label for="floatingTextarea" class="fw-normal"
                                  >General Remark</label
                                >
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <!-- Part D -->
                  <div class="col-12 mb-3">
                    <div
                      class="p-2 bg-soft-primary text-center fw-medium text-primary rounded"
                    >
                      Part D: Projected Income
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="table-responsive text-nowrap text-center mb-3">
                      <table
                        class="table table-bordered border-light2 mb-1 mb-0 align-middle min-width-760 partA"
                      >
                        <thead
                          class="fw-medium font-size-14 align-middle text-center"
                        >
                          <tr>
                            <th colspan="2">
                              <span
                                v-if="
                                  forms.pre_factoring_purpose_type ==
                                  'Term Advance'
                                "
                                >{{ forms.pre_factoring_purpose_type }}</span
                              >
                              Pre-Factoring
                            </th>
                            <th colspan="3">Factoring</th>
                            <th rowspan="2">Total</th>
                          </tr>
                          <tr>
                            <th>Processing Fee</th>
                            <th>Profit</th>
                            <th>Processing Fee</th>
                            <th>Administration Fee</th>
                            <th>Profit</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span class="d-block">{{ forms.currency }}</span>
                              <div v-if="forms.project_income.term_advance_pre_factoring.processing_fee" >
                                {{
                                  convertCurrencyFormat(forms.project_income.term_advance_pre_factoring.processing_fee, false)
                                }}
                              </div>
                              <div v-else>0</div>
                            </td>
                            <td>
                              <span class="d-block">{{ forms.currency }}</span>
                              <div v-if="forms.project_income.term_advance_pre_factoring.profit" >
                                {{
                                  convertCurrencyFormat(forms.project_income.term_advance_pre_factoring.profit,false)
                                }}
                              </div>
                              <div v-else>0</div>
                            </td>
                            <td>
                              <span class="d-block">{{ forms.currency }}</span>
                              <div
                                v-if="forms.project_income.factoring.processing_fee" >
                                {{
                                  convertCurrencyFormat(forms.project_income.factoring.processing_fee,false)
                                }}
                              </div>
                              <div v-else>0</div>
                            </td>
                            <td>
                              <span class="d-block">{{ forms.currency }}</span>
                              <div v-if="forms.project_income.factoring.administration_fee" >
                                {{
                                  convertCurrencyFormat(forms.project_income.factoring.administration_fee, false)
                                }}
                              </div>
                              <div v-else>0</div>
                            </td>
                            <td>
                              <span class="d-block">{{ forms.currency }}</span>
                              <div v-if="forms.project_income.factoring.profit">
                                {{
                                  convertCurrencyFormat( forms.project_income.factoring.profit,false)
                                }}
                              </div>
                              <div v-else>0</div>
                            </td>
                            <td>
                              <span class="d-block">{{ forms.currency }}</span>
                              <div v-if="forms.project_income.total">
                                {{
                                  convertCurrencyFormat(forms.project_income.total, false)
                                }}
                              </div>
                              <div v-else>0</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col-12 mb-3" :class="{'sign-area': (accessUsername==(forms.approver_1.username || '') || (accessUsername==(forms.approver_2.username || '')))}">
                    <div class="p-2 bg-light text-center fw-medium rounded">
                      Approver <button class="ms-2 btn btn-sm btn-primary" @click="
      updatetApprovingAuthorities(parseFloat(forms.maximum_exposure),forms.contract_type.value,forms.payment_mode.value)"><i class="mdi mdi-reload"></i> Update</button>
                    </div>
                  </div>
                  <div
                    class="col-md-6 col-12 text-center mb-3" :class="{'sign-area':accessUsername==(forms.approver_1.username || '')}"
                    v-if="forms.maximum_exposure"
                  >
                    <div class="mb-3" v-if="accessUsername==(forms.approver_1.username || '')">
                      <input
                        type="radio"
                        class="btn-check"
                        name="approver1"
                        ref="approver1"
                        id="approver1_approved"
                        @change="setDateAfterSign()"
                        v-model="forms.approver1_approval_status"
                        autocomplete="off"
                        value="approved"
                      />
                      <label
                        class="btn btn-outline-primary me-1"
                        for="approver1_approved"
                        >Approved</label
                      >
                      <input
                        type="radio"
                        class="btn-check"
                        name="approver1"
                        @change="setDateAfterSign()"
                        v-model="forms.approver1_approval_status"
                        ref="approver1_decline"
                        value="declined"
                        id="approver1_declined"
                        autocomplete="off"
                      />
                      <label
                        class="btn btn-outline-primary"
                        for="approver1_declined"
                        >Declined</label
                      >
                    </div>
                    <div v-else class="mb-3">
                      <input
                        type="radio"
                        class="btn-check"
                        name="approver1"
                        id="approver1_approved"
                        autocomplete="off"
                        value="approved"
                        disabled="disabled"
                      />
                      <label
                        class="btn btn-outline-primary me-1"
                        for="approver1_approved"
                        >Approved</label
                      >
                      <input
                        type="radio"
                        class="btn-check"
                        name="approver1"
                        value="declined"
                        id="approver1_declined"
                        autocomplete="off"
                        disabled="disabled"
                      />
                      <label
                        class="btn btn-outline-primary"
                        for="approver1_declined"
                        >Declined</label
                      >
                    </div>
                    <table
                      class="w-100 text-start mb-3"
                      v-if="forms.approver_1"
                    >
                      <tr>
                        <td width="140"></td>
                        <td
                          height="160"
                          class="text-center border-bottom border-dark pb-2"
                        >
                          <div>
                            <img
                              v-if="
                                forms.approver_1.signature &&
                                forms.approver1_approval_status == 'approved'
                              "
                              :src="forms.approver_1.signature"
                              height="150"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pt-2">Name</td>
                        <td class="pt-2">{{ forms.approver_1.name }}</td>
                      </tr>
                      <tr>
                        <td class="pb-1">Position</td>
                        <td class="pb-1">{{ forms.approver_1.position }}</td>
                      </tr>
                      <tr>
                        <td>Date</td>
                        <td>
                          <date-picker
                            v-model="forms.approver1_approval_date"
                            type="date"
                            value-type="format"
                            format="MMM DD, YYYY"
                            placeholder="Date"
                            :disabled="loading"
                            :disabled-date="notBeforeToday"
                            :editable="false"
                          ></date-picker>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div
                    class="col-md-6 col-12 text-center mb-3" :class="{'sign-area':accessUsername==(forms.approver_2.username || '')}"
                    v-if="forms.maximum_exposure"
                  >
                    <div class="mb-3" v-if="accessUsername==(forms.approver_2.username || '')">
                      <input
                        type="radio"
                        class="btn-check"
                        name="approver2"
                        ref="approver2"
                        id="approver2_approved"
                        @change="setDateAfterSign()"
                        v-model="forms.approver2_approval_status"
                        autocomplete="off"
                        value="approved"
                      />
                      <label
                        class="btn btn-outline-primary me-1"
                        for="approver2_approved"
                        >Approved</label
                      >

                      <input
                        type="radio"
                        class="btn-check"
                        name="approver2"
                        @change="setDateAfterSign()"
                        v-model="forms.approver2_approval_status"
                        value="declined"
                        ref="approver2_decline"
                        id="approver2_declined"
                        autocomplete="off"
                      />
                      <label
                        class="btn btn-outline-primary"
                        for="approver2_declined"
                        >Declined</label
                      >
                    </div>
                    <div class="mb-3" v-else>
                      <input
                        type="radio"
                        class="btn-check"
                        name="approver2"
                        id="approver2_approved"
                        autocomplete="off"
                        value="approved"
                        disabled="disabled"
                      />
                      <label
                        class="btn btn-outline-primary me-1"
                        for="approver2_approved"
                        >Approved</label
                      >

                      <input
                        type="radio"
                        class="btn-check"
                        name="approver2"
                        value="declined"
                        id="approver2_declined"
                        autocomplete="off"
                        disabled="disabled"
                      />
                      <label
                        class="btn btn-outline-primary"
                        for="approver2_declined"
                        >Declined</label
                      >
                    </div>
                    <table
                      class="w-100 text-start mb-3"
                      v-if="forms.approver_1"
                    >
                      <tr>
                        <td width="140"></td>
                        <td
                          height="160"
                          class="text-center border-bottom border-dark pb-2"
                        >
                          <div>
                            <img
                              v-if="
                                forms.approver_2.signature &&
                                forms.approver2_approval_status == 'approved'
                              "
                              :src="forms.approver_2.signature"
                              height="150"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pt-2">Name</td>
                        <td class="pt-2">{{ forms.approver_2.name }}</td>
                      </tr>
                      <tr>
                        <td class="pb-1">Position</td>
                        <td class="pb-1">{{ forms.approver_2.position }}</td>
                      </tr>
                      <tr>
                        <td>Date</td>
                        <td>
                          <date-picker
                            v-model="forms.approver2_approval_date"
                            type="date"
                            value-type="format"
                            format="MMM DD, YYYY"
                            placeholder="Date"
                            :disabled="loading"
                            :disabled-date="notBeforeToday"
                            :editable="false"
                          ></date-picker>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="row justify-content-between align-items-center">
                  <div class="col-md-auto col-6 mb-3">
                    <div class="mb-1">ROCE (%)</div>
                    <input
                      v-model.trim="forms.roce"
                      type="tel"
                      class="form-control text-start"
                      placeholder="ROCE"
                      @input="
                        inputNumberOnly($event, 'forms', 'roce', 'decimal')
                      "
                    />
                  </div>
                  <div class="col-md-auto col-6 mb-3">
                    <div class="mb-1">Tenure (Months)</div>
                    <input
                      v-model.trim="forms.project_tenure"
                      type="tel"
                      class="form-control text-start"
                      placeholder="Months"
                      @input="
                        inputNumberOnly(
                          $event,
                          'forms',
                          'project_tenure',
                          'decimal'
                        )
                      "
                    />
                  </div>
                  <div class="col-md-auto col-6 mb-3">
                    <div class="mb-1">Annualised ROCE (%)</div>
                    <input
                      v-model.trim="forms.annualised_roce"
                      type="tel"
                      class="form-control text-start"
                      placeholder="Annualised ROCE (%)"
                      @input="
                        inputNumberOnly(
                          $event,
                          'forms',
                          'annualised_roce',
                          'decimal'
                        )
                      "
                    />
                  </div>
                  <div class="col-md-auto col-6 mb-3">
                    <div class="mb-1">
                      Collection Mode
                      <strong class="d-block">{{
                        forms.payment_mode.value
                      }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <div class="card">
            <a
              href="javascript:void(0)"
              class="text-dark"
              data-toggle="collapsed"
              v-b-toggle.accordion-2
            >
              <div class="p-3">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-20"
                      >
                        B
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-0">
                      Factoring Application
                      <span
                        class="error font-size-18"
                        v-if="$v.formData.$error"
                      >
                        <i class="uil uil-exclamation-circle"></i>
                      </span>
                    </h5>
                    <p class="text-muted text-truncate mb-0 font-size-13">
                      Facility Information, Applicant Information, Bank Account
                      Information, Contract Information, Pre-Factoring Payee,
                      Doucment Check List and Declaration
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse id="accordion-2" class="position-relative">
              <div class="position-absolute opacity-50 bg-white top-0 start-0 w-100 h-100 disabled-area" v-if="isEditPage && accessUsername !== forms.originator.username"></div>
              <div class="p-3 border-top">
                <div class="row">
                  <!-- Part A -->
                  <div class="col-12 mb-3">
                    <div
                      class="p-2 bg-soft-primary text-center fw-medium text-primary rounded"
                    >
                      Part A: Facility Information
                    </div>
                  </div>
                  <div class="col-12 mb-3">
                    <div class="table-responsive text-nowrap text-center mb-3">
                      <table
                        class="table table-bordered border-light border-light2 mb-1 mb-0 align-middle min-width-760"
                      >
                        <thead
                          class="fw-medium font-size-14 align-middle text-center"
                        >
                          <tr>
                            <th colspan="2">Pre-Factoring</th>
                            <th colspan="2">Factoring</th>
                          </tr>
                          <tr>
                            <th width="25">Type</th>
                            <th width="25">
                              Amount
                              <span class="d-block">{{ forms.currency }}</span>
                            </th>
                            <th width="25">Type</th>
                            <th width="25">
                              Amount
                              <span class="d-block">{{ forms.currency }}</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="border-light2 border-end text-start">
                              Supplier Advance
                              <div
                                v-if="
                                  forms.facility_information.supplier_advance
                                "
                                class="text-success"
                              >
                                {{
                                  convertCurrencyFormat(
                                    forms.facility_information.supplier_advance,
                                    false
                                  )
                                }}
                              </div>
                            </td>
                            <td class="border-light2 border-end">
                              <input
                                v-model.trim="
                                  forms.facility_information.supplier_advance
                                "
                                type="tel"
                                class="form-control text-end"
                                placeholder=""
                                @input="
                                  inputNumberOnlyV3(
                                    $event,
                                    'forms',
                                    'facility_information',
                                    'supplier_advance'
                                  )
                                "
                              />
                            </td>
                            <td class="border-light2 border-end text-start">
                              Invoice Advance
                              <div
                                v-if="
                                  forms.facility_information.invoice_advance
                                "
                                class="text-success"
                              >
                                {{
                                  convertCurrencyFormat(
                                    forms.facility_information.invoice_advance,
                                    false
                                  )
                                }}
                              </div>
                            </td>
                            <td class="border-light2 border-end">
                              <input
                                v-model.trim="
                                  forms.facility_information.invoice_advance
                                "
                                type="tel"
                                class="form-control text-end"
                                placeholder=""
                                @input="
                                  inputNumberOnlyV3(
                                    $event,
                                    'forms',
                                    'facility_information',
                                    'invoice_advance'
                                  )
                                "
                              />
                            </td>
                          </tr>
                          <tr>
                            <td class="border-light2 border-end text-start">
                              Cash Advance
                              <div
                                v-if="forms.facility_information.cash_advance"
                                class="text-success"
                              >
                                {{
                                  convertCurrencyFormat(
                                    forms.facility_information.cash_advance,
                                    false
                                  )
                                }}
                              </div>
                            </td>
                            <td class="border-light2 border-end">
                              <input
                                v-model.trim="
                                  forms.facility_information.cash_advance
                                "
                                type="tel"
                                class="form-control text-end"
                                placeholder=""
                                @input="
                                  inputNumberOnlyV3(
                                    $event,
                                    'forms',
                                    'facility_information',
                                    'cash_advance'
                                  )
                                "
                              />
                            </td>
                            <td class="border-light2 border-end text-start">
                              Collection Agent
                              <div
                                v-if="
                                  forms.facility_information.collection_agent
                                "
                                class="text-success"
                              >
                                {{
                                  convertCurrencyFormat(
                                    forms.facility_information.collection_agent,
                                    false
                                  )
                                }}
                              </div>
                            </td>
                            <td class="border-light2 border-end">
                              <input
                                v-model.trim="
                                  forms.facility_information.collection_agent
                                "
                                type="tel"
                                class="form-control text-end"
                                placeholder=""
                                @input="
                                  inputNumberOnlyV3(
                                    $event,
                                    'forms',
                                    'facility_information',
                                    'collection_agent'
                                  )
                                "
                              />
                            </td>
                          </tr>
                          <tr>
                            <td class="border-light2 border-end text-start">
                              Term Advance
                              <div
                                v-if="forms.facility_information.term_advance"
                                class="text-success"
                              >
                                {{
                                  convertCurrencyFormat(
                                    forms.facility_information.term_advance,
                                    false
                                  )
                                }}
                              </div>
                            </td>
                            <td class="border-light2 border-end">
                              <input
                                v-model.trim="
                                  forms.facility_information.term_advance
                                "
                                type="tel"
                                class="form-control text-end"
                                placeholder=""
                                @input="
                                  inputNumberOnlyV3(
                                    $event,
                                    'forms',
                                    'facility_information',
                                    'term_advance'
                                  )
                                "
                              />
                            </td>
                            <td class="border-light2 border-end text-start">
                              Letter of Support
                              <div
                                v-if="
                                  forms.facility_information.letter_of_support
                                "
                                class="text-success"
                              >
                                {{
                                  convertCurrencyFormat(
                                    forms.facility_information
                                      .letter_of_support,
                                    false
                                  )
                                }}
                              </div>
                            </td>
                            <td class="border-light2 border-end">
                              <input
                                v-model.trim="
                                  forms.facility_information.letter_of_support
                                "
                                type="tel"
                                class="form-control text-end"
                                placeholder=""
                                @input="
                                  inputNumberOnlyV3(
                                    $event,
                                    'forms',
                                    'facility_information',
                                    'letter_of_support'
                                  )
                                "
                              />
                            </td>
                          </tr>
                          <tr>
                            <td class="border-light2 border-end text-start">
                              Bond Advance
                              <div
                                v-if="forms.facility_information.bond_advance"
                                class="text-success"
                              >
                                {{
                                  convertCurrencyFormat(
                                    forms.facility_information.bond_advance,
                                    false
                                  )
                                }}
                              </div>
                            </td>
                            <td class="border-light2 border-end">
                              <input
                                v-model.trim="
                                  forms.facility_information.bond_advance
                                "
                                type="tel"
                                class="form-control text-end"
                                placeholder=""
                                @input="
                                  inputNumberOnlyV3(
                                    $event,
                                    'forms',
                                    'facility_information',
                                    'bond_advance'
                                  )
                                "
                              />
                            </td>
                            <td class="border-light2 border-end text-start">
                              Letter of Undertaking
                              <div
                                v-if="
                                  forms.facility_information
                                    .letter_of_undertaking
                                "
                                class="text-success"
                              >
                                {{
                                  convertCurrencyFormat(
                                    forms.facility_information
                                      .letter_of_undertaking,
                                    false
                                  )
                                }}
                              </div>
                            </td>
                            <td class="border-light2 border-end">
                              <input
                                v-model.trim="
                                  forms.facility_information
                                    .letter_of_undertaking
                                "
                                type="tel"
                                class="form-control text-end"
                                placeholder=""
                                @input="
                                  inputNumberOnlyV3(
                                    $event,
                                    'forms',
                                    'facility_information',
                                    'letter_of_undertaking'
                                  )
                                "
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col-12 mb-3">
                    <div
                      class="p-2 bg-soft-primary text-center fw-medium text-primary rounded"
                    >
                      Part B: Applicant Information
                    </div>
                  </div>
                  <div class="col-12 mb-3">
                    <form>
                      <div class="mb-3 position-relative">
                        <label>Name of Entity</label>
                        <input
                          v-model.trim="formData.company_name"
                          type="text"
                          class="form-control"
                          placeholder="Name of Entity"
                          :class="{
                            'is-invalid':
                              submitted && $v.formData.company_name.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.formData.company_name.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.formData.company_name.required"
                            >Company Name is required.</span
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-4">
                          <div class="mb-3 position-relative">
                            <label>Date of Registration</label>
                            <date-picker
                              v-model="formData.date_of_registration"
                              type="date"
                              value-type="format"
                              format="MMM DD, YYYY"
                              placeholder="Date of Registration"
                              :disabled="loading"
                              :disabled-date="notBeforeToday"
                              :editable="false"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.formData.date_of_registration.$error,
                              }"
                            ></date-picker>

                            <div
                              v-if="
                                submitted &&
                                $v.formData.date_of_registration.$error
                              "
                              class="invalid-feedback"
                            >
                              <span
                                v-if="
                                  !$v.formData.date_of_registration.required
                                "
                                >Business Registration No. is required.</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="mb-3 position-relative">
                            <label>Business Registration No.</label>
                            <input
                              v-model.trim="formData.business_registration_no"
                              type="text"
                              class="form-control"
                              placeholder="20001112222 (123456-A)"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.formData.business_registration_no.$error,
                              }"
                            />
                            <div
                              v-if="
                                submitted &&
                                $v.formData.business_registration_no.$error
                              "
                              class="invalid-feedback"
                            >
                              <span
                                v-if="
                                  !$v.formData.business_registration_no.required
                                "
                                >Business Registration No. is required.</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="mb-3 position-relative">
                            <label>Type of Entity</label>
                            <select
                              class="form-control pe-5"
                              v-model="formData.type_of_entity"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.formData.type_of_entity.$error,
                              }"
                            >
                              <option value="">Select</option>
                              <option
                                v-for="(value, index) in typeEntity"
                                :key="index"
                                :value="value"
                              >
                                {{ value.name }}
                              </option>
                            </select>

                            <div
                              v-if="
                                submitted && $v.formData.type_of_entity.$error
                              "
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.formData.type_of_entity.required"
                                >Type of Entity is required.</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="mb-3 position-relative">
                            <label>Business Address</label>
                            <input
                              v-model.trim="formData.business_address"
                              type="text"
                              class="form-control"
                              placeholder="Business Address"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.formData.business_address.$error,
                              }"
                            />
                            <div
                              v-if="
                                submitted && $v.formData.business_address.$error
                              "
                              class="invalid-feedback"
                            >
                              <span
                                v-if="!$v.formData.business_address.required"
                                >Business Address is required.</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3 position-relative">
                            <label>Telephone Number</label>
                            <input
                              v-model.trim="formData.business_tel"
                              type="text"
                              class="form-control"
                              placeholder="Telephone Number"
                              @input="
                                inputNumberOnly(
                                  $event,
                                  'formData',
                                  'business_tel',
                                  'normal'
                                )
                              "
                              :class="{
                                'is-invalid':
                                  submitted && $v.formData.business_tel.$error,
                              }"
                            />
                            <div
                              v-if="
                                submitted && $v.formData.business_tel.$error
                              "
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.formData.business_tel.required"
                                >Telephone Number is required.</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3 position-relative">
                            <label>E-Mail Address</label>
                            <input
                              v-model.trim="formData.business_email"
                              type="text"
                              class="form-control"
                              placeholder="E-Mail Address"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.formData.business_email.$error,
                              }"
                            />
                            <div
                              v-if="
                                submitted && $v.formData.business_email.$error
                              "
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.formData.business_email.required"
                                >E-mail address is required.</span
                              >
                              <span v-if="!$v.formData.business_email.email"
                                >E-mail address should be a valid email.</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3 position-relative">
                            <label>Contact Person</label>
                            <input
                              v-model.trim="formData.contact_person"
                              type="text"
                              class="form-control"
                              placeholder="Contact Person"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.formData.contact_person.$error,
                              }"
                              @blur="updateAuthorisedSignatoryUser()"
                            />
                            <div
                              v-if="
                                submitted && $v.formData.contact_person.$error
                              "
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.formData.contact_person.required"
                                >Contact Person is required.</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3 position-relative">
                            <label>Position</label>
                            <input
                              v-model.trim="formData.position"
                              type="text"
                              class="form-control"
                              placeholder="Position"
                              :class="{
                                'is-invalid':
                                  submitted && $v.formData.position.$error,
                              }"
                            />
                            <div
                              v-if="submitted && $v.formData.position.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.formData.position.required"
                                >Position is required.</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3 position-relative">
                            <label>Contact Person Phone No.</label>
                            <input
                              v-model.trim="formData.contact_tel"
                              type="text"
                              class="form-control"
                              placeholder="Contact Person Tel No."
                              @input="
                                inputNumberOnly(
                                  $event,
                                  'formData',
                                  'contact_tel',
                                  'normal'
                                )
                              "
                              :class="{
                                'is-invalid':
                                  submitted && $v.formData.contact_tel.$error,
                              }"
                            />
                            <div
                              v-if="submitted && $v.formData.contact_tel.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.formData.contact_tel.required"
                                >Contact Person Phone No. is required.</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3 position-relative">
                            <label>Contact Person E-Mail Address</label>
                            <input
                              v-model.trim="formData.contact_email"
                              type="text"
                              class="form-control"
                              placeholder="Contact Person E-Mail Address"
                              :class="{
                                'is-invalid':
                                  submitted && $v.formData.contact_email.$error,
                              }"
                            />
                            <div
                              v-if="
                                submitted && $v.formData.contact_email.$error
                              "
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.formData.contact_email.required"
                                >Contact Person E-Mail Address is
                                required.</span
                              >
                              <span v-if="!$v.formData.contact_email.email"
                                >Contact Person E-Mail Address should be a valid
                                email.</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="col-12 mb-3">
                    <div
                      class="p-2 bg-soft-primary text-center fw-medium text-primary rounded"
                    >
                      Part C: Bank Account Information
                    </div>
                  </div>
                  <div class="col-12 mb-3">
                    <div class="table-responsive text-nowrap text-center mb-3">
                      <table
                        class="table table-bordered border-light2 mb-1 mb-0 align-middle min-width-760"
                      >
                        <thead
                          class="fw-medium font-size-14 align-middle text-center"
                        >
                          <tr>
                            <th>Bank</th>
                            <th>Account Number</th>
                            <th>Facility</th>
                            <th>Limit ({{ forms.currency }})</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(value, index) in formData.banks"
                            :key="index"
                          >
                            <td class="border-light2 border-end text-start">
                              <span v-if="value.bank_name"
                                >{{ value.bank_name || "-" }},
                              </span>
                              {{ value.branch || "-" }}
                            </td>
                            <td class="border-light2 border-end text-start">
                              {{ value.account_number || "-" }}
                            </td>
                            <td class="border-light2 border-end text-start">
                              {{ value.facility || "-" }}
                            </td>
                            <td class="text-center">
                              <span v-if="value.limit">
                              {{ convertCurrencyFormat(value.limit, false ) }}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col-12 mb-3">
                    <div
                      class="p-2 bg-soft-primary text-center fw-medium text-primary rounded"
                    >
                      Part D: Contract Information
                    </div>
                  </div>
                  <div class="col-12 mb-3">
                    <div class="table-responsive text-nowrap text-center mb-3">
                      <table
                        class="table table-borderless border border-light2 mb-1 mb-0 align-middle min-width-760"
                      >
                        <thead
                          class="fw-medium font-size-14 align-middle text-center"
                        >
                          <tr>
                            <th class="border-end border-light2">
                              Contract Title
                            </th>
                            <th class="border-end border-light2">Awarder</th>
                            <th class="border-end border-light2">
                              Nature Of Contract
                            </th>
                            <th class="border-end border-light2">Amount</th>
                            <th>ePerolehan</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              class="text-start border-end border-light2"
                              width="45%"
                            >
                              <div
                                v-html="forms.contract_title"
                                class="text-wrap"
                              ></div>
                            </td>
                            <td class="text-start border-end border-light2">
                              <div
                                v-html="forms.contract_awarder"
                                class="text-wrap"
                              ></div>
                            </td>
                            <td class="text-center border-end border-light2">
                              {{ forms.nature_of_contract.label }}
                            </td>
                            <td class="text-center border-end border-light2">
                              {{ forms.currency }}
                              <div v-if="forms.contract_amount">
                                {{
                                  convertCurrencyFormat(
                                    forms.contract_amount,
                                    false
                                  )
                                }}
                              </div>
                            </td>
                            <td class="text-start">
                              <div class="d-flex w-100 text-center">
                                <div class="w-100">Yes</div>
                                <div class="w-100">No</div>
                              </div>
                              <div class="d-flex text-center">
                                <div class="w-100 font-size-16">
                                  <b-form-checkbox
                                    disabled
                                    v-model="forms.payment_mode"
                                    name="payment_mode"
                                    :value="payment_mode[0]"
                                    :unchecked-value="payment_mode[1]"
                                  >
                                  </b-form-checkbox>
                                </div>
                                <div class="w-100 font-size-16">
                                  <b-form-checkbox
                                    disabled
                                    v-model="forms.payment_mode"
                                    name="payment_mode"
                                    :value="payment_mode[1]"
                                    :unchecked-value="payment_mode[0]"
                                  >
                                  </b-form-checkbox>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr class="d-none">
                            <td
                              class="border-top-0 border-end border-light2"
                            ></td>
                            <td
                              class="border-top-0 border-end border-light2"
                            ></td>
                            <td
                              class="border-top-0 border-end border-light2"
                            ></td>
                            <td class="text-start border-top-0">
                              <div class="d-flex text-center">
                                <div class="w-100 font-size-16">
                                  <b-form-checkbox
                                    disabled
                                    v-model="forms.payment_mode"
                                    name="payment_mode"
                                  >
                                  </b-form-checkbox>
                                </div>
                                <div class="w-100 font-size-16">
                                  <b-form-checkbox
                                    disabled
                                    v-model="forms.payment_mode"
                                    name="payment_mode"
                                  >
                                  </b-form-checkbox>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col-12 mb-3">
                    <div
                      class="p-2 bg-soft-primary text-center fw-medium text-primary rounded"
                    >
                      Part E: Pre-Factoring Payee
                    </div>
                  </div>
                  <div class="table-responsive text-nowrap text-center mb-3">
                    <table
                      class="table table-borderless border border-light2 mb-0 align-middle min-width-760"
                    >
                      <thead
                        class="fw-medium font-size-14 align-middle text-center"
                      >
                        <tr>
                          <th class="border-end border-light2 min-width-200">Payee Company</th>
                          <th class="border-end border-light2 min-width-150">Location</th>
                          <th class="border-end border-light2 min-width-200">Purpose</th>
                          <th class="border-end border-light2 min-width-100">Amount</th>
                          <th class="border-end border-light2 min-width-150">Currency</th>
                          <th class="border-end border-light2 min-width-100">Rate</th>
                          <th class="min-width-150">Total ({{ forms.currency }})</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(value, index) in forms.pre_factoring_payee"
                          :key="index"
                        >
                          <td class="text-start border-end border-light2">
                            <textarea
                              class="form-control"
                              rows="3"
                              type="text"
                              v-model.trim="value.payee_company"
                              :placeholder="`Payee Company (${index+1})`"
                            ></textarea>
                          </td>
                          <td class="text-start border-end border-light2">
                            <textarea
                              class="form-control"
                              rows="3"
                              type="text"
                              v-model.trim="value.location"
                              placeholder="Location"
                            ></textarea>
                          </td>
                          <td class="text-center border-end border-light2" >
                            <textarea
                              class="form-control"
                              rows="3"
                              type="text"
                              v-model.trim="value.purpose"
                              placeholder="Purpose"
                            ></textarea>
                          </td>
                          <td class="text-start border-end border-light2">
                            <input
                              v-model.trim="value.amount"
                              type="tel"
                              class="form-control text-end"
                              placeholder="Amount"
                              @input="
                                inputNumberOnlyV4(
                                  $event,
                                  'forms',
                                  'pre_factoring_payee',
                                  'amount',
                                  index
                                )
                              "
                              @blur="payeeAmountnRateInputChange()"
                            />
                          </td>
                          <td
                            class="text-start border-end border-light2"
                            width="120"
                          >
                            <select
                              class="form-select pe-5"
                              v-model="value.currency"
                              :disabled="loading"
                              @change="payeeRatesChange(index)"
                            >
                              <option
                                :value="value.currency"
                                v-for="(value, index) in currencyRates"
                                :key="index"
                              >
                                {{ value.currency }}
                              </option>
                            </select>
                          </td>
                          <td
                            class="text-center border-end border-light2"
                            width="120"
                          >
                            <input
                              v-model.trim="value.rate"
                              type="tel"
                              class="form-control text-end"
                              placeholder="Rate"
                              @input="
                                inputNumberOnlyV6(
                                  $event,
                                  'forms',
                                  'pre_factoring_payee',
                                  'rate',
                                  index,
                                  'decimal'
                                )
                              "
                              @blur="payeeAmountnRateInputChange()"
                            />
                          </td>
                          <td class="text-end border-end border-light2">
                            <div v-if="value.total">
                              {{ convertCurrencyFormat(value.total || "0") }}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="text-start border-end border-light2 align-top"
                            colspan="6"
                          >
                            <div class="font-size-12 text-muted">
                              <i class="uil uil-exclamation-circle"></i> If a
                              figure is not entered into the 'amount' field, the
                              credit paper will not appear in the row.
                            </div>
                          </td>
                          <td class="text-end">
                            <span v-if="forms.pre_factoring_payee.length > 1">
                              <div class="text-success fw-emdium mb-2">  {{ convertCurrencyFormat(forms.pre_factoring_payee_total_amount || "0")}}</div>

                              <input
                              v-model.trim="forms.pre_factoring_payee_total_amount"
                              type="tel"
                              class="form-control text-end"
                              placeholder="Total"
                              @input="
                                inputNumberOnly(
                                  $event,
                                  'forms',
                                  'pre_factoring_payee_total_amount',
                                  'decimal'
                                )
                              "
                            />
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="border-top-0 border-end border-light2"
                            colspan="6"
                          ></td>
                          <td class="text-start border-top-0"></td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="text-end mt-3" v-if="create_modify_permission">
                    <button
                      type="button"
                      class="btn btn-primary btn-soft-primary waves-effect waves-light border-0 me-2"
                      @click="forms.pre_factoring_payee.pop(), payeeAmountnRateInputChange()"
                      :disabled="forms.pre_factoring_payee.length < 2"
                      v-if="forms.pre_factoring_payee.length > 1"
                    >
                      <i class="uil uil-minus-circle"></i>
                      Remove
                    </button>
                    
                    <button
                      type="button"
                      class="btn btn-primary btn-soft-primary waves-effect waves-light border-0"
                      @click="
                        forms.pre_factoring_payee.push({
                        payee_company: '',
                        location: '',
                        purpose: '',
                        amount: 0,
                        currency: 'MYR',
                        rate: '1',
                        total: 0
                      }),
                      payeeAmountnRateInputChange()"
                      :disabled="forms.pre_factoring_payee.length >= 22"
                      v-if="forms.pre_factoring_payee.length<22"
                    >
                      <i class="uil uil-plus-circle"></i> Add Payee Company
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary btn-soft-primary waves-effect waves-light border-0"
                      disabled="true"
                      v-else
                    >
                      <i class="uil uil-exclamation-circle"></i>  Payee Company (Max. 22)
                    </button>
                    
                  </div>
                    
                  </div>
                  <div class="col-12 mb-3">
                    <div
                      class="p-2 bg-soft-primary text-center fw-medium text-primary rounded"
                    >
                      Part F: Required Documents Checklist
                    </div>
                  </div>
                  <div class="col-12">
                    <!-- <pre class="bg-light p-3 rounded" style="height:300px">{{formData.supporting_docs}}</pre> -->
                    <div class="table-responsive text-nowrap mb-3">
                      <div class="min-width-760 p-2">
                        <div class="d-flex">
                          <div class="col-8 col-md-8 mb-3"></div>
                          <div class="col-2 col-md-2 mb-3">
                            <div class="d-flex text-center">
                              <div class="w-100 font-size-14">Yes</div>
                              <div class="w-100 font-size-14">No</div>
                            </div>
                          </div>
                          <div class="col-2 col-md-2 mb-3">Remarks</div>
                        </div>
                        <div
                          class="d-flex align-items-center"
                          v-for="(
                            value, index
                          ) in formData.supporting_docs.slice(0, -2)"
                          :key="index"
                        >
                          <div
                            class="col-8 col-md-8 font-size-14"
                            :class="{
                              'mb-3':
                                index !== formData.supporting_docs.length - 1,
                            }"
                          >
                            {{ index + 1 }}. {{ value.name }}
                            <a
                              :href="value.files"
                              v-if="value.files"
                              target="_blank"
                              class="badge rounded-pill bg-primary text-white mb-1"
                              >View Docs.</a
                            >
                          </div>
                          <div
                            class="col-2 col-md-2"
                            :class="{
                              'mb-3':
                                index !== formData.supporting_docs.length - 1,
                            }"
                          >
                            <div class="d-flex text-center">
                              <div class="w-100 font-size-16">
                                <b-form-checkbox
                                  v-model="value.checkYesNo"
                                  name="status"
                                  :value="'checked'"
                                  :unchecked-value="''"
                                  @change="updateRemarkBasedOnStatus(value)"
                                >
                                </b-form-checkbox>
                              </div>
                              <div class="w-100 font-size-16">
                                <b-form-checkbox
                                  v-model="value.checkYesNo"
                                  name="status"
                                  :value="'unchecked'"
                                  :unchecked-value="''"
                                  @change="updateRemarkBasedOnStatus(value)"
                                >
                                </b-form-checkbox>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-2 col-md-2"
                            :class="{
                              'mb-3':
                                index !== formData.supporting_docs.length - 1,
                            }"
                          >
                            <div v-if="value.id !== 8 && value.id !== 9">
                              <span v-if="value.checkYesNo == ''">Pending</span>
                              <span v-if="value.checkYesNo == 'checked'">{{
                                value.remark
                              }}</span>
                              <span v-if="value.checkYesNo == 'unchecked'">
                               <select v-model="value.remark"  class="form-select w-auto pe-5">
                                <option value="">Remain Blank</option>
                                <option value="Not Applicable">Not Applicable</option>
                                <option value="Pending">Pending</option>
                                <option value="Waived">Waived</option>
                                <option value="Recurring">Recurring</option>
                                <option value="Proprietorship">Proprietorship</option>
                               </select>
                                </span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mb-3">
                    <div
                      class="p-2 bg-soft-primary text-center fw-medium text-primary rounded"
                    >
                      Part G: Declaration
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="p-2">
                      <ol class="list-group ps-3">
                        <li class="mb-3">
                          All information furnished above and as contained in
                          the attached documents requested are true and
                          accurate.
                        </li>
                        <li class="mb-3">
                          I/We hereby authorise Ikhlas Al Dain Sdn Bhd to verify
                          any information pertaining to our company, its
                          directors, and its shareholders, from any source
                          deemed appropriate for the purpose of assessing this
                          application.
                        </li>
                        <li class="mb-3">
                          I/We understand that Ikhlas Al Dain Sdn Bhd reserves
                          the right at its sole absolute discretion to decline
                          this application and without providing the reason/s
                          for it.
                        </li>
                      </ol>
                      <div
                        class="table-responsive text-nowrap text-center mb-3"
                      >
                        <div class="d-flex w-100 partC">
                          <div>
                            <div
                              class="border-bottom border-dark"
                              style="height: 200px; min-width: 300px"
                            ></div>
                            <div class="px-0 text-start pt-1">
                              <div class="form-floating">
                                <select
                                  class="form-select"
                                  id="name_of_authorised_signatory"
                                  aria-label=""
                                  v-model="forms.name_of_authorised_signatory"
                                  :disabled="loading"
                                >
                                  <option
                                    :value="value.name"
                                    v-for="(value, index) in directorsAndContractPerson"
                                    :key="index"
                                  >
                                    {{ value.name }}
                                  </option>
                                </select>
                                <label for="name_of_authorised_signatory"
                                  >Name</label
                                >
                              </div>
                            </div>
                          </div>
                          <div class="mx-3">
                            <div class="d-flex w-100" style="height: 200px">
                              <span class="mt-auto">Authorised Signatory</span>
                            </div>
                            <div class="py-1">
                              <div class="d-flex w-100">
                                <span class="me-1 d-none">Date:</span>
                                <date-picker
                                  v-model="forms.date_of_authorised_signatory"
                                  type="date"
                                  value-type="format"
                                  format="MMM DD, YYYY"
                                  placeholder="Date "
                                  :disabled="loading"
                                  :disabled-date="notBeforeToday"
                                  :editable="false"
                                ></date-picker>
                              </div>
                            </div>
                          </div>
                          <div class="ms-auto">
                            <div
                              class="border border-dark"
                              style="height: 200px; width: 280px"
                            ></div>
                            <div class="text-center mt-2">
                              Affix Company Stamp
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>

          <div class="card">
            <a
              href="javascript:void(0)"
              class="text-dark"
              v-b-toggle.accordion-4
            >
              <div class="p-3">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-20"
                      >
                        C
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-0">Facility Term Sheet</h5>
                    <p class="text-muted text-truncate mb-0 font-size-13">
                      Factoring and Pre-Factoring
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse id="accordion-4" class="position-relative">
              <div class="position-absolute opacity-50 bg-white top-0 start-0 w-100 h-100 disabled-area" v-if="isEditPage && accessUsername !== forms.originator.username"></div>
              <div class="p-3 border-top">
                <div class="row">
                  <div class="col-12 mb-3">
                    <div
                      class="p-2 bg-soft-primary text-center fw-medium text-primary rounded"
                    >
                      Facility Term Sheet
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="table-responsive mb-3">
                      <table class="table min-width-760">
                        <thead>
                          <tr>
                            <th width="250px">Facility:</th>
                            <th>Factoring</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Applicant:</td>
                            <td>
                              <div v-if="forms.applicant">
                                {{ forms.applicant.company_name || "" }}
                              </div>
                              <div v-else>-</div>
                            </td>
                          </tr>
                          <tr>
                            <td class="align-middle">Purpose:</td>
                            <td>
                              <div v-if="forms.facility_term_sheet">
                                {{
                                  forms.facility_term_sheet.factoring.purpose
                                }}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Limit:</td>
                            <td>
                              <div v-if="forms.facility_term_sheet">
                                {{ forms.currency }}
                                {{
                                  convertCurrencyFormat(
                                    forms.facility_term_sheet.factoring.limit ||
                                      "0"
                                  )
                                }}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Administration Fee:</td>
                            <td>
                              {{ forms.facility_term_sheet.factoring.administration_fee || "-"  }}% <span v-if="forms.nature_of_contract.value=='milestone_staggered'">flat</span> of Gross Invoice Value, charged on a per
                              invoice basis.
                            </td>
                          </tr>
                          <tr>
                            <td>Profit Rate:</td>
                            <td>
                              {{  forms.facility_term_sheet.factoring.profit_rate || "-"}}% per month of Advanced amount, calculated on a
                              per day basis.
                            </td>
                          </tr>
                          <tr>
                            <td>Rollover Fee:</td>
                            <td>
                              {{ forms.facility_term_sheet.factoring.rollover_fee || "-" }}% per month of Gross Invoice Value, calculated
                              on a per day basis and charged upon expiry of
                              Credit Period.
                            </td>
                          </tr>
                          <tr>
                            <td>Margin of Advance:</td>
                            <td>
                              Up to
                              {{
                                forms.facility_term_sheet.factoring
                                  .margin_of_advance || "-"
                              }}% of Gross Invoice Value.
                            </td>
                          </tr>
                          <tr>
                            <td>Credit Period:</td>
                            <td>
                              {{
                                forms.facility_term_sheet.factoring
                                  .credit_period
                              }}
                              days from date of invoice.
                            </td>
                          </tr>
                          <tr>
                            <td>Recourse Period:</td>
                            <td>
                              {{
                                forms.facility_term_sheet.factoring
                                  .recourse_period
                              }}
                              days upon expiry of Credit Period.
                            </td>
                          </tr>
                          <tr>
                            <td>Security and Credit Support:</td>
                            <td>
                              <ul class="p-0" >
                                <li class="list-group-item mb-3" v-for="(value, index) in  forms.security_and_credit_support.factoring" :key="index">
                                  <div class="d-flex align-items-center">
                                  <span class="me-2">{{ index+1 }}. </span>
                                  <input type="text" class="form-control d-flex" v-model.trim="forms.security_and_credit_support.factoring[index]">
                                </div>
                                </li>
                              </ul>
                            </td>
                          </tr>
                          <tr>
                            <td>Processing Fees:</td>
                            <td>
                              <div
                                v-if="forms.facility_term_sheet"
                                class="mb-3"
                              >
                                {{ forms.currency }}
                                {{
                                  convertCurrencyFormat(
                                    forms.facility_term_sheet.factoring
                                      .processing_fees || "0"
                                  )
                                }}
                              </div>
                              The fees do not include stamp duty to be charged
                              under the Stamp Act, 1949.
                            </td>
                          </tr>
                          <tr class="d-none">
                            <td>Letter of Undertaking Fees:</td>
                            <td>
                              <table class="w-100">
                                <tr>
                                  <td
                                    class="border border-dark bg-light p-2 py-1 fw-medium"
                                    width="50%"
                                  >
                                    Amount of Undertaking<span
                                      class="d-block"
                                      >{{ forms.currency }}</span
                                    >
                                  </td>
                                  <td
                                    class="border border-dark border-start-0 bg-light p-2 py-1 fw-medium"
                                    width="50%"
                                  >
                                    Fee<span class="d-block">%</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="px-2 pt-2">Up to 250,000</td>
                                  <td class="px-2 pt-2">
                                    0.5% of Amount of Undertaking
                                  </td>
                                </tr>
                                <tr>
                                  <td class="px-2">Above 250,000 to 500,000</td>
                                  <td class="px-2">
                                    0.75% of Amount Undertaking
                                  </td>
                                </tr>
                                <tr>
                                  <td class="px-2">
                                    Above 500,000 to below 1.0 million
                                  </td>
                                  <td class="px-2">
                                    1% of Amount of Undertaking
                                  </td>
                                </tr>
                                <tr>
                                  <td class="px-2">1.0 million and above</td>
                                  <td class="px-2">
                                    1.25% of Amount of Undertaking
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          <tr class="d-none">
                            <td>Support Letter Fees:</td>
                            <td>
                              <table class="w-100">
                                <tr>
                                  <td
                                    class="border border-dark bg-light p-2 py-1 fw-medium"
                                    width="50%"
                                  >
                                    Amount Supported<span class="d-block">{{
                                      forms.currency
                                    }}</span>
                                  </td>
                                  <td
                                    width="50%"
                                    class="border border-dark border-start-0 bg-light p-2 py-1 fw-medium"
                                  >
                                    Fee<span class="d-block">%</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="px-2 pt-2">Up to 500,000</td>
                                  <td class="px-2 pt-2">50 per Letter</td>
                                </tr>
                                <tr>
                                  <td class="px-2">
                                    Above 500,000 to below 1.0 million
                                  </td>
                                  <td class="px-2">75 per Letter</td>
                                </tr>
                                <tr>
                                  <td class="px-2">1.0 million</td>
                                  <td class="px-2">100 per Letter</td>
                                </tr>
                                <tr>
                                  <td class="px-2">
                                    Above 1.0 million to below 2.0 million
                                  </td>
                                  <td class="px-2">200 per Letter</td>
                                </tr>
                                <tr>
                                  <td class="px-2">2.0 million and above</td>
                                  <td class="px-2">250 per Letter</td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div v-show="forms.facility_term_sheet.pre_factoring.enabled=='true'">
                    <div class="col-12 mb-3">
                      <div
                        class="p-2 bg-soft-primary text-center fw-medium text-primary rounded"
                      >
                        Facility Term Sheet
                      </div>
                    </div>
                    <div
                      class="col-12"
                      v-if="this.forms.nature_of_contract.value == 'recurring'"
                    >
                      <div class="table-responsive mb-3">
                        <table class="table min-width-760">
                          <thead>
                            <tr>
                              <th width="250px">Facility:</th>
                              <th>Pre-Factoring</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Applicant:</td>
                              <td>
                                <div v-if="forms.applicant">
                                  {{ forms.applicant.company_name }}
                                </div>
                                <div v-else>-</div>
                              </td>
                            </tr>
                            <tr>
                              <td class="align-middle">Purpose:</td>
                              <td>
                                <div v-if="forms.facility_term_sheet">
                                  {{
                                    forms.facility_term_sheet.pre_factoring
                                      .purpose
                                  }}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Limit:</td>
                              <td>
                                <div v-if="forms.facility_term_sheet">
                                  {{ forms.currency }}
                                  {{
                                    convertCurrencyFormat(
                                      forms.facility_term_sheet.pre_factoring
                                        .limit || "0"
                                    )
                                  }}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Profit Rate:</td>
                              <td>
                                {{
                                  forms.facility_term_sheet.pre_factoring
                                    .profit_rate || "-"
                                }}% flat per month of Advanced amount
                              </td>
                            </tr>
                            <tr>
                              <td>Rollover Fee:</td>
                              <td>
                                {{
                                  forms.facility_term_sheet.pre_factoring
                                    .rollover_fee || "-"
                                }}% per month of Advanced amount, charged upon
                                expiry of each monthly Instalment Due Date and
                                calculated on a per day basis.
                              </td>
                            </tr>
                            <tr>
                              <td>Margin of Advance:</td>
                              <td>
                                Up to
                                {{
                                  forms.facility_term_sheet.pre_factoring
                                    .margin_of_advance || "-"
                                }}% of working capital requirement
                              </td>
                            </tr>
                            <tr>
                              <td>Tenure:</td>
                              <td>
                                {{
                                  forms.facility_term_sheet.pre_factoring
                                    .tenure || "-"
                                }}
                                months
                              </td>
                            </tr>
                            <tr>
                              <td>Repayments:</td>
                              <td>
                                Through
                                {{
                                  forms.facility_term_sheet.pre_factoring
                                    .tenure || "-"
                                }}
                                fixed and equal monthly instalments of
                                {{ forms.currency }}
                                {{
                                  convertCurrencyFormat(
                                    forms.facility_term_sheet.pre_factoring
                                      .repayments || "0"
                                  )
                                }}
                                each either by setoffs against factored contract
                                proceeds or repayments by the Applicant.
                              </td>
                            </tr>
                            <tr>
                              <td>Instalment Due Dates:</td>
                              <td>
                                {{
                                  forms.facility_term_sheet.pre_factoring
                                    .installment_due_dates.label || "-"
                                }}
                                ({{
                                  forms.facility_term_sheet.pre_factoring
                                    .installment_due_dates.value || "-"
                                }}) month from date of Advance.
                              </td>
                            </tr>
                            <tr>
                              <td>Security and Credit Support:</td>
                              <td>
                                <ul class="p-0" >
                                  <li class="list-group-item mb-3" v-for="(value, index) in  forms.security_and_credit_support.pre_factoring" :key="index">
                                    <div class="d-flex align-items-center">
                                    <span class="me-2">{{ index+1 }}. </span>
                                    <input type="text" class="form-control d-flex" v-model.trim="forms.security_and_credit_support.pre_factoring[index]">
                                  </div>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                            <tr>
                              <td>Processing Fees:</td>
                              <td>
                                <div
                                  v-if="forms.facility_term_sheet"
                                  class="mb-3"
                                >
                                  {{ forms.currency }}
                                  {{
                                    convertCurrencyFormat(
                                      forms.facility_term_sheet.pre_factoring
                                        .processing_fees || "0"
                                    )
                                  }}
                                </div>
                                The fees do not include stamp duty to be charged
                                under the Stamp Act, 1949.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="col-12" v-else>
                      <div class="table-responsive mb-3">
                        <table class="table min-width-760">
                          <thead>
                            <tr>
                              <th width="250px">Facility:</th>
                              <th>Pre-Factoring</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Applicant:</td>
                              <td>
                                <div v-if="forms.applicant">
                                  {{ forms.applicant.company_name }}
                                </div>
                                <div v-else>-</div>
                              </td>
                            </tr>
                            <tr>
                              <td class="align-middle">Purpose:</td>
                              <td>
                                <div v-if="forms.facility_term_sheet">
                                  {{
                                    forms.facility_term_sheet.pre_factoring
                                      .purpose
                                  }}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Limit:</td>
                              <td>
                                <div v-if="forms.facility_term_sheet">
                                  {{ forms.currency }}
                                  {{
                                    convertCurrencyFormat(
                                      forms.facility_term_sheet.pre_factoring
                                        .limit || "0"
                                    )
                                  }}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Profit Rate:</td>
                              <td>
                                <div class="row" v-if="forms.nature_of_contract.value == 'one_off'">
                                  <div class="col-6 fw-medium mb-2">Purpose</div>
                                  <div class="col-6 fw-medium mb-2">Rate</div>
                                  <div class="col-6 mb-2">
                                    Supplier Advance/Bond Advance
                                  </div>
                                  <div class="col-6 mb-2">
                                    {{
                                      forms.facility_term_sheet.pre_factoring
                                        .supplier_bond_advance_rate
                                    }}% per month of Advanced amount, calculated
                                    on a daily basis.
                                  </div>
                                  <div class="col-6 mb-2">Cash Advance</div>
                                  <div class="col-6 mb-2">
                                    {{
                                      forms.facility_term_sheet.pre_factoring
                                        .cash_advance_rate
                                    }}% per month of Advanced amount, calculated
                                    on a daily basis.
                                  </div>
                                </div>
                                <div class="row" v-else>
                                  <div class="col-6 fw-medium mb-2">Supplier Advance / Bond Advance</div>
                                  <div class="col-6 fw-medium mb-2">Cash Advance</div>
                                  <div class="col-6 mb-2">
                                    {{ forms.facility_term_sheet.pre_factoring.supplier_bond_advance_rate }}% per month of Advanced amount,calculated on a daily basis.
                                  </div>
                                  <div class="col-6 mb-2">
                                    {{ forms.facility_term_sheet.pre_factoring.cash_advance_rate}}% per month of Advanced amount, calculated on a daily basis.
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Rollover Fee:</td>
                              <td>
                                {{
                                  forms.facility_term_sheet.pre_factoring
                                    .rollover_fee || "-"
                                }}% per month of the balance outstanding,
                                calculated on a per day basis and charged upon
                                expiry of Credit Period.
                              </td>
                            </tr>
                            <tr>
                              <td>Margin of Advance:</td>
                              <td>
                                Up to
                                {{
                                  forms.facility_term_sheet.pre_factoring
                                    .margin_of_advance || "-"
                                }}% of purchase price or incurred cost.
                              </td>
                            </tr>
                            <tr>
                              <td>Credit Period:</td>
                              <td>
                                {{
                                  forms.facility_term_sheet.pre_factoring
                                    .credit_period
                                }}
                                days from date of Advance.
                              </td>
                            </tr>
                            <tr>
                              <td>Recourse Period:</td>
                              <td>
                                {{
                                  forms.facility_term_sheet.pre_factoring
                                    .recourse_period
                                }}
                                days upon expiry of Credit Period.
                              </td>
                            </tr>
                            <!-- <tr>
                            <td>Tenure:</td>
                            <td>{{forms.facility_term_sheet.pre_factoring.tenure.label || '-'}} ({{forms.facility_term_sheet.pre_factoring.tenure.value || '-'}}) months
                            </td>
                          </tr>
                          <tr>
                            <td>Repayments:</td>
                            <td>Through <span class="text-lowercase">{{forms.facility_term_sheet.pre_factoring.tenure.label || '-'}}</span> ({{forms.facility_term_sheet.pre_factoring.tenure.value || '-'}}) fixed and equal monthly instalments of {{forms.currency}} {{convertCurrencyFormat(forms.facility_term_sheet.pre_factoring.repayments || '0') }}  each either by setoffs against factored contract proceeds or repayments by the Applicant.  

                            </td>
                          </tr> 
                          <tr>
                            <td>Instalment Due Dates:</td>
                            <td>{{forms.facility_term_sheet.pre_factoring.installment_due_dates.label || '-'}} ({{forms.facility_term_sheet.pre_factoring.installment_due_dates.value || '-'}}) month from date of Advance.


                            </td>
                          </tr>-->
                            <tr>
                              <td>Security and Credit Support:</td>
                              <td>
                                <ul class="p-0" >
                                  <li class="list-group-item mb-3" v-for="(value, index) in forms.security_and_credit_support.pre_factoring" :key="index">
                                    <div class="d-flex align-items-center">
                                    <span class="me-2">{{ index+1 }}. </span>
                                    <input type="text" class="form-control d-flex" v-model.trim="forms.security_and_credit_support.pre_factoring[index]">
                                  </div>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                            <tr>
                              <td>Processing Fees:</td>
                              <td>
                                <div
                                  v-if="forms.facility_term_sheet"
                                  class="mb-3"
                                >
                                  {{ forms.currency }}
                                  {{
                                    convertCurrencyFormat(
                                      forms.facility_term_sheet.pre_factoring
                                        .processing_fees || "0"
                                    )
                                  }}
                                </div>
                                The fees do not include stamp duty to be charged
                                under the Stamp Act, 1949.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>

          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark"
              data-toggle="collapse"
              aria-expanded="true"
              aria-controls=""
              v-b-toggle.accordion-5
            >
              <div class="p-3">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-20"
                      >
                        D
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-0">Risk Analysis Report</h5>
                    <p class="text-muted text-truncate mb-0 font-size-13">
                      Delivery Risk, Collection Risk, Exposure Risk
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse id="accordion-5" class="position-relative">
              <div class="position-absolute opacity-50 bg-white top-0 start-0 w-100 h-100 disabled-area" v-if="isEditPage && accessUsername !== forms.originator.username"></div>
              <div class="p-3 border-top">
                <div class="row">
                  <div class="col-12"></div>
                </div>
                <div class="row mb-3">
                  <div class="col-4">
                    <strong class="fw-medium">Identified Risks</strong>
                  </div>
                  <div class="col-12 col-lg-8">
                    <strong class="fw-medium">Risk Mitigation</strong>
                  </div>
                </div>
                <div
                  class="row mb-3"
                  v-for="(value, index) in forms.risk_analysis_report"
                  :key="index"
                >
                  <div class="col-4 mb-lg-0 mb-2">
                    {{ index + 1 }}. {{ value.type_of_identified_risks }}
                  </div>
                  <div class="col-12 col-lg-8">
                    <div
                      class="d-flex align-items-center mb-lg-3 mb-2"
                      v-for="(v, i) in value.risk_mitigation_list"
                      :key="i"
                    >
                      <div class="me-2">
                        <b-form-checkbox
                          :id="index + 'w' + i"
                          v-model="v.yesNo"
                          name="checkbox"
                          value="yes"
                          unchecked-value="no"
                        >
                        </b-form-checkbox>
                      </div>
                      <div class="w-100">
                        <input :for="index + 'w' + i" type="text" class="form-control" v-model.trim="v.title">
                        <!-- <label
                          class="fw-normal cursor-pointer mb-0"
                          :for="index + 'w' + i"
                          >{{ v.title }}</label
                        > -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>

          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark"
              data-toggle="collapse"
              aria-expanded="true"
              aria-controls=""
              v-b-toggle.accordion-6
            >
              <div class="p-3">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-20"
                      >
                        E
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-0">
                      Justifications And Recommendations
                    </h5>
                    <p class="text-muted text-truncate mb-0 font-size-13">
                      Justifications, Recommendations, Sign-Off and Recommender
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse id="accordion-6" class="position-relative">
              <div class="position-absolute opacity-50 bg-white top-0 start-0 w-100 h-100 disabled-area" v-if="isEditPage && accessUsername !== forms.originator.username"></div>
              <div class="p-3 border-top">
                <div class="row mb-3">
                  <div class="col-12">
                    <div
                      class="p-2 bg-soft-primary text-center fw-medium text-primary rounded"
                    >
                      Justifications
                    </div>
                  </div>
                </div>
                <div
                  class="row mb-lg-3 mb-2 align-items-center"
                  v-for="(value, index) in forms
                    .justifications_and_recommendations.justifications"
                  :key="index"
                >
                  <div class="col-auto">
                    <span v-if="index < 5">{{ index + 1 }}. </span>
                  </div>
                  <div class="col">
                    <div
                      class="d-flex align-items-center mb-1"
                      v-if="index < 5"
                    >
                      <div class="w-100 me-3">
                        <!-- <label
                          class="fw-normal cursor-pointer mb-0 lh-1"
                          :for="index + 'Justifications'"
                          >{{ value.title }}</label
                        > -->
                        <input :for="index + 'Justifications'" type="text" class="form-control" v-model.trim="value.title">
                      </div>
                      <div class="ms-auto ms-2">
                        <b-form-checkbox
                          :id="index + 'Justifications'"
                          v-model="value.yesNo"
                          name="checkbox"
                          value="yes"
                          unchecked-value="no"
                        >
                        </b-form-checkbox>
                      </div>
                    </div>
                    <div class="d-flex align-items-center mb-1" v-else>
                      <textarea
                        class="form-control"
                        rows="2"
                        type="text"
                        v-model.trim="value.title"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 mt-2">
                  <div class="col-12">
                    <div
                      class="p-2 bg-soft-primary text-center fw-medium text-primary rounded"
                    >
                      Recommendations
                    </div>
                  </div>
                </div>
                <div
                  class="row align-items-top mb-3"
                  v-for="(value, index) in forms
                    .justifications_and_recommendations.recommendations"
                  :key="'recommendations' + index"
                >
                  <div class="col-auto">
                    <span>{{ index + 1 }}. </span>
                  </div>
                  <div class="col">
                    <div class="d-flex align-items-center mb-1">
                      <textarea
                        class="form-control"
                        rows="3"
                        type="text"
                        v-model.trim="
                          forms.justifications_and_recommendations
                            .recommendations[index]
                        "
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div class="row mb-3 mt-2">
                  <div class="col-12 mb-3">
                    <div
                      class="p-2 bg-soft-primary text-center fw-medium text-primary rounded"
                    >
                      Due Diligence Sign-Off
                    </div>
                  </div>
                  <div class="col-12 col-md-6" :class="{'sign-area':accessUsername==(forms.justifications_and_recommendations
                              .due_diligence_sign_off_1.username || '')}">
                    <div class="mb-3 text-center">
                      <div class="mb-0 d-flex">
                        <multiselect
                          autocomplete="off"
                          :disabled="
                            forms.justifications_and_recommendations
                              .due_diligence_sign_off_1_status == 'signed' || forms.status=='approved'
                          "
                          v-model="
                            forms.justifications_and_recommendations
                              .due_diligence_sign_off_1
                          "
                          :options="
                            userListCanSign.filter((item) => {
                              return (
                                item.username !== (forms.approver_1.username || '') &&
                                item.username !== (forms.approver_2.username || '') ||
                                ''
                              );
                            })
                          "
                          label="name"
                          placeholder="Select User"
                          :close-on-select="true"
                          :allow-empty="false"
                          deselect-label=""
                          class="font-size-15"
                          :show-on-select="true"
                          @open="isOpen = true"
                          @close="isOpen = false"
                        >
                          <template slot="singleLabel" slot-scope="props">
                            <span v-if="props.option.id !== '-1'">
                              <span class="option__title">
                                {{ props.option.name }}
                              </span>
                            </span>
                            <span v-else>{{ props.option.name }} </span>
                          </template>
                          <template slot="option" slot-scope="props">
                            <span v-if="props.option.id !== '-1'">
                              <span class="option__title">
                                {{ props.option.name }}
                              </span>
                            </span>
                            <span v-else>{{ props.option.name }}</span>
                          </template>
                          <span slot="noResult">Oops! No User found. </span>
                        </multiselect>
                        <div
                          v-if="
                            forms.justifications_and_recommendations
                              .due_diligence_sign_off_1
                          "
                        >
                          <b-form-checkbox
                            v-if="accessUsername==(forms.justifications_and_recommendations.due_diligence_sign_off_1.username || '')"
                            @change="setDateAfterSign()"
                            v-model="
                              forms.justifications_and_recommendations
                                .due_diligence_sign_off_1_status
                            "
                            value="signed"
                            class="m-0"
                            hidden
                            id="due_diligence_sign_off_1_status"
                            unchecked-value=""
                          >
                            <label
                              ref="dueSignOff1"
                              class="btn m-0 btn-outline-primary ms-2"
                              :class="{
                                'btn-primary text-white':
                                  forms.justifications_and_recommendations
                                    .due_diligence_sign_off_1_status ==
                                  'signed',
                              }"
                              for="due_diligence_sign_off_1_status"
                              >Sign</label
                            >
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                    <table class="w-100 text-start mb-3">
                      <tr>
                        <td width="140"></td>
                        <td
                          height="160"
                          class="text-center border-bottom border-dark pb-2"
                        >
                          <div
                            v-if="
                              forms.justifications_and_recommendations
                                .due_diligence_sign_off_1
                            "
                          >
                            <img
                              v-if="
                                forms.justifications_and_recommendations
                                  .due_diligence_sign_off_1.signature &&
                                forms.justifications_and_recommendations
                                  .due_diligence_sign_off_1_status == 'signed'
                              "
                              :src="
                                forms.justifications_and_recommendations
                                  .due_diligence_sign_off_1.signature
                              "
                              height="150"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pt-2">Name</td>
                        <td class="pt-2">
                          {{
                            forms.justifications_and_recommendations
                              .due_diligence_sign_off_1
                              ? forms.justifications_and_recommendations
                                  .due_diligence_sign_off_1.name
                              : "-"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pb-1">Position</td>
                        <td class="pb-1">
                          {{
                            forms.justifications_and_recommendations
                              .due_diligence_sign_off_1
                              ? forms.justifications_and_recommendations
                                  .due_diligence_sign_off_1.position
                              : "-"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>Date</td>
                        <td>
                          <date-picker
                            v-model="
                              forms.justifications_and_recommendations
                                .due_diligence_sign_off_1_date
                            "
                            type="date"
                            value-type="format"
                            format="MMM DD, YYYY"
                            placeholder="Date"
                            :disabled="loading"
                            :disabled-date="notBeforeToday"
                            :editable="false"
                          ></date-picker>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-12 col-md-6" :class="{'sign-area':accessUsername==(forms.justifications_and_recommendations
                              .due_diligence_sign_off_2.username || '')}">
                    <div class="mb-3 text-center">
                      <div class="mb-0 d-flex">
                        <!-- || accessUsername!==(forms.originator.username || '') -->
                        <multiselect
                          autocomplete="off"
                          :disabled="
                            forms.justifications_and_recommendations
                              .due_diligence_sign_off_2_status == 'signed' || forms.status=='approved'
                          "
                          v-model="
                            forms.justifications_and_recommendations
                              .due_diligence_sign_off_2
                          "
                          :options="
                            userList.filter((item) => {
                              return (
                                item.username !== (forms.approver_1.username || '') &&
                                item.username !== (forms.approver_2.username || '') ||
                                ''
                              );
                            })
                          "
                          label="name"
                          placeholder="Select User"
                          :close-on-select="true"
                          :allow-empty="false"
                          deselect-label=""
                          class="font-size-15"
                          :show-on-select="true"
                          @open="isOpen = true"
                          @close="isOpen = false"
                        >
                          <template slot="singleLabel" slot-scope="props">
                            <span v-if="props.option.id !== '-1'">
                              <span class="option__title">
                                {{ props.option.name }}
                              </span>
                            </span>
                            <span v-else>{{ props.option.name }} </span>
                          </template>
                          <template slot="option" slot-scope="props">
                            <span v-if="props.option.id !== '-1'">
                              <span class="option__title">
                                {{ props.option.name }}
                              </span>
                            </span>
                            <span v-else>{{ props.option.name }}</span>
                          </template>
                          <span slot="noResult">Oops! No User found. </span>
                        </multiselect>
                        <div
                          v-if="
                            forms.justifications_and_recommendations
                              .due_diligence_sign_off_2
                          "
                        >
                          <b-form-checkbox
                            v-if="accessUsername==(forms.justifications_and_recommendations.due_diligence_sign_off_2.username || '')"
                            v-model="
                              forms.justifications_and_recommendations
                                .due_diligence_sign_off_2_status
                            "
                            value="signed"
                            @change="setDateAfterSign()"
                            class="m-0"
                            hidden
                            id="due_diligence_sign_off_2_status"
                            unchecked-value=""
                          >
                            <label
                              ref="dueSignOff2"
                              class="btn m-0 btn-outline-primary ms-2"
                              :class="{
                                'btn-primary text-white':
                                  forms.justifications_and_recommendations
                                    .due_diligence_sign_off_2_status ==
                                  'signed',
                              }"
                              for="due_diligence_sign_off_2_status"
                              >Sign</label
                            >
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                    <table class="w-100 text-start mb-3">
                      <tr>
                        <td width="140"></td>
                        <td
                          height="160"
                          class="text-center border-bottom border-dark pb-2"
                        >
                          <div
                            v-if="
                              forms.justifications_and_recommendations
                                .due_diligence_sign_off_2
                            "
                          >
                            <img
                              v-if="
                                forms.justifications_and_recommendations
                                  .due_diligence_sign_off_2.signature &&
                                forms.justifications_and_recommendations
                                  .due_diligence_sign_off_2_status == 'signed'
                              "
                              :src="
                                forms.justifications_and_recommendations
                                  .due_diligence_sign_off_2.signature
                              "
                              height="150"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pt-2">Name</td>
                        <td class="pt-2">
                          {{
                            forms.justifications_and_recommendations
                              .due_diligence_sign_off_2
                              ? forms.justifications_and_recommendations
                                  .due_diligence_sign_off_2.name
                              : "-"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pb-1">Position</td>
                        <td class="pb-1">
                          {{
                            forms.justifications_and_recommendations
                              .due_diligence_sign_off_2
                              ? forms.justifications_and_recommendations
                                  .due_diligence_sign_off_2.position
                              : "-"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>Date</td>
                        <td>
                          <date-picker
                            v-model="
                              forms.justifications_and_recommendations
                                .due_diligence_sign_off_2_date
                            "
                            type="date"
                            value-type="format"
                            format="MMM DD, YYYY"
                            placeholder="Date"
                            :disabled="loading"
                            :disabled-date="notBeforeToday"
                            :editable="false"
                          ></date-picker>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div class="row mb-3 mt-2">
                  <div class="col-12 mb-3">
                    <div
                      class="p-2 bg-soft-primary text-center fw-medium text-primary rounded"
                    >
                      Recommender
                    </div>
                  </div>
                  <div class="col-12 col-md-6" :class="{'sign-area':accessUsername==(forms.justifications_and_recommendations
                              .recommender_1.username || '')}">
                    <div class="mb-3 text-center">
                      <div class="mb-0 d-flex">
                        <multiselect
                          autocomplete="off"
                          :disabled="
                            forms.justifications_and_recommendations.recommender_1_status == 'signed' || forms.status=='approved'
                          "
                          v-model="
                            forms.justifications_and_recommendations
                              .recommender_1
                          "
                          :options="
                            userList.filter((item) => {
                              return (
                                item.username !== (forms.approver_1.username || '') &&
                                item.username !== (forms.approver_2.username || '') || 
                                ''
                              );
                            })
                          "
                          label="name"
                          placeholder="Select User"
                          :close-on-select="true"
                          :allow-empty="false"
                          deselect-label=""
                          class="font-size-15"
                          :show-on-select="true"
                          @open="isOpen = true"
                          @close="isOpen = false"
                        >
                          <template slot="singleLabel" slot-scope="props">
                            <span v-if="props.option.id !== '-1'">
                              <span class="option__title">
                                {{ props.option.name }}
                              </span>
                            </span>
                            <span v-else>{{ props.option.name }} </span>
                          </template>
                          <template slot="option" slot-scope="props">
                            <span v-if="props.option.id !== '-1'">
                              <span class="option__title">
                                {{ props.option.name }}
                              </span>
                            </span>
                            <span v-else>{{ props.option.name }}</span>
                          </template>
                          <span slot="noResult">Oops! No User found. </span>
                        </multiselect>
                        <div
                          v-if="
                            forms.justifications_and_recommendations
                              .recommender_1
                          "
                        >
                          <b-form-checkbox
                            v-if="accessUsername==(forms.justifications_and_recommendations.recommender_1.username || '')"
                            v-model="
                              forms.justifications_and_recommendations
                                .recommender_1_status
                            "
                            @change="setDateAfterSign()"
                            value="signed"
                            class="m-0"
                            hidden
                            id="recommender_1_status"
                            unchecked-value=""
                          >
                            <label
                              ref="recommender1"
                              class="btn m-0 btn-outline-primary ms-2"
                              :class="{
                                'btn-primary text-white':
                                  forms.justifications_and_recommendations
                                    .recommender_1_status == 'signed',
                              }"
                              for="recommender_1_status"
                              >Sign</label
                            >
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                    <table class="w-100 text-start mb-3">
                      <tr>
                        <td width="140"></td>
                        <td
                          height="160"
                          class="text-center border-bottom border-dark pb-2"
                        >
                          <div
                            v-if="
                              forms.justifications_and_recommendations
                                .recommender_1
                            "
                          >
                            <img
                              v-if="
                                forms.justifications_and_recommendations
                                  .recommender_1.signature &&
                                forms.justifications_and_recommendations
                                  .recommender_1_status == 'signed'
                              "
                              :src="
                                forms.justifications_and_recommendations
                                  .recommender_1.signature
                              "
                              height="150"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pt-2">Name</td>
                        <td class="pt-2">
                          {{
                            forms.justifications_and_recommendations
                              .recommender_1
                              ? forms.justifications_and_recommendations
                                  .recommender_1.name
                              : "-"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pb-1">Position</td>
                        <td class="pb-1">
                          {{
                            forms.justifications_and_recommendations
                              .recommender_1
                              ? forms.justifications_and_recommendations
                                  .recommender_1.position
                              : "-"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>Date</td>
                        <td>
                          <date-picker
                            v-model="
                              forms.justifications_and_recommendations
                                .recommender_1_date
                            "
                            type="date"
                            value-type="format"
                            format="MMM DD, YYYY"
                            placeholder="Date"
                            :disabled="loading"
                            :disabled-date="notBeforeToday"
                            :editable="false"
                          ></date-picker>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-12 col-md-6" :class="{'sign-area':accessUsername==(forms.justifications_and_recommendations
                              .recommender_2.username || '')}">
                    <div class="mb-3 text-center">
                      <div class="mb-0 d-flex">
                        <multiselect
                          autocomplete="off"
                          :disabled="
                            forms.justifications_and_recommendations.recommender_2_status == 'signed' || forms.status=='approved' 
                          "
                          v-model="
                            forms.justifications_and_recommendations
                              .recommender_2
                          "
                          :options="
                            userList.filter((item) => {
                              return (
                                item.username !== (forms.approver_1.username || '') &&
                                item.username !== (forms.approver_2.username || '') ||
                                ''
                              );
                            })
                          "
                          label="name"
                          placeholder="Select User"
                          :close-on-select="true"
                          :allow-empty="false"
                          deselect-label=""
                          class="font-size-15"
                          :show-on-select="true"
                          @open="isOpen = true"
                          @close="isOpen = false"
                        >
                          <template slot="singleLabel" slot-scope="props">
                            <span v-if="props.option.id !== '-1'">
                              <span class="option__title">
                                {{ props.option.name }}
                              </span>
                            </span>
                            <span v-else>{{ props.option.name }} </span>
                          </template>
                          <template slot="option" slot-scope="props">
                            <span v-if="props.option.id !== '-1'">
                              <span class="option__title">
                                {{ props.option.name }}
                              </span>
                            </span>
                            <span v-else>{{ props.option.name }}</span>
                          </template>
                          <span slot="noResult">Oops! No User found. </span>
                        </multiselect>
                        <div
                          v-if="
                            forms.justifications_and_recommendations
                              .recommender_2
                          "
                        >
                          <b-form-checkbox
                            v-if="accessUsername==(forms.justifications_and_recommendations.recommender_2.username || '')"
                            v-model="
                              forms.justifications_and_recommendations
                                .recommender_2_status
                            "
                            @change="setDateAfterSign()"
                            value="signed"
                            class="m-0"
                            hidden
                            id="recommender_2_status"
                            unchecked-value=""
                          >
                            <label
                              ref="recommender2"
                              class="btn m-0 btn-outline-primary ms-2"
                              :class="{
                                'btn-primary text-white':
                                  forms.justifications_and_recommendations
                                    .recommender_2_status == 'signed',
                              }"
                              for="recommender_2_status"
                              >Sign</label
                            >
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                    <table class="w-100 text-start mb-3">
                      <tr>
                        <td width="140"></td>
                        <td
                          height="160"
                          class="text-center border-bottom border-dark pb-2"
                        >
                          <div
                            v-if="
                              forms.justifications_and_recommendations
                                .recommender_2
                            "
                          >
                            <img
                              v-if="
                                forms.justifications_and_recommendations
                                  .recommender_2.signature &&
                                forms.justifications_and_recommendations
                                  .recommender_2_status == 'signed'
                              "
                              :src="
                                forms.justifications_and_recommendations
                                  .recommender_2.signature
                              "
                              height="150"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pt-2">Name</td>
                        <td class="pt-2">
                          {{
                            forms.justifications_and_recommendations
                              .recommender_2
                              ? forms.justifications_and_recommendations
                                  .recommender_2.name
                              : "-"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pb-1">Position</td>
                        <td class="pb-1">
                          {{
                            forms.justifications_and_recommendations
                              .recommender_2
                              ? forms.justifications_and_recommendations
                                  .recommender_2.position
                              : "-"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>Date</td>
                        <td>
                          <date-picker
                            v-model="
                              forms.justifications_and_recommendations
                                .recommender_2_date
                            "
                            type="date"
                            value-type="format"
                            format="MMM DD, YYYY"
                            placeholder="Date"
                            :disabled="loading"
                            :disabled-date="notBeforeToday"
                            :editable="false"
                          ></date-picker>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>

          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark"
              data-toggle="collapse"
              aria-expanded="true"
              aria-controls=""
              v-b-toggle.accordion-7
            >
              <div class="p-3">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-20"
                      >
                        F
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-0">Appendices</h5>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse id="accordion-7" class="position-relative">
              <div class="position-absolute opacity-50 bg-white top-0 start-0 w-100 h-100 disabled-area" v-if="isEditPage && accessUsername !== forms.originator.username"></div>
              <div class="p-3 border-top">
                <div class="row mb-3">
                  <div class="col-12">
                    <div
                      class="p-2 bg-soft-primary text-center fw-medium text-primary rounded"
                    >
                      Appendices
                    </div>
                  </div>
                </div>
                <div
                  class="row mb-3 align-items-center"
                  v-for="(value, index) in forms.appendix_list"
                  :key="index"
                >
                  <div class="col-auto mb-lg-0 mb-2" style="width: 130px">
                    <input
                        class="form-control"
                        type="text"
                        v-model.trim="value.appendix"
                      />
                  </div>
                  <div class="col-lg col-12">
                    <div class="d-flex align-items-center mb-1">
                      <input
                        class="form-control"
                        type="text"
                        v-model.trim="value.appendix_title"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>

    <pre class="font-size-10 d-none" style="height: 500px">{{ forms }}</pre>
    <PDFExport ref="modalFunc" :exportData="exportData" @callParentFunction="approverSignAndSubmit" @callParentFunction2="approverDeclinedAndUpdate" />

    <div class="row mb-4">
      <div class="col-12 col-md-12" v-if="isEditPage">
      <div class="bg-white shadow rounded p-3 ">
      <label class="fw-medium font-size-16 mb-0">Completed Factoring Application Document</label>
      <p class="text-muted mb-3 font-size-13">
        Please upload the completed application document, signed by the customer, once the application process is completed.
      </p>
      <div class="row">
          <div
            class="col-xl-4 col-sm-6"
            v-for="(value, index) in forms.completed_documents"
            :key="index"
          >
                <div class="card border shadow-none  border-2 bg-white">
                  <div class="card-body">
                    <div class="d-flex align-items-start">
                      <div class="flex-shrink-0 me-4">
                        <div class="avatar-sm">
                          <span
                            class="avatar-title bg-soft-primary text-primary font-size-22 rounded-circle"
                            :class="{
                              'bg-soft-success text-success':
                                value.status == 1,
                            }"
                          >
                          <i class="uil uil-file-graph"></i>
                          </span>
                        </div>
                      </div>
                      <div class="flex-grow-1 align-self-center">
                        <div class="">
                          <div class="d-flex w-100">
                            <!-- <input type="text" class="form-control font-size-15 mb-1 py-1 fw-medium bg-light border-bottom border-dark" v-model="value.name" v-if="index>0" > -->
                            <h5 class="font-size-15 mb-1 fw-medium" >
                            {{ value.name }}
                            </h5>
                          </div>

                          <p class="text-success" v-if="value.status == 1" :class="{'mb-0': !create_modify_permission}">
                            <i
                              class="mdi mdi-check-circle text-success"
                            ></i>
                            Completed
                            <a
                              :href="value.files"
                              class="ms-1"
                              v-b-tooltip.hover
                              :title="value.file_name"
                              v-if="value.files"
                              target="_blank"
                            >
                              <i class="uil uil-import font-size-15"></i>
                              {{ value.file_name }} ({{ value.file_size }})
                            </a>

                            <a href="javascript:void(0)" class="text-danger ms-1" v-if="value.status == 1" @click="
                                value.status=0,
                                value.files= '',
                                value.file_name= '',
                                value.file_size= '',
                                value.remark= 'Pending',
                                value.checkYesNo = ''
                            "><span><i class="mdi mdi-trash-can-outline font-size-16"></i> Remove</span>
                            </a>
                          </p>


                          <p class="text-warning" v-if="value.status == 2">
                            <i
                              class="mdi mdi-clock-time-nine-outline text-warning"
                            ></i>
                            Uploading
                            <span class="text-muted ms-1"
                              >{{ value.file_name }} ({{
                                value.file_size
                              }})</span
                            >
                          </p>
                          <p class="text-muted" v-if="value.status == 0" :class="{'mb-0': !create_modify_permission}">
                            <i class="mdi mdi-alert-circle text-danger"></i>
                            Pending
                          </p>

                          <!-- <div class="placeholder-glow">
                                      <span class="placeholder col-12 rounded"></span>
                                    </div> -->
                          <div v-if="buttonLoading[1] && value.status == 2">
                            <div
                              class="Animated progress rounded bg-white"
                              style="height: 26px"
                              role="progressbar"
                              :aria-valuenow="uploadProgress"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              <div
                                class="progress-bar progress-bar-striped progress-bar-animated rounded fw-medium font-size-13"
                                :style="`width: ${uploadProgress}%`"
                              >
                                {{ uploadProgress }}%
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <input
                              v-if="create_modify_permission"
                              class="form-control"
                              accept="application/pdf"
                              type="file"
                              :disabled="buttonLoading[1] || (isEditPage && accessUsername !== forms.originator.username)"
                              :id="value.id"
                              @change="onChangePDF"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>
      </div>
    </div>
    </div>
    <div class="row mb-4">
      <div class="col-12" v-if="isEditPage"><b-alert show variant="warning" class="mb-2"> Only {{forms.originator.name}} is permitted to update the status and write comments in the section below. </b-alert></div>
       <div class="col-12 col-md-6" v-if="isEditPage">
        <div class="form-floating mb-3 border-0 rounded bg-white shadow" v-if="isEditPage" >
          <select class="form-select border-0 rounded" v-if="accessUsername == forms.originator.username" id="statusss" aria-label="" v-model="forms.status" @change="forms.rejected_reason=' ', forms.comment='Pending Approval', forms.remarks=' '" >
            <option value="pending">Pending</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
          </select>
          <select class="form-select border-0 bg-white rounded" v-else disabled="true" id="statusss" aria-label="" v-model="forms.status" @change="forms.rejected_reason=' '">
            <option value="pending">Pending</option>
          </select>
          <label for="statusss" class="fw-medium">Application Status</label>
        </div>
      </div>
      <div class="col-12 col-md-6" v-if="isEditPage">
        <div class="form-floating mb-3 border-0 rounded bg-white shadow" v-if="forms.status =='approved'">
          <input class="form-control bg-white border-0 rounded" id="remarks" v-model="forms.remarks" placeholder="Remarks" :disabled="accessUsername !== forms.originator.username">
          <label for="remarks">Remarks</label>
        </div>

        <div class="form-floating mb-3 border-0 rounded bg-white shadow" v-if="forms.status =='pending'">
          <input class="form-control bg-white border-0 rounded" id="comment" v-model="forms.comment" placeholder="Comment" :disabled="accessUsername !== forms.originator.username">
          <label for="comment">Comment</label>
        </div>

        <div class="form-floating mb-3 border-0 rounded bg-white shadow" v-if="forms.status =='rejected'">
          <input class="form-control border-0 rounded" id="rejectedReason" v-model="forms.rejected_reason" placeholder="Rejected Reason" :disabled="accessUsername !== forms.originator.username">
          <label for="rejectedReason">Rejected Reason</label>
        </div>
      </div>
     
      <div class="col-md text-start">
        <div class="col-md-12 text-muted" v-if="isEditPage && forms.created_by">
          Created by {{ forms.created_by }} at {{ forms.date_created
          }}<br />
        </div>
        <div class="col-md-12 text-muted" v-if="forms.last_update_by">
          Last updated by {{ forms.last_update_by }} at
          {{ forms.date_updated }}
        </div>
      </div>
      <div class="col text-end">
        <button
          class="btn btn-primary btn-soft-primary border-0 ms-2"
          @click="$router.replace({ name: 'Factoring Application' }).catch(() => {})"
        >
          <i class="uil uil-times"></i> Cancel
        </button>
       
        <button
          class="btn btn-primary border-0 ms-2"
          @click="$refs.modalFunc.showModal()"
          v-if="forms.applicant && forms.originator && create_modify_permission"
        >
          <i class="uil uil-file-graph me-1"></i> Preview
        </button>
        <!-- <button
          class="btn btn-primary border-0 ms-2"
          @click="generatePDF()"
          v-if="forms.applicant && forms.originator"
        >
          <i class="uil uil-file-export me-1"></i> Generate PDF
        </button> -->
        <button
          type="button"
          class="btn btn-primary ms-2"
          @click="save"
          v-if="create_modify_permission && (this.checkUserAvailabilityToUpdateAndSign() || this.accessUsername == this.forms.originator.username)"
          :disabled="loading || buttonLoading[0]"
        >
          <span v-if="buttonLoading[0]"
            ><i class="uil uil-check me-1"></i>
            <span v-if="isEditPage">Updating</span> <span v-else>Submitting</span> 
            </span
          >
          <span v-else> 
            <i class="uil uil-check me-1"></i> 
            <span v-if="isEditPage">Update</span> <span v-else>Submit</span> 
          </span>
          <span
            class="spinner-border spinner-border-sm ms-2"
            v-if="buttonLoading[0]"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>
      <!-- end col -->
    </div>
    <Lottie
      :path="'/animate/loading_blue.json'"
      :title="'Loading'"
      :info="'Please wait...'"
      :show="showLottie"
      ref="lottieFunc"
    />
    <Common ref="commonFunc" />
  </Layout>
</template>
<script>
//var generatePDF = () => {}
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import { required, email, minLength } from "vuelidate/lib/validators";
export const equal2A = (value) => {
  return value !== "YOUR_API_ID";
};
export const equal2B = (value) => {
  return value !== "YOUR_API_HASH";
};
import PDFExport from "@/views/pages/app/factoring-application-export";
/**
 * Starter page
 */
const PageTitle = "Factoring Application";

export default {
  components: {
    Layout,
    PageHeader,
    Lottie,
    Common,
    DatePicker,
    Multiselect,
    PDFExport,
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  beforeRouteLeave (to, from , next) {
    if (!this.isDataSizeExceeded()) {
      // Data size is not exceeded, allow navigation without confirmation
      next();
    } else {
      if (!this.completed2Exit) {
        // Data size exceeds the default, show confirmation message
        Swal.fire({
          icon: "info",
          title: "Unsaved Changes",
          html: `Do you really want to leave? You have unsaved changes!`,
          showCancelButton:true,
          confirmButtonColor: "#222",
          confirmButtonText: "Yes",
        }).then((answer) => {
          if (answer.isConfirmed) {
            next();
          } else {
            next(false);
          }
        });
    } else {
      next();
    }
    }
  },
  data() {
    return {
      completed2Exit:false,
      id: "",
      prevRoute: {
        query: {
          status: "",
        },
      },
      title: "",
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: "Factoring Application",
          to: "/factoring-application",
        },
        {
          text: "",
          active: true,
        },
      ],
      isEditPage: false,
      uploadProgress: 0,
      returnData: {},
      userList: [],
      userListCanSign: [],
      applicantList: [],
      authorization: [],
      accessToken: "",
      accessEmail: "",
      accessPhone: "",
      accessUsername: "",
      account_type: "",
      showLottie: false,
      currency: "",
      directorsAndContractPerson:[],
      nature_of_contract: [
        {
          label: "Recurring",
          value: "recurring",
        },
        {
          label: "One Off",
          value: "one_off",
        },
        {
          label: "Milestone/Staggered",
          value: "milestone_staggered",
        },
        {
          label: "Progress",
          value: "progress",
        },
      ],
      currencyRates: [
        {
          currency: "MYR",
          rate: "1",
        },
        {
          currency: "THB",
          rate: "0.13333712",
        },
        {
          currency: "SGD",
          rate: "3.46075",
        },
        {
          currency: "USD",
          rate: "4.66648",
        },
        {
          currency: "GBP",
          rate: "5.82746",
        },
        {
          currency: "EUR",
          rate: "5.07621",
        },
        {
          currency: "AUD",
          rate: "3.03249",
        },
        {
          currency: "CNY",
          rate: "0.643324",
        },
        {
          currency: "IDR",
          rate: "0.000301324",
        },
        {
          currency: "INR",
          rate: "0.0562212",
        },
      ],
      pre_factoring_purpose_type: [
        "Term Advance",
        "Supplier Advance",
        "Bond Advance",
        "Cash Advance",
      ],
      factoring_purpose_type: [
        "Invoice Advance",
        "Collection Agent",
        "Letter of Support",
        "Letter of Undertaking",
      ],
      selectedMaximumExposureFormula:"13",
      maximumExposureFormula: ["13", "15","20", "30"],
      period: [30, 60, 90, 120, 150, 180],
      collection_forecast: [
        {
          label: "None",
          value: 0,
        },
        {
          label: "Thirty",
          value: 30,
        },
        {
          label: "Forty Five",
          value: 45,
        },
        {
          label: "Sixty",
          value: 60,
        },
        {
          label: "Seventy Five",
          value: 75,
        },
        {
          label: "Ninety",
          value: 90,
        },
        {
          label: "One Hundred Twenty",
          value: 120,
        },
        {
          label: "One Hundred Fifty",
          value: 150,
        },
        {
          label: "One Hundred Eighty",
          value: 180,
        },
      ],
      tenure: [2, 4, 6, 12, 18, 24],
      installment_due_dates: [
        {
          label: "None",
          value: 0,
        },
        {
          label: "One",
          value: 1,
        },
        {
          label: "Two",
          value: 2,
        },
        {
          label: "Three",
          value: 3,
        },
        {
          label: "Four",
          value: 4,
        },
        {
          label: "Five",
          value: 5,
        },
        {
          label: "Six",
          value: 6,
        },
        {
          label: "Seven",
          value: 7,
        },
        {
          label: "Eight",
          value: 8,
        },
        {
          label: "Nine",
          value: 9,
        },
        {
          label: "Ten",
          value: 10,
        },
        {
          label: "Eleven",
          value: 11,
        },
        {
          label: "Twelve",
          value: 12,
        },
        {
          label: "Thirteen",
          value: 13,
        },
        {
          label: "Fourteen",
          value: 14,
        },
        {
          label: "Fifteen",
          value: 15,
        },
        {
          label: "Sixteen",
          value: 16,
        },
        {
          label: "Seventeen",
          value: 17,
        },
        {
          label: "Eighteen",
          value: 18,
        },
        {
          label: "Nineteen",
          value: 19,
        },
        {
          label: "Twenty",
          value: 20,
        },
        {
          label: "Twenty-One",
          value: 21,
        },
        {
          label: "Twenty-Two",
          value: 22,
        },
        {
          label: "Twenty-Three",
          value: 23,
        },
        {
          label: "Twenty-Four",
          value: 24,
        },
        {
          label: "Twenty-Five",
          value: 25,
        },
        {
          label: "Twenty-Six",
          value: 26,
        },
        {
          label: "Twenty-Seven",
          value: 27,
        },
        {
          label: "Twenty-Eight",
          value: 28,
        },
        {
          label: "Twenty-Nine",
          value: 29,
        },
        {
          label: "Thirty",
          value: 30,
        },
        {
          label: "Thirty-One",
          value: 31,
        },
      ],
      "contracts_type": [
        {
          "name": "Federal Govt Contracts",
          "value": "federal_goverment",
          "payment_mode": "eP"
        },
        {
          "name": "Federal Govt Agencies",
          "value": "federal_goverment",
          "payment_mode": "Non-eP"
        },
        {
          "name": "IPTAs",
          "value": "non_federal_goverment",
          "payment_mode": "Non-eP"
        },
        {
          "name": "GLCs",
          "value": "non_federal_goverment",
          "payment_mode": "Non-eP"
        },
        {
          "name": "State Govt",
          "value": "non_federal_goverment",
          "payment_mode": "Non-eP"
        },
        {
          "name": "Local Authorities",
          "value": "non_federal_goverment",
          "payment_mode": "Non-eP"
        },
        {
          "name": "National Car Companies",
          "value": "non_federal_goverment",
          "payment_mode": "Non-eP"
        }
      ],
      payment_mode: [
        {
          name: "ePerolehan",
          value: "eP",
        },
        {
          name: "Non-ePerolehan",
          value: "Non-eP",
        },
      ],
      typeEntity: [
        { name: "Private Limited", value: "private_limited" },
        { name: "Partnership", value: "partnership" },
        { name: "Proprietorship", value: "proprietorship" },
      ],
      awarder_list: [
        "Road Transport Department (JPJ)",
        "Royal Malaysia Police (PDRM)",
        "Malaysian Anti-Corruption Commission (MACC)",
        "Malaysian Immigration Department (Jabatan Imigresen Malaysia)",
        "Malaysian Customs Department (Jabatan Kastam Malaysia)",
        "Ministry of Health Malaysia (MOH)",
        "Ministry of Education Malaysia (MOE)",
        "Malaysian Communications and Multimedia Commission (MCMC)",
        "Malaysian Palm Oil Board (MPOB)",
        "Malaysia External Trade Development Corporation (MATRADE)",
        "Other",
      ],
      awarderSelected: "Road Transport Department (JPJ)",
      awarderSelfInput: "",
      contract_tenure: [24, 30, 36, 42, 48, 54, 60, "Other"],
      contractTenureSelected: 30,
      contractTenureSelfInput: "",
      exportData: {},
      defaultDataSize:10000,
      forms: {
        application_id: "",
        application_db_code:"",
        nature_of_contract: {
          label: "Recurring",
          value: "recurring",
        },
        currency: "MYR",
        applicant: "",
        originator:"",
        lead: "None",
        pre_factoring_purpose_type: "Term Advance",
        factoring_purpose_type: "Invoice Advance",
        term_advance_pre_factoring: {
          current: 0,
          proposed: 0,
          total: 0,
        },
        factoring: {
          current: 0,
          proposed: 0,
          total: 0,
        },
        maximum_exposure: 0,
        contract_type: {
          name: "Federal Govt Contracts",
          value: "federal_goverment",
          payment_mode: "eP",
        },
        contract_title: "",
        contract_awarder: "",
        contract_amount: "",
        contract_tenure: "",
        project_tenure: "",
        roce: "",
        annualised_roce: "",
        payment_mode: {
          name: "ePerolehan",
          value: "eP",
        },
        facility_term_sheet: {
          pre_factoring: {
            purpose: "",
            limit: 0,
            total_pre_financing_amount: "",
            processing_fees: 0,
            profit_rate: "",
            rollover_fee: "",
            margin_of_advance: 0,
            tenure: 0,
            repayments: 0,
            installment_due_dates: {
              label: "One",
              value: 1,
            },
            day_of_collections: 60,
            supplier_bond_advance_rate: "",
            cash_advance_rate: "",
            credit_period: 0,
            recourse_period: 0,
            enabled: false
          },
          factoring: {
            purpose: "",
            limit: 0,
            processing_fees: 0,
            administration_fee: "",
            profit_rate: "0",
            rollover_fee: "0.5",
            margin_of_advance: 80,
            credit_period: 90,
            recourse_period: 90,
          },
        },
        approver_1: {},
        approver_2: {},
        approver1_approval_status: "",
        approver1_approval_date: "",
        approver2_approval_status: "",
        approver2_approval_date: "",
        collection_forecast: [
          {
            name: "0",
            weeks: [
              {
                name: "1",
                remark: "",
              },
              {
                name: "2",
                remark: "Term Advance",
              },
              {
                name: "3",
                remark: "",
              },
              {
                name: "4",
                remark: "",
              },
            ],
            general_remark: "",
          },
          {
            name: "1",
            weeks: [
              {
                name: "1",
                remark: "",
              },
              {
                name: "2",
                remark: "",
              },
              {
                name: "3",
                remark: "",
              },
              {
                name: "4",
                remark: "",
              },
            ],
            general_remark: "Invoice Advance 1",
          },
          {
            name: "2",
            weeks: [
              {
                name: "1",
                remark: "Advance 2",
              },
              {
                name: "2",
                remark: "",
              },
              {
                name: "3",
                remark: "",
              },
              {
                name: "4",
                remark: "Collection 1",
              },
            ],
            general_remark: "",
          },
          {
            name: "3",
            weeks: [
              {
                name: "1",
                remark: "Advance 3",
              },
              {
                name: "2",
                remark: "",
              },
              {
                name: "3",
                remark: "",
              },
              {
                name: "4",
                remark: "Collection 2",
              },
            ],
            general_remark: "",
          },
          {
            name: "Subsequent",
            weeks: [
              {
                name: "1",
                remark: "",
              },
              {
                name: "2",
                remark: "",
              },
              {
                name: "3",
                remark: "",
              },
              {
                name: "4",
                remark: "",
              },
            ],
            general_remark: "Continue 21 months",
          },
        ],
        average_collection_forecast: {
          label: "Sixty",
          value: 60,
        },
        collection_forecast_milestoneStaggered: [
          {
            name: "",
            months: [
              {
                name: "1",
                remark: "",
              },
              {
                name: "6",
                remark: "",
              },
            ],
            general_remark: "",
          },
          {
            name: "",
            months: [
              {
                name: "7",
                remark: "",
              },
              {
                name: "12",
                remark: "",
              }
            ],
            general_remark: "",
          },
          {
            name: "",
            months: [
              {
                name: "13",
                remark: "",
              },
              {
                name: "18",
                remark: "",
              }
            ],
            general_remark: "",
          }
        ],
        collection_forecast_one_off: [
          {
            name: "1",
            weeks: [
              {
                name: "Day 1",
                remark: "",
              },
              {
                name: "Day 30",
                remark: "",
              },
            ],
            general_remark: "",
          },
          {
            name: "2",
            weeks: [
              {
                name: "",
                remark: "",
              },
              {
                name: "Day 60",
                remark: "",
              },
            ],
            general_remark: "",
          },
        ],
        collection_forecast_custom_tenure_type:"Month",
        security_and_credit_support:{
          factoring:[
            "Factoring Letter of Offer and Acceptance denoting offer by Ikhlas Al Dain Sdn Bhd and acceptance by of the company_name.",
            "Factoring Agreement between company_name and Ikhlas Al Dain Sdn Bhd.",
            "Letter of Undertaking issued by company_name to Ikhlas Al Dain Sdn Bhd.",
            "Guarantee and Indemnity by all directors.",
            "Assignment of all factored accounts receivable.",
            "",
            "",
          ],
          pre_factoring: [
            "Pre-Factoring Letter of Offer and Acceptance denoting offer by Ikhlas Al Dain Sdn Bhd and acceptance bycompany_name.",
            "Pre-Factoring Agreement between company_name and Ikhlas Al Dain Sdn Bhd.",
            "Letter of Undertaking issued by company_name to Ikhlas Al Dain Sdn Bhd.",
            "Guarantee and Indemnity by all directors.",
            "Assignment of all factored accounts receivable.",
            "",
            "",
          ]
        },
        project_income: {
          term_advance_pre_factoring: {
            processing_fee: 0,
            profit: 0,
          },
          factoring: {
            processing_fee: 0,
            administration_fee: 0,
            profit: 0,
          },
          total: 0,
        },
        facility_information: {
          supplier_advance: "",
          cash_advance: "",
          term_advance: "",
          bond_advance: "",
          invoice_advance: "",
          collection_agent: "",
          letter_of_support: "",
          letter_of_undertaking: "",
        },
        pre_factoring_payee: [
          {
            payee_company: "",
            location: "",
            purpose: "",
            amount: 0,
            currency: "MYR",
            rate: "1",
            total: 0,
          },
        ],
        pre_factoring_payee_total_amount: 0,
        risk_analysis_report: [
          {
            type_of_identified_risks: "Delivery Risk",
            risk_mitigation_list: [
              {
                title:
                  "Applicant has experience in delivering similar contracts in the past.",
                yesNo: "yes",
              },
              {
                title:
                  "Applicant has the capability and expertise in executing the contract.",
                yesNo: "yes",
              },
              {
                title: "Contract is simple to perform and uncomplicated.",
                yesNo: "yes",
              },
              {
                title:
                  "Finished goods are involved and not work-in-progress (WIP).",
                yesNo: "no",
              },
              {
                title:
                  "Established manufacturer/fabricator/distributor/supplier.",
                yesNo: "no",
              },
              {
                title:
                  "Favourable manufacturer/fabricator/distributor/supplier site visit findings.",
                yesNo: "no",
              },
              {
                title:
                  "Short gap between disbursement and delivery of goods/services.",
                yesNo: "no",
              },
              {
                title:
                  "Applicant is our existing/previous client with good track record.",
                yesNo: "no",
              },
              {
                title:
                  "Sales Factoring case where advance/s will only be made upon delivery confirmation.",
                yesNo: "yes",
              },
              {
                title:
                  "No outflow of our funds as we are acting as a collection agent.",
                yesNo: "no",
              },
            ],
          },
          {
            type_of_identified_risks: "Collection Risk",
            risk_mitigation_list: [
              {
                title:
                  "Federal government contract where proceeds will be collected via e-Perolehan (eP). ",
                yesNo: "yes",
              },
              {
                title:
                  "Obligor is a federal government ministry/department/unit.",
                yesNo: "yes",
              },
              {
                title:
                  "Obligor is a federal government agency/state/local government.",
                yesNo: "no",
              },
              {
                title:
                  "Obligor is a financially strong government-linked company (GLC).",
                yesNo: "no",
              },
              {
                title:
                  "Obligor is an established multi-national (MNC) with local presence.",
                yesNo: "no",
              },
              {
                title:
                  "Applicant/directors/ partners are free from legal suits.",
                yesNo: "yes",
              },
              {
                title:
                  "Legal suits against applicant/directors/partners have been put on hold.",
                yesNo: "no",
              },
              {
                title:
                  "Applicant's/directors'/partners' bad debts have been rescheduled.",
                yesNo: "no",
              },
              {
                title:
                  "Applicant's/directors'/partners' bad debts will be settled prior to our disbursement.",
                yesNo: "no",
              },
              {
                title:
                  "Applicant's/directors'/partners' bad debts will be set off against our disbursement.",
                yesNo: "no",
              },
            ],
          },
          {
            type_of_identified_risks: "Exposure Risk",
            risk_mitigation_list: [
              {
                title:
                  "Our exposure will be relatively low and will not exceed RM0.5 M.",
                yesNo: "no",
              },
              {
                title:
                  "Our exposure will exceed RM0.5 M but delivery and collection risks are mitigated.",
                yesNo: "yes",
              },
              {
                title:
                  "Applicant has no other borrowings or has a relatively low borrowing level.",
                yesNo: "no",
              },
            ],
          },
        ],
        justifications_and_recommendations: {
          justifications: [
            {
              title:
                "Procurement contract to be funded falls within the Accepted category.",
              yesNo: "yes",
            },
            {
              title:
                "We will have full access to information on the delivery and collection process.",
              yesNo: "yes",
            },
            {
              title:
                "Risks to be assumed have been assessed to be normal and will be well mitigated.",
              yesNo: "yes",
            },
            {
              title:
                "Standard terms and conditions are proposed that do not go beyond our credit guidelines.",
              yesNo: "yes",
            },
            {
              title:
                "Pricing to be charged is acceptable and above our minimum rate.",
              yesNo: "yes",
            },
            {
              title:
                "Waiver of one of the directors,  {one_of_directors} to stand as a guarantor as he is a silent partner and holds only 30% interest, which he is in the process of disposing.",
              yesNo: "yes",
            },
            {
              title:
                "The two other directors who shall be standing as guarantors hold a 70% majority shareholding and are the ones running the company.",
              yesNo: "yes",
            },
            {
              title: "",
              yesNo: "yes",
            },
          ],
          recommendations: [
            "",
            "",
            "",
          ],
          due_diligence_sign_off_1: "",
          due_diligence_sign_off_1_status: "",
          due_diligence_sign_off_1_date: "",
          due_diligence_sign_off_2: "",
          due_diligence_sign_off_2_status: "",
          due_diligence_sign_off_2_date: "",
          recommender_1: "",
          recommender_1_status: "",
          recommender_1_date: "",
          recommender_2: "",
          recommender_2_status: "",
          recommender_2_date: ""
        },
        appendix_list: [
          {
            appendix: "Appendix A",
            appendix_title: "Letter of Award of Contract",
          },
          {
            appendix: "Appendix B",
            appendix_title:
              "Experian Credit Check Report on Applicant and Directors",
          },
          {
            appendix: "Appendix C",
            appendix_title:
              "Request to Waive Requirement for One of the Directors to Stand as Guarantor",
          },
          {
            appendix: "Appendix D",
            appendix_title: "Profile of Applicant",
          },
          {
            appendix: "Appendix E",
            appendix_title: "Profile of Directors",
          },
          {
            appendix: "Appendix F",
            appendix_title: "Bank Statements",
          },
          {
            appendix: "Appendix G",
            appendix_title: "Financial Statements",
          },
          {
            appendix: "Appendix H",
            appendix_title: "",
          },
          {
            appendix: "Appendix I",
            appendix_title: "",
          },
          {
            appendix: "Appendix J",
            appendix_title: "",
          },
          {
            appendix: "Appendix K",
            appendix_title: "",
          },
          {
            appendix: "Appendix L",
            appendix_title: "",
          },
          {
            appendix: "Appendix M",
            appendix_title: "",
          },
          {
            appendix: "Appendix N",
            appendix_title: "",
          },
          {
            appendix: "Appendix O",
            appendix_title: "",
          },
          {
            appendix: "Appendix P",
            appendix_title: "",
          },
          {
            appendix: "Appendix Q",
            appendix_title: "",
          }
        ],
        affix_company_stamp: "",
        name_of_authorised_signatory: "",
        date_of_authorised_signatory: "",
        date_created: "",
        date_updated: "",
        created_by: "",
        last_update_by: "",
        comment: " ",
        remarks: " ",
        rejected_reason: "",
        status: "pending",
        approver_1_declined_comment: "",
        approver_2_declined_comment: "",
        completed_documents: [{
            id: 1,
            name: "Completed Document",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "",
            remark: "",
            custom_remark: "",
            checkYesNo: "",
        },
        {
            id: 2,
            name: "Additional Document (1)",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "",
            remark: "",
            custom_remark: "",
            checkYesNo: "",
        },
        {
            id: 3,
            name: "Additional Document (2)",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "",
            remark: "",
            custom_remark: "",
            checkYesNo: "",
        }]
      },
      formData: {
        company_name: "",
        business_registration_no: "",
        date_of_registration: "",
        type_of_entity: "",
        business_address: "",
        business_tel: "",
        business_email: "",
        contact_person: "",
        position: "",
        contact_tel: "",
        contact_email: "",
        directors: [
          { name: "", nric: "" },
          { name: "", nric: "" },
        ],
        banks: [
          {
            bank_name: "",
            branch: "",
            account_number: "",
            facility: "",
            limit: "",
          },
        ],
        supporting_docs: [
          {
            id: 1,
            name: "Letter of Award or Surat Setuju Terima (SST)",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "Appendix A",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 2,
            name: "Purchase Order (PO) or Warrant or Indent",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "Appendix B",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 3,
            name: "Contract",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "Appendix C",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 4,
            name: "Profile of Company",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "Appendix D",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 5,
            name: "Profile of Directors or Partners or Proprietor",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "Appendix E",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 6,
            name: "Latest Six Months Bank Statements",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "Appendix F",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 7,
            name: "Latest One Year Financial Statements",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "Appendix G",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 8,
            name: "Latest Month EPF Contribution for Staff (Penyata Caruman KWSP)",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 9,
            name: "Identity Card of All Directors or Partners or Proprietor",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 10,
            name: "Applicant Explanation of Latest Financial Position",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 11,
            name: "Additional Documents",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
        ],
        current_facilities_limit: {
          pre_factoring: {
            limit: 0,
          },
          factoring: {
            limit: 0,
          },
        },
        created_by: "",
        reason_inactive: "",
        active: 1,
      },
      formData2: {
        banks: [
          {
            bank_name: "",
            branch: "",
            account_number: "",
            facility: "",
            limit: "",
          },
        ],
      },

      submitted: false,
      loading: false,
      buttonLoading: [false, false, false],
      create_modify_permission:false
    };
  },
  validations: {
    formData: {
      company_name: {
        required,
      },
      business_registration_no: {
        required,
      },
      date_of_registration: {
        required,
      },
      type_of_entity: {
        required,
      },
      business_address: {
        required,
      },
      business_tel: {
        required,
      },
      business_email: {
        required,
        email,
      },
      contact_person: {
        required,
      },
      position: {
        required,
      },
      contact_tel: {
        required,
      },
      contact_email: {
        required,
        email,
      },
    },
    formData2: {
      banks: {
        required,
        minLength: minLength(1),
        $each: {
          bank_name: {
            required,
            minLength: minLength(2),
          },
          branch: {
            required,
            minLength: minLength(2),
          },
          account_number: {
            required,
            minLength: minLength(2),
          },
          facility: {
            required,
            minLength: minLength(2),
          },
          limit: {},
        },
      },
    },
    forms: {
      applicant: {
        required,
      },
      originator: {
        required,
      },
    },
  },
  middleware: "authentication",
  async mounted() {
    await this.reload();
    this.title = PageTitle;
    this.items[2].text = "Application Form";
    this.accessToken = this.$refs.commonFunc.getToken();
    this.accessUsername = this.$refs.commonFunc.getUsername();
    await this.getUsers();
    await this.getApplicant();
    await this.getAuthority();

    if (this.$route.params.id!=='' && this.$route.params.id !==undefined) {
      await this.getData();
    }
  },
  created() {
    this.exportData = {
      forms: this.forms,
      formData: this.formData,
    };
    //
    // this.currencyRates.forEach(element => {
    //   if (element.currency!=='MYR'){
    //     this.getCurrencyRateByCountryToMYR(element.currency)
    //   }
    //   else if (element.currency=='CNY'){
    //     this.getCurrencyRateByCountryToMYR('CNY')
    //   }else{
    //     this.getCurrencyRateByCountryToMYR(element.currency)
    //   }
    // })
  },
  methods: {
    isDataSizeExceeded() {
      if (!this.isEditPage){
      const jsonData = JSON.stringify(this.forms);
      const dataSize = jsonData.length;
      console.log(dataSize, this.defaultDataSize);
      return dataSize > this.defaultDataSize
      }
    },
    convertFileSize(fileSize) {
      var formattedFileSize = "";

      if (fileSize < 1024) {
        formattedFileSize = fileSize + " bytes";
      } else if (fileSize < 1048576) {
        // 1024 * 1024
        formattedFileSize = (fileSize / 1024).toFixed(2) + " KB";
      } else if (fileSize < 1073741824) {
        // 1024 * 1024 * 1024
        formattedFileSize = (fileSize / 1048576).toFixed(2) + " MB";
      } else {
        formattedFileSize = (fileSize / 1073741824).toFixed(2) + " GB";
      }
      return formattedFileSize;
    },
    async onChangePDF(e) {
      console.log(e.target.id);
      let file = e.target.files[0];
      console.log(file);
      this.forms.completed_documents.forEach((element) => {
        if (element.id == e.target.id) {
          element.status = 2;
          element.file_name = file.name;
          element.file_size = this.convertFileSize(file.size);
          element.files = file;
          this.uploadFile(element);
        }
      });
      // this.formSuccessData.transactionReceipt = file
      // this.formSuccessData.transactionReceiptFile = file
    },
    uploadFile(value) {
      this.$Progress.start();
      this.buttonLoading[1] = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("mediaPath", "factoring_application_doc");
      //bodyFormData.append("id", value.id);
      bodyFormData.append("media", value.files);
      //bodyFormData.append("size", value.file_size);
     // bodyFormData.append("name", value.file_name);

      axios({
        method: "post",
        url: appConfig.APIHost + 'controller/media/saveMediaFile',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          this.uploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData);
            value.status = 1;
            value.checkYesNo = "checked";
            value.remark = value.appendix;
            value.files = resData.media_path;
            console.log("uploaded");
            console.log(this.forms.completed_documents);
            //upload to the server temporary place first and you return id to me, after that i submit the form you move the data to ori place
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.buttonLoading[1] = false;
          this.uploadProgress = 0;
          this.submitted = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.buttonLoading[1] = false;
          this.uploadProgress = 0;
          this.submitted = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    generatePDF: function () {
      this.exportData = {
        forms: this.forms,
        formData: this.formData,
      };
      this.$refs["modalFunc"].generatePDF();
    },
    // if (type == "decimal") {
    //     const numericValue = event.target.value.replace(/[^0-9.]/g, "");
    //     if (parentModel == "") {
    //       this[childModel] = numericValue;
    //     } else {
    //       this[parentModel][childModel] = numericValue;
    //     }
    //   }
   
    getCurrencyRates() {
      console.log("getCurrencyRates");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", "admin123");
      bodyFormData.append("accessUsername", "admin123");
      axios({
        method: "get",
        url: "/api/applicants.json",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            this.currencyRates = [
              {
                currency: "MYR",
                rate: "1",
              },
              {
                currency: "SGD",
                rate: "3.46075",
              },
              {
                currency: "USD",
                rate: "4.66648",
              },
              {
                currency: "GBP",
                rate: "5.82746",
              },
              {
                currency: "EUR",
                rate: "5.07621",
              },

              {
                currency: "AUD",
                rate: "3.03249",
              },
              {
                currency: "RMB",
                rate: "0.643324",
              },
              {
                currency: "IDR",
                rate: "0.000301324",
              },
              {
                currency: "INR",
                rate: "0.0562212",
              },
            ];
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    getCurrencyRateByCountryToMYR(currency) {
      this.$Progress.start();
      this.loading = true;
      axios({
        method: "get",
        url: `https://api.currencyapi.com/v3/latest?apikey=fca_live_6chXiOUHpsTQrieVVGw9bzvDyP9JHs3RrC1ihIia&currencies=MYR&base_currency=${currency}`,
      })
        .then((response) => {
          var resData = response.data;
          const value = resData.data.MYR.value;
          this.currencyRates.forEach((element) => {
            if (element.currency == currency) {
              element.rate = value;
            }
          });
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },

    updateRemarkBasedOnStatus(value) {
      if (value.checkYesNo == "") {
        value.remark = "Pending";
      } else if (value.checkYesNo == "checked") {
        value.remark = value.appendix;
      } else if (value.checkYesNo == "unchecked") {
        value.remark = "Not Applicable";
      }
    },
    awarderChange() {
      if (this.awarderSelected == "Other") {
        this.forms.contract_awarder = this.awarderSelfInput;

        if (this.awarder_list.includes(this.awarderSelfInput)) {
          this.awarderSelected = this.awarderSelfInput;
          this.awarderSelfInput = "";
        }
      } else {
        this.awarderSelfInput = "";
        this.forms.contract_awarder = this.awarderSelected;
      }
    },
    purposeChange() {
      if (this.forms.pre_factoring_purpose_type == "Term Advance") {
        this.forms.facility_term_sheet.pre_factoring.purpose =
          "Term Advance for working capital.";
      } else {
        this.forms.facility_term_sheet.pre_factoring.purpose =
          "Supplier Advance/ Bond Advance/ Cash Advance";
      }

      if (this.forms.factoring_purpose_type !== "Letter of Support") {
        this.forms.facility_term_sheet.factoring.purpose =
          "Accounts receivable financing."; //this.forms.factoring_purpose_type
        //Accounts receivable financing.
      } else {
        this.forms.facility_term_sheet.factoring.purpose = "";
      }
      this.purposeLimitFollowPurposeChange();
      // forms.facility_term_sheet.pre_factoring.limit
    },
    purposeLimitFollowPurposeChange() {
      
      //pre-factoring
      if (!this.isEditPage){
        this.forms.facility_information = {
          supplier_advance: "",
          cash_advance: "",
          term_advance: "",
          bond_advance: "",
          invoice_advance: "",
          collection_agent: "",
          letter_of_support: "",
          letter_of_undertaking: "",
        };
        if (this.forms.pre_factoring_purpose_type == "Supplier Advance") {
          this.forms.facility_information.supplier_advance =
            this.forms.facility_term_sheet.pre_factoring.limit;
        }
        if (this.forms.pre_factoring_purpose_type == "Bond Advance") {
          this.forms.facility_information.bond_advance =
            this.forms.facility_term_sheet.pre_factoring.limit;
        }
        if (this.forms.pre_factoring_purpose_type == "Term Advance") {
          this.forms.facility_information.term_advance =
            this.forms.facility_term_sheet.pre_factoring.limit;
        }
        if (this.forms.pre_factoring_purpose_type == "Cash Advance") {
          this.forms.facility_information.cash_advance =
            this.forms.facility_term_sheet.pre_factoring.limit;
        }
        //factoring
        if (this.forms.factoring_purpose_type == "Invoice Advance") {
          this.forms.facility_information.invoice_advance =
            this.forms.facility_term_sheet.factoring.limit;
        }
        if (this.forms.factoring_purpose_type == "Collection Agent") {
          this.forms.facility_information.collection_agent =
            this.forms.facility_term_sheet.factoring.limit;
        }
        if (this.forms.factoring_purpose_type == "Letter of Support") {
          this.forms.facility_information.letter_of_support =
            this.forms.facility_term_sheet.factoring.limit;
        }
        if (this.forms.factoring_purpose_type == "Letter of Undertaking") {
          this.forms.facility_information.letter_of_undertaking =
            this.forms.facility_term_sheet.factoring.limit;
        }
      }
    },
    payeeRatesChange(index) {
      var total = 0;
      this.forms.pre_factoring_payee[index].rate = parseFloat( this.currencyRates.find(
          (e) => e.currency === this.forms.pre_factoring_payee[index].currency
        ).rate
      );

      
      this.forms.pre_factoring_payee.forEach((element) => {
        const payee_purpose_amount = element.amount || 0;
        element.total =
          parseFloat(payee_purpose_amount) * parseFloat(element.rate || 0);
        element.total = element.total.toFixed(2);
        total += parseFloat(element.total);
      });
      if (total !==0){
        this.forms.pre_factoring_payee_total_amount = total;
      }
    
    },
    payeeAmountnRateInputChange() {
      var total = 0;
      this.forms.pre_factoring_payee.forEach((element) => {
        const payee_purpose_amount = element.amount || 0;
        element.total =
          parseFloat(payee_purpose_amount) * parseFloat(element.rate || 0);
        element.total = element.total.toFixed(2);
        total += parseFloat(element.total);
      });
      if (total !==0){
        this.forms.pre_factoring_payee_total_amount = total;
      }
    },
    contractTenureChange() {
      if (this.contractTenureSelected == "Other") {
        this.forms.contract_tenure = this.contractTenureSelfInput;

        if (
          this.contract_tenure.includes(parseInt(this.contractTenureSelfInput))
        ) {
          this.contractTenureSelected = this.contractTenureSelfInput;
          this.contractTenureSelfInput = "";
        }
      } else {
        this.contractTenureSelfInput = "";
        this.forms.contract_tenure = this.contractTenureSelected;
      }
    },
    natureContractChange() {
      if (this.forms.nature_of_contract.value == "recurring") {
        this.forms.facility_term_sheet.pre_factoring.profit_rate = "";
        this.forms.facility_term_sheet.pre_factoring.tenure = "";
        this.forms.facility_term_sheet.pre_factoring.installment_due_dates = {
          label: "None",
          value: 0,
        };
        this.forms.facility_term_sheet.pre_factoring.total_pre_financing_amount = "";
      } else {
        this.forms.facility_term_sheet.pre_factoring.supplier_bond_advance_rate = "";
        this.forms.facility_term_sheet.pre_factoring.cash_advance_rate = "";
        this.forms.facility_term_sheet.pre_factoring.credit_period = "";
        this.forms.facility_term_sheet.pre_factoring.recourse_period = "";
        this.forms.facility_term_sheet.pre_factoring.total_pre_financing_amount = "";
        this.forms.facility_term_sheet.pre_factoring.day_of_collections = "60";
      }
    },
    runFormula(){
      if (this.forms.nature_of_contract.value == "one_off") {
        this.forms.average_collection_forecast = this.collection_forecast[0];
      }

      //if applicant got information to know
      if (this.forms.applicant) {
        var currentPreFactoringFacilitiesLimit =  this.forms.applicant.current_facilities_limit.pre_factoring.limit;
        var currentFactoringFacilitiesLimit = this.forms.applicant.current_facilities_limit.factoring.limit;
        //get current limit and set
        this.forms.term_advance_pre_factoring.current = currentPreFactoringFacilitiesLimit;
        this.forms.factoring.current = currentFactoringFacilitiesLimit;
      }
      //pre-factoring define
      var pre_factoring_limit = parseFloat(this.forms.facility_term_sheet.pre_factoring.limit);
      var total_pre_financing_amount = parseFloat(this.forms.facility_term_sheet.pre_factoring.total_pre_financing_amount);
 
      var pre_factoring_processingFee = parseFloat(this.forms.facility_term_sheet.pre_factoring.processing_fees);
      var pre_factoring_tenure = this.forms.facility_term_sheet.pre_factoring.tenure || 0;
      var pre_factoring_profitRates = parseFloat(this.forms.facility_term_sheet.pre_factoring.profit_rate);

      var pre_factoring_profitRates_supplier_bond_advance = parseFloat(this.forms.facility_term_sheet.pre_factoring.supplier_bond_advance_rate );

      // var pre_factoring_profitRates_cash_advance =  parseFloat(this.forms.facility_term_sheet.pre_factoring.cash_advance_rate)

      //set pre-factoring proposed
      this.forms.term_advance_pre_factoring.proposed = this.roundUp(pre_factoring_limit);
      //set processing fee
      this.forms.project_income.term_advance_pre_factoring.processing_fee = this.roundUp(pre_factoring_processingFee);
      //set profit  (recurring)
      if (this.forms.nature_of_contract.value == "recurring") {
        this.forms.project_income.term_advance_pre_factoring.profit = this.roundUp(pre_factoring_limit * (pre_factoring_profitRates / 100) * parseFloat(pre_factoring_tenure));
        console.log("recurring Pre-Factoring Profit = "+ this.roundUp(pre_factoring_limit * (pre_factoring_profitRates / 100) * parseFloat(pre_factoring_tenure)))
      }
      else if (this.forms.nature_of_contract.value == "milestone_staggered") {
        const annualProfitRate = (pre_factoring_profitRates_supplier_bond_advance * 12) / 100;
        const dayOfCollection = this.forms.facility_term_sheet.pre_factoring.day_of_collections || 0
        const dayOfCollectionDividedByYear = dayOfCollection / 365;
        console.log(pre_factoring_profitRates_supplier_bond_advance, pre_factoring_tenure, pre_factoring_profitRates)
        this.forms.project_income.term_advance_pre_factoring.profit = this.roundUp(total_pre_financing_amount * annualProfitRate * dayOfCollectionDividedByYear);
        console.log(pre_factoring_limit, annualProfitRate, dayOfCollectionDividedByYear)
        console.log("milestone_staggered Pre-Factoring Profit = "+  this.roundUp(total_pre_financing_amount * annualProfitRate * dayOfCollectionDividedByYear))
        // For this case how we get Profit Rate RM 374,078 we take 
        //Total Pre Financing required by client which is RM7,585,474.67
      }
      else {
        const annualProfitRate = (pre_factoring_profitRates_supplier_bond_advance * 12) / 100;
        const dayOfCollection = this.forms.facility_term_sheet.pre_factoring.day_of_collections || 0
        const dayOfCollectionDividedByYear = dayOfCollection / 365;
        this.forms.project_income.term_advance_pre_factoring.profit = this.roundUp(pre_factoring_limit * annualProfitRate * dayOfCollectionDividedByYear);
        //RM70,000 x 33% x 60 days/365days
        //Limit x annual profit rate x 60days/365

        console.log("Pre-Factoring Profit"+ this.roundUp(pre_factoring_limit * annualProfitRate * dayOfCollectionDividedByYear))
      }

      //set profit  (one-off)

      //Set Repayments
      this.forms.facility_term_sheet.pre_factoring.repayments =
        this.roundUp((pre_factoring_limit + this.forms.project_income.term_advance_pre_factoring.profit) / pre_factoring_tenure) || 0;

      //factoring define
      var factoring_limit = parseFloat(this.forms.facility_term_sheet.factoring.limit || 0);
      var factoring_processingFee = parseFloat(this.forms.facility_term_sheet.factoring.processing_fees);
      var factoring_administrationRate = parseFloat(this.forms.facility_term_sheet.factoring.administration_fee);
      var factoring_profitRates = parseFloat(this.forms.facility_term_sheet.factoring.profit_rate);
      var factoring_marginOfAdvance = parseFloat(this.forms.facility_term_sheet.factoring.margin_of_advance);
      var daysOfAdvance = parseFloat(this.forms.average_collection_forecast.value);
      var contractAmount = parseFloat(this.forms.contract_amount || 0);

      //set factoring proposed
      this.forms.factoring.proposed = this.roundUp(factoring_limit);
      //set processing fee
      this.forms.project_income.factoring.processing_fee = this.roundUp(factoring_processingFee);
      console.log(contractAmount)
      if (this.forms.nature_of_contract.value !== "milestone_staggered") {
        //set administration fee
        //here propose got detect ## pre_factoring_type

        //19 Mar 2024 before don't have !this.forms.project_income.factoring.administration_fee and !this.forms.project_income.factoring.profit
        if (!this.forms.project_income.factoring.administration_fee){
        this.forms.project_income.factoring.administration_fee = this.roundUp( contractAmount * (factoring_administrationRate / 100));
        }
        //set profit
        if (!this.forms.project_income.factoring.profit){
        this.forms.project_income.factoring.profit = this.roundUp(contractAmount * (factoring_marginOfAdvance / 100) *((factoring_profitRates / 100) * 12) * (daysOfAdvance / 365));
        }
      }
      else{
        //if blank of value we only help to calculate
        console.log(!this.isEditPage)
      
        if (!this.forms.project_income.factoring.administration_fee){
          this.forms.project_income.factoring.administration_fee = this.roundUp( contractAmount * (factoring_administrationRate / 100) || 0)
        }
        if (!this.forms.project_income.factoring.profit){
          //set profit
          this.forms.project_income.factoring.profit = this.roundUp(contractAmount * (factoring_marginOfAdvance / 100) *((factoring_profitRates / 100) * 12) * (daysOfAdvance / 365) || 0);
        }
        
      }
      console.log("Administration fee = "+ this.roundUp( contractAmount * (factoring_administrationRate / 100)))
      console.log("Profit = "+ this.roundUp(contractAmount *(factoring_marginOfAdvance / 100) * ((factoring_profitRates / 100) * 12) * (daysOfAdvance / 365)));

      //other
      //set pre_factoring_payee amount
      // if (this.forms.pre_factoring_payee[0].amount == "") {
      //   parseFloat(this.pre_factoring_limit || 0);
      // }

      //count project income total
      this.forms.project_income.total =
      parseFloat(this.forms.project_income.term_advance_pre_factoring.processing_fee || 0) +
      parseFloat(this.forms.project_income.term_advance_pre_factoring.profit || 0) +
      parseFloat(this.forms.project_income.factoring.processing_fee || 0) +
      parseFloat(this.forms.project_income.factoring.administration_fee || 0) +
      parseFloat(this.forms.project_income.factoring.profit|| 0);

      this.partA_PreFactoring();
      this.partA_Factoring();
      var companyName = this.forms.applicant ? this.forms.applicant.company_name  : "{company}";
      var recommendationsLimit =
        this.forms.currency +
        this.convertCurrencyFormat(pre_factoring_limit, false);
      var recommendationsTotalLimit =
        this.forms.currency +
        this.convertCurrencyFormat(
          this.forms.term_advance_pre_factoring.total,
          false
        );
      var recommendationsFactoring =
        this.forms.currency +
        this.convertCurrencyFormat(factoring_limit, false);
      var recommendationsTotalFactoring =
        this.forms.currency +
        this.convertCurrencyFormat(this.forms.factoring.total, false);

      
      if (this.isEditPage){
        if (this.forms.nature_of_contract.value == "recurring") {
          this.forms.justifications_and_recommendations.recommendations[0] = `${companyName} an additional Pre-Factoring of ${recommendationsLimit} (Term Advance), increasing the limit to ${recommendationsTotalLimit}, as per the attached term sheet and our standard terms and conditions.`;
          this.forms.justifications_and_recommendations.recommendations[1] = `${companyName} an additional Factoring of ${recommendationsFactoring}, increasing the limit to ${recommendationsTotalFactoring}, as per the attached term sheet and our standard terms and conditions.`;
        }else if (this.forms.nature_of_contract.value == "milestone_staggered") {
          
          this.forms.justifications_and_recommendations.recommendations[0] = `Offer ${companyName} an additional Pre-Factoring of  ${recommendationsLimit}, increasing the limit to ${recommendationsTotalLimit} as per the attached term sheet and our standard terms and conditions.`;
          this.forms.justifications_and_recommendations.recommendations[1] = `Offer ${companyName} an additional Factoring of ${recommendationsFactoring},  increasing the limit to ${recommendationsTotalFactoring} as per the attached term sheet and our standard terms and conditions.`;
          this.forms.justifications_and_recommendations.recommendations[2] = ``;
        } else {
          this.forms.justifications_and_recommendations.recommendations[0] = `${companyName} a Pre-Factoring of ${recommendationsLimit} as per the attached term sheet and our standard terms and conditions.`;
          this.forms.justifications_and_recommendations.recommendations[1] = `${companyName} a Factoring of ${recommendationsFactoring} as per the attached term sheet and our standard terms and conditions.`;
          this.forms.justifications_and_recommendations.recommendations[2] = ``;
        }
      }
      
      //20230119
      // this.forms.maximum_exposure=(this.forms.term_advance_pre_factoring.total+this.forms.factoring.total)
     
      //set payee total after get detail
      this.payeeAmountnRateInputChange();
    },
    updateProjectIncomeTotal(){
      this.forms.project_income.total =
      parseFloat(this.forms.project_income.term_advance_pre_factoring.processing_fee || 0) +
      parseFloat(this.forms.project_income.term_advance_pre_factoring.profit || 0) +
      parseFloat(this.forms.project_income.factoring.processing_fee || 0) +
      parseFloat(this.forms.project_income.factoring.administration_fee || 0) +
      parseFloat(this.forms.project_income.factoring.profit|| 0);
    },
    updateMaximumExposure(){
      this.forms.maximum_exposure=(this.forms.term_advance_pre_factoring.total+this.forms.factoring.total);
      this.forms.maximum_exposure= this.forms.maximum_exposure + (this.forms.maximum_exposure * this.selectedMaximumExposureFormula/100)
      this.changeAmountPaymentModeContractType();
    },
    getApprovingAuthorities(amount, contract_type, payment_mode) {
      console.log(amount, contract_type, payment_mode);
      console.log(this.authorization);
      const foundData = this.authorization.filter((e) => {
        const facilityExposure = parseInt(e.facility_exposure);
        if (
          e.payment_mode.value === payment_mode &&
          e.contract_awarder_type.some((type) => type.value === contract_type)
        ) {
          if (
            e.facility_exposure_type === "up_to" &&
            amount <= facilityExposure
          ) {
            return true;
          } else if (
            e.facility_exposure_type === "above" &&
            amount > facilityExposure
          ) {
            return true;
          } else if (e.facility_exposure_type === "open") {
            return true;
          }
        }
        return false;
      });
      if (foundData.length > 0) {
        if (!this.isEditPage){
          this.forms.approver_1 = foundData[0].approving_authorities_user1;
          this.forms.approver_2 = foundData[0].approving_authorities_user2;
        }
      }
      //if credit paper originator is razif then he cannot approval the paper. force bin and ste
      if (this.forms.originator) {
        if (this.forms.originator.username == "razif") {
          console.log(this.userList.filter((item) => {
              return item.username == "kiatbin";
            })
          );
          this.forms.approver_1 = this.userList.filter((item) => {
            return item.username == "kiatbin";
          })[0];
          this.forms.approver_2 = this.userList.filter((item) => {
            return item.username == "steve";
          })[0];
        }
      }
    },
    updatetApprovingAuthorities(amount, contract_type, payment_mode) {
      console.log(amount, contract_type, payment_mode);
      console.log(this.authorization);
      const foundData = this.authorization.filter((e) => {
        const facilityExposure = parseInt(e.facility_exposure);
        if (
          e.payment_mode.value === payment_mode &&
          e.contract_awarder_type.some((type) => type.value === contract_type)
        ) {
          if (
            e.facility_exposure_type === "up_to" &&
            amount <= facilityExposure
          ) {
            return true;
          } else if (
            e.facility_exposure_type === "above" &&
            amount > facilityExposure
          ) {
            return true;
          } else if (e.facility_exposure_type === "open") {
            return true;
          }
        }
        return false;
      });
      if (foundData.length > 0) {
        this.forms.approver_1 = foundData[0].approving_authorities_user1;
        this.forms.approver_2 = foundData[0].approving_authorities_user2;
      }
      //if credit paper originator is razif then he cannot approval the paper. force bin and ste
      if (this.forms.originator) {
        if (this.forms.originator.username == "razif") {
          console.log(this.userList.filter((item) => {
              return item.username == "kiatbin";
            })
          );
          this.forms.approver_1 = this.userList.filter((item) => {
            return item.username == "kiatbin";
          })[0];
          this.forms.approver_2 = this.userList.filter((item) => {
            return item.username == "steve";
          })[0];
        }
      }
    },
    ifChangeOrginator() {
      if (this.forms.originator) {
        if (
          this.forms.originator.username ==
          this.forms.justifications_and_recommendations.due_diligence_sign_off_1
            .username
        ) {
          this.forms.justifications_and_recommendations.due_diligence_sign_off_1 =
            "";
        }
        if (
          this.forms.originator.username ==
          this.forms.justifications_and_recommendations.due_diligence_sign_off_2
            .username
        ) {
          this.forms.justifications_and_recommendations.due_diligence_sign_off_2 =
            "";
        }
        if (
          this.forms.originator.username ==
          this.forms.justifications_and_recommendations.recommender_1.username
        ) {
          this.forms.justifications_and_recommendations.recommender_1 = "";
        }
        if (
          this.forms.originator.username ==
          this.forms.justifications_and_recommendations.recommender_2.username
        ) {
          this.forms.justifications_and_recommendations.recommender_2 = "";
        }
      }
    },
    changeAmountPaymentModeContractType() {
     //this.forms.approver_1 = "";
     //this.forms.approver_2 = "";
      if (!this.isEditPage){
        //remove this code if want always sync the approving_authority user
        this.forms.approver_1 = "";
        this.forms.approver_2 = "";
        //end-0
        this.forms.approver1_approval_status = "";
        this.forms.approver1_approval_date = "";
        this.forms.approver2_approval_status = "";
        this.forms.approver2_approval_date = "";
      }
      
      this.payment_mode.forEach((element) => {
        console.log(element.value, this.forms.contract_type.payment_mode);
        if (element.value == this.forms.contract_type.payment_mode) {
          console.log(element.value, this.forms.contract_type.payment_mode);
          //this.forms.payment_mode = element;
          // don't set it, sometime federal govt contracts will support non-ep
          // discuss this on 2024/04/04
        }
      });
      this.getApprovingAuthorities(parseFloat(this.forms.maximum_exposure),this.forms.contract_type.value,this.forms.payment_mode.value);
    },
    setDateAfterSign() {
     
      if (this.forms.approver1_approval_status == "approved") {
        //this.forms.approver1_approval_date = this.todayDate();
        if (this.forms.approver1_approval_date==''){
          this.forms.approver1_approval_date = this.todayDate()
        }
      } else {
          (this.forms.approver1_approval_date !== '') ? this.forms.approver1_approval_date : '';
      }
      if (this.forms.approver2_approval_status == "approved") {
        //this.forms.approver2_approval_date = this.todayDate();
        if (this.forms.approver2_approval_date==''){
          this.forms.approver2_approval_date = this.todayDate()
        }
      } else {
        (this.forms.approver2_approval_date !== '') ? this.forms.approver2_approval_date : '';
      }
      if (this.forms.justifications_and_recommendations.due_diligence_sign_off_1_status == "signed") {
        if (this.forms.justifications_and_recommendations.due_diligence_sign_off_1_date==''){
          this.forms.justifications_and_recommendations.due_diligence_sign_off_1_date = this.todayDate()
        }
      } else {
        (this.forms.justifications_and_recommendations.due_diligence_sign_off_1_date !== '') ? this.forms.justifications_and_recommendations.due_diligence_sign_off_1_date : '';
      }
      if (this.forms.justifications_and_recommendations.due_diligence_sign_off_2_status == "signed") {
        if (this.forms.justifications_and_recommendations.due_diligence_sign_off_2_date==''){
          this.forms.justifications_and_recommendations.due_diligence_sign_off_2_date = this.todayDate()
        }
      } else {
         (this.forms.justifications_and_recommendations.due_diligence_sign_off_2_date !== '') ? this.forms.justifications_and_recommendations.due_diligence_sign_off_2_date : '';
      }

      if (this.forms.justifications_and_recommendations.recommender_1_status == "signed") {
        if (this.forms.justifications_and_recommendations.recommender_1_date==''){
          this.forms.justifications_and_recommendations.recommender_1_date = this.todayDate();
        }
      } else {
        (this.forms.justifications_and_recommendations.recommender_1_date !== '') ? this.forms.justifications_and_recommendations.recommender_1_date : '';
      }

      if (this.forms.justifications_and_recommendations.recommender_2_status =="signed") {
        if (this.forms.justifications_and_recommendations.recommender_2_date ==''){
          this.forms.justifications_and_recommendations.recommender_2_date = this.todayDate();
        }
      } else {
         (this.forms.justifications_and_recommendations.recommender_2_date !== '') ? this.forms.justifications_and_recommendations.recommender_2_date : '';
      }

      this.updateAndSyncSignatureImages();
    },
    todayDate() {
      // Get today's date
      var today = new Date();

      // Define month names
      var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      // Get the month, day, and year
      var month = monthNames[today.getMonth()];
      var day = today.getDate();
      var year = today.getFullYear();

      // Format the date
      var formattedDate =
        month + " " + (day < 10 ? "0" : "") + day + ", " + year;
      return formattedDate;
    },
    partA_PreFactoring() {
      this.forms.term_advance_pre_factoring.total =  parseFloat(this.forms.term_advance_pre_factoring.current || 0) + parseFloat(this.forms.term_advance_pre_factoring.proposed || 0);
    },
    partA_Factoring() {
      this.forms.factoring.total =  parseFloat(this.forms.factoring.current || 0) +  parseFloat(this.forms.factoring.proposed || 0);
    },
    updateApplicantInfo() {
      this.formData = this.forms.applicant;
      if (!this.isEditPage){
      this.forms.security_and_credit_support={
          factoring:[
            `Factoring Letter of Offer and Acceptance denoting offer by Ikhlas Al Dain Sdn Bhd and acceptance by of the ${this.formData.company_name}.`,
            `Factoring Agreement between ${this.formData.company_name} and Ikhlas Al Dain Sdn Bhd.`,
            `Letter of Undertaking issued by ${this.formData.company_name} to Ikhlas Al Dain Sdn Bhd.`,
            "Guarantee and Indemnity by all directors.",
            "Assignment of all factored accounts receivable.",
            "",
            "",
          ],
          pre_factoring: [
            `Pre-Factoring Letter of Offer and Acceptance denoting offer by Ikhlas Al Dain Sdn Bhd and acceptance by ${this.formData.company_name}.`,
            `Pre-Factoring Agreement between ${this.formData.company_name} and Ikhlas Al Dain Sdn Bhd.`,
            `Letter of Undertaking issued by ${this.formData.company_name} to Ikhlas Al Dain Sdn Bhd.`,
            "Guarantee and Indemnity by all directors.",
            "Assignment of all factored accounts receivable.",
            "",
            "",
          ]
        }
      }

      //this.runFormula();

      if (!this.isEditPage){
        this.forms.pre_factoring_payee[0].payee_company = this.formData.company_name;
        this.forms.pre_factoring_payee[0].location = this.formData.business_address;
      }else{
        if (this.forms.pre_factoring_payee[0].payee_company == "") {
          this.forms.pre_factoring_payee[0].payee_company = this.formData.company_name;
        }
        if (this.forms.pre_factoring_payee[0].location == "") {
          this.forms.pre_factoring_payee[0].location = this.formData.business_address;
        }
      }
      
      // if (this.forms.pre_factoring_payee[0].amount == "") {
      //   this.forms.pre_factoring_payee[0].amount = parseFloat(this.forms.term_advance_pre_factoring.proposed || 0);
      // }
      
      this.updateAuthorisedSignatoryUser();
      this.changeAmountPaymentModeContractType();
      this.purposeChange();
      this.exportData = {
        forms: this.forms,
        formData: this.formData,
      };
    },
    updateAuthorisedSignatoryUser(){
      if (this.forms.applicant.directors[0].name !== "") {
        this.forms.name_of_authorised_signatory =
        this.forms.applicant.directors[0].name;
      }
      this.directorsAndContractPerson = []
      // this.directorsAndContractPerson = this.formData.directors;
      this.formData.directors.forEach(element => {
        if (element.name !== "") {
          this.directorsAndContractPerson.push(element);
        }
      });
      if (this.forms.applicant.contact_person !== "") {
        if (!this.forms.applicant.directors.some(e => e.name === this.forms.applicant.contact_person)) {
          this.directorsAndContractPerson.push({"name":this.forms.applicant.contact_person, "nric":""})
        }
      }
    },
    convertCurrencyFormat(value, show00) {
      if (show00 == true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value);
      } else {
        return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
        // Assuming this.transactionLimit is defined in your component's data
        //return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00","");
      }
    },
    roundUp(value) {
      var roundedValue = Math.floor(value);
      const decimalPart = value - roundedValue;
      if (decimalPart > 0.5) {
        roundedValue++;
      }
      return roundedValue;
    },
    inputNumberOnly(event, parentModel, childModel, type) {
      if (type == "decimal") {
        const numericValue = event.target.value.replace(/[^0-9.]/g, "");
        if (parentModel == "") {
          this[childModel] = numericValue;
        } else {
          this[parentModel][childModel] = numericValue;
        }
      } else {
        const numericValue = event.target.value.replace(/[^0-9]/g, "");
        if (parentModel == "") {
          this[childModel] = numericValue;
        } else {
          this[parentModel][childModel] = numericValue;
        }
      }
    },
    inputNumberOnlyV3(event, parentModel, parent, childModel) {
      const numericValue = event.target.value.replace(/[^0-9]/g, "");
      this[parentModel][parent][childModel] = numericValue;
    },
    updateAmount(parentModel, parent, childModel) {
      const numericValue = this[parentModel][parent][childModel];
      if (numericValue !== "") {
        const amountNumber = parseInt(numericValue);
        if (!isNaN(amountNumber) && amountNumber >= 0) {
          this[parentModel][parent][childModel] = amountNumber.toLocaleString(
            "en-US",
            {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
              useGrouping: true,
            }
          );
        }
      }
    },
    inputNumberOnlyV4(event, parentModel, parent, childModel, index) {
      const numericValue = event.target.value.replace(/[^0-9]/g, "");
      this[parentModel][parent][index][childModel] = numericValue;
    },
    inputNumberOnlyV6(event, parentModel, parent, childModel, index, type) {
      if (type == "decimal") {
        const numericValue = event.target.value.replace(/[^0-9.]/g, "");
        this[parentModel][parent][index][childModel] = numericValue;
      } else {
        const numericValue = event.target.value.replace(/[^0-9]/g, "");
        this[parentModel][parent][index][childModel] = numericValue;
      }
    },
    inputNumberOnlyV5(event, grandpa, parent, children, model, type) {
      if (type == "decimal") {
        const numericValue = event.target.value.replace(/[^0-9.]/g, "");
        this[grandpa][parent][children][model] = numericValue;
      } else {
        const numericValue = event.target.value.replace(/[^0-9]/g, "");
        this[grandpa][parent][children][model] = numericValue;
      }
    },
    inputNumberOnlyCustom(event, parentProperty, mainindex, index) {
      // Replace any non-numeric or dot characters
      const numericValue = event.target.value.replace(/[^0-9.]/g, "");
      // Update the model value with the sanitized numeric value
      this.forms[parentProperty][mainindex].months[index].name = numericValue
      // Log the sanitized numeric value (for debugging)
      console.log(numericValue);
    },

    notBeforeToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    async getData() {
      this.$Progress.start();
      this.loading = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("applicationDbCode",this.$route.params.id || '');
      axios({
        method: "post",
        url: appConfig.APIHost + "controller/application/getApplicationDetailByID",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData);
            
            this.forms = resData.data;
            this.formData = resData.data.applicant;

            this.isEditPage = true
            //awarder
            if (this.awarder_list.includes(this.forms.contract_awarder)) {
              this.awarderSelected = this.forms.contract_awarder;
            } else {
              this.awarderSelected = "Other";
              this.awarderSelfInput = this.forms.contract_awarder;
            }
            //contract tenure
            if (this.contract_tenure.includes(parseInt(this.forms.contract_tenure))) {
              this.contractTenureSelected = this.forms.contract_tenure;
            } else {
              this.contractTenureSelected = "Other";
              this.contractTenureSelfInput = this.forms.contract_tenure;
            }


            setTimeout(() => {
              this.exportData.forms = this.forms;
              this.exportData.formData = this.formData;
              this.updateApplicantInfo();
              this.updateAndSyncSignatureImages();
              this.forms.approver_1 = resData.data.approver_1;
              this.forms.approver_2 = resData.data.approver_2;
              const document = this.applicantList.find(e => e.account_db_code==resData.data.applicant.account_db_code);
              console.log(document.supporting_docs)
              this.exportData.checkingFiles = document.supporting_docs;

              //detect if accessUsername is approver_1 and approver_2
              console.log(this.forms.approver_2.username)
              if (this.checkUserAvailabilityToUpdateAndSign()){
                  setTimeout(() => {
                    this.$refs.modalFunc.showModal()
                  }, 500)
              }
            }, 2);
            

            //this.runFormula();

            

          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    getApplicant() {
      console.log("getApplicant");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", "1");
      bodyFormData.append("limit", "9999");
      bodyFormData.append("keyword", "");
      bodyFormData.append("sortBy", "");
      bodyFormData.append("dateStart", "");
      bodyFormData.append("dateEnd", "");
      axios({
        method:"post",
        url: appConfig.APIHost + 'controller/applicant/getAllApplicantList',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            resData.data.forEach((element) => {
              this.applicantList.push(element);
            });
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    updateAndSyncSignatureImages(){
      //if user already signed the signature won't change to latest signature
      if (this.forms.justifications_and_recommendations.due_diligence_sign_off_1_status!=='signed'){
        if (this.forms.justifications_and_recommendations.due_diligence_sign_off_1){
          const dueDiligenceSignOff1AccountDBCode = this.forms.justifications_and_recommendations.due_diligence_sign_off_1.account_db_code
          this.forms.justifications_and_recommendations.due_diligence_sign_off_1.signature = 
          this.userList.find(e => e.account_db_code==dueDiligenceSignOff1AccountDBCode).signature
        }
      }
      if (this.forms.justifications_and_recommendations.due_diligence_sign_off_2_status!=='signed'){
        if (this.forms.justifications_and_recommendations.due_diligence_sign_off_2){
          const dueDiligenceSignOff2AccountDBCode = this.forms.justifications_and_recommendations.due_diligence_sign_off_2.account_db_code
          this.forms.justifications_and_recommendations.due_diligence_sign_off_2.signature = 
          this.userList.find(e => e.account_db_code==dueDiligenceSignOff2AccountDBCode).signature
        }
      }
      if (this.forms.justifications_and_recommendations.recommender_1_status!=='signed'){
        if (this.forms.justifications_and_recommendations.recommender_1){
          const recommender1AccountDBCode = this.forms.justifications_and_recommendations.recommender_1.account_db_code
          this.forms.justifications_and_recommendations.recommender_1.signature = 
          this.userList.find(e => e.account_db_code==recommender1AccountDBCode).signature
        }
      }
      if (this.forms.justifications_and_recommendations.recommender_2_status!=='signed'){
        if (this.forms.justifications_and_recommendations.recommender_2){
          const recommender2AccountDBCode = this.forms.justifications_and_recommendations.recommender_2.account_db_code
          this.forms.justifications_and_recommendations.recommender_2.signature = 
          this.userList.find(e => e.account_db_code==recommender2AccountDBCode  ).signature
        }
      }
    },
    approverSignAndSubmit(){
      console.log("SignAndSubmit")
      //setTimeout(() => this.$refs.modalFunc.updateLoading(false),1000)
      if (this.accessUsername==(this.forms.approver_1.username || '')){
        this.$refs.approver1.click();
        this.setDateAfterSign()
      }
      if (this.accessUsername==(this.forms.approver_2.username || '')){
        this.$refs.approver2.click();
        this.setDateAfterSign()
      }
      if (this.accessUsername==(this.forms.justifications_and_recommendations.due_diligence_sign_off_1.username || '')){
        this.$refs.dueSignOff1.click();
        this.setDateAfterSign()
      }

      if (this.accessUsername==(this.forms.justifications_and_recommendations.due_diligence_sign_off_2.username || '')){
        this.$refs.dueSignOff2.click();
        this.setDateAfterSign()
      }

      if (this.accessUsername==(this.forms.justifications_and_recommendations.recommender_1.username|| '')){
        this.$refs.recommender1.click();
        this.setDateAfterSign()
      }

      if (this.accessUsername==(this.forms.justifications_and_recommendations.recommender_2.username|| '')){
        this.$refs.recommender2.click();
        this.setDateAfterSign()
      }

      this.save();
    },
    approverDeclinedAndUpdate(){
      console.log("approverDeclinedAndUpdate")
      if (this.accessUsername==(this.forms.approver_1.username || '')){
        this.$refs.approver1_decline.click();
      }
      if (this.accessUsername==(this.forms.approver_2.username || '')){
        this.$refs.approver2_decline.click();
      }
      this.save();
    },

    getAuthority() {
      console.log("getAuthority");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      axios({
        method: "post",
        url: appConfig.APIHost + 'controller/authority/getAuthoritySetting',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            resData.data.forEach((element) => {
              if (element.type == "credit_facility_approval") {
                element.contract.forEach((v) => {
                  this.authorization.push(v);
                });
              }
            });
            setTimeout(() => this.changeAmountPaymentModeContractType(), 1);
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    getUsers() {
      console.log("getUsers");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", "1");
      bodyFormData.append("limit", "9999");
      axios({
        method: "post",
        url:  appConfig.APIHost + "controller/user/getAllUserList",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            resData.data.forEach((element) => {
              this.userList.push({
                  account_db_code: element.account_db_code,
                  username: element.username,
                  name: element.name,
                  full_name: element.full_name,
                  nric_no: element.nric_no,
                  signature: element.signature,
                  position: element.position,
                  email: element.email,
                  status: element.status,
                  signing_authority: element.signing_authority
              });
              if (element.signing_authority=="available"){
                this.userListCanSign.push({
                  account_db_code: element.account_db_code,
                  username: element.username,
                  name: element.name,
                  full_name: element.full_name,
                  nric_no: element.nric_no,
                  signature: element.signature,
                  position: element.position,
                  email: element.email,
                  status: element.status,
                  signing_authority: element.signing_authority
                });
              }
             
            });
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    checkUserAvailabilityToUpdateAndSign(){
      const usernames = [
        this.forms.approver_1.username|| '',
        this.forms.approver_2.username|| '',
        this.forms.justifications_and_recommendations.recommender_1.username || '',
        this.forms.justifications_and_recommendations.recommender_2.username || '',
        this.forms.justifications_and_recommendations.due_diligence_sign_off_1.username || '',
        this.forms.justifications_and_recommendations.due_diligence_sign_off_2.username || ''
      ];
      return usernames.includes(this.accessUsername);
    },
    save() {
      this.submitted = true;
      this.$v.forms.$touch();
      this.$v.formData.$touch();

      console.log(this.$v.formData.$invalid);
      if (
        this.$v.formData.$invalid ||
        this.$v.forms.$invalid
      ) {
        return;
      } else {
        this.$Progress.start();
        this.buttonLoading[0] = true;
        var bodyFormData = new FormData();
        var postUrl = "";

        if (this.isEditPage){
          this.forms.last_update_by= this.accessUsername
        }else{
          this.forms.created_by = this.accessUsername
        }
        var signStatusData = {
          "approver1": this.forms.approver_1.username,
          "approver2": this.forms.approver_2.username,
          "approver1_status": this.forms.approver1_approval_status,
          "approver2_status": this.forms.approver2_approval_status,
          "due_diligence1":this.forms.justifications_and_recommendations.due_diligence_sign_off_1.username || "",
          "due_diligence2":this.forms.justifications_and_recommendations.due_diligence_sign_off_2.username || "",
          "due_diligence1_status":this.forms.justifications_and_recommendations.due_diligence_sign_off_1_status,
          "due_diligence2_status":this.forms.justifications_and_recommendations.due_diligence_sign_off_2_status,
          "recommender1":this.forms.justifications_and_recommendations.recommender_1.username || "",
          "recommender2":this.forms.justifications_and_recommendations.recommender_2.username || "",
          "recommender1_status":this.forms.justifications_and_recommendations.recommender_1_status,
          "recommender2_status":this.forms.justifications_and_recommendations.recommender_2_status
        }
        console.log(signStatusData)
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("applicationInfo", JSON.stringify(this.forms));
        bodyFormData.append("signStatusInfo", JSON.stringify(signStatusData))
        if (this.isEditPage){
          bodyFormData.append("applicationDbCode", this.forms.application_db_code );
          postUrl = "controller/application/editApplication"
        }else{
          postUrl = "controller/application/createNewApplication"
        }
        axios({
          method: "post",
          url: appConfig.APIHost + postUrl,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((response) => {
            var resData = response.data;
            if (resData.status == 200) {
              this.completed2Exit = true;
              console.log(resData);
              var responseTitle = ""
              var responseMessage = ""
              if (this.isEditPage){
                responseTitle =  "Factoring Application Updated"
                responseMessage = "Factoring application has been successfully updated!"

                //this for preview sign and submit function 
                this.$refs.modalFunc.updateLoading(false)
                this.$refs.modalFunc.$refs['modal-preview'].hide();
              }else{
                responseTitle = "New Factoring Application"
                responseMessage = "Factoring application has been successfully created!"
              }
              Swal.fire({
                icon: "success",
                title: responseTitle,
                html: responseMessage,
                confirmButtonColor: "#FA6541",
                confirmButtonText: this.$t("siteLang.Done"),
              }).then(() => {
                console.log("success");
                //this.getData()
                this.$router.replace({ name: "Factoring Application" }).catch(() => {});
              });
            } else if (resData.status == 440) {
              this.completed2Exit = true;
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `${resData.message}.`,
                confirmButtonColor: "#222",
                confirmButtonText: this.$t("siteLang.Done"),
              });
              localStorage.clear();
              this.$router.push({
                path: "/login",
              });
            } else {
              this.completed2Exit = false;
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `${resData.message}.`,
                confirmButtonColor: "#222",
                confirmButtonText: this.$t("siteLang.Done"),
              });
            }
            this.buttonLoading[0] = false;
            this.submitted = false;
            this.$Progress.finish();
          })
          .catch((error) => {
            this.completed2Exit = false;
            this.buttonLoading[0] = false;
            this.submitted = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${error}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          });
      }
    },
    reset() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.$Progress.start();
        this.loading = true;
        this.buttonLoading[1] = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("phone", this.accessPhone);
        axios({
          method: "post",
          url: appConfig.DemoAPI,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((response) => {
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData);
              Swal.fire({
                icon: "success",
                title: "Deleted",
                html: `API ID and API Hash have been successfully deleted and reset!`,
                confirmButtonColor: "#FA6541",
                confirmButtonText: this.$t("siteLang.Done"),
              });
              const updateData = {
                apiID: "YOUR_API_ID",
                apiHash: "YOUR_API_HASH",
              };
              this.$refs.commonFunc.updateData(JSON.stringify(updateData));
              this.reload();
            } else if (resData.status == 440) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `${resData.message}.`,
                confirmButtonColor: "#FA6541",
                confirmButtonText: this.$t("siteLang.Done"),
              });
              localStorage.clear();
              this.$router.push({
                path: "/login",
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `${resData.message}.`,
                confirmButtonColor: "#FA6541",
                confirmButtonText: this.$t("siteLang.Done"),
              });
            }
            this.loading = false;
            this.submitted = false;
            this.buttonLoading[1] = false;
            this.$Progress.finish();
          })
          .catch((error) => {
            this.buttonLoading[1] = false;
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${error}.`,
              confirmButtonColor: "#FA6541",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          });
      }
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type//data.account_type;
          const permission = data.modules.filter(e => e.name==='factoring_application')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
          } else {
            console.log('Module with name "factoring_application" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  },
};
</script>
<style scoped>
.min-width-760 {
  min-width: 760px;
}
.min-width-200 {
  min-width: 200px;
}
.min-width-150 {
  min-width: 130px;
}
.min-width-100 {
  min-width: 100px;
}
.partA tr td {
  min-width: 120px;
}
.partC tr td {
  padding: 8px 5px;
  border-color: #ddd !important;
}
.partB {
  min-width: 1000px;
}
.partB tr td {
  min-width: 120px;
}
.partB tr td textarea {
  min-width: 220px;
}
.partC {
  min-width: 800px;
}
.topArrow::before {
  width: 100%;
  display: block;
  left: 0px;
  top: -1px;
  font-size: 16px;
  content: "↑";
}
.disabled-area{
  z-index: 3;
}
.sign-area{
  position: relative;
  z-index: 4;
}
</style>
